import { useState, useEffect } from "react";
import cls from "./image-modal.module.css";
import klass from "common/klass";
import client from "common/client";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import overlayCls from "common/overlay.module.css";
import Avatar from "common/avatar";
import FileSelect from "common/file-select";

const DefaultPlaceholder = props => {
  const { user } = props;
  return (
    <Avatar
      style={{ width: "120px", height: "120px" }}
      string={user.email}
      bgColor={user?.settings?.avatar?.bgColor}
    />
  );
};

export default function ImageModal(props) {
  const {
    onClose,
    onSaveImage,
    isProcessing,
    user,
    className,
    title = "Change profile picture",
    placeholder: Placeholder = DefaultPlaceholder,
    defaultImage
  } = props;
  const [overlay, setOverlay] = useState();
  const [isInvalid, setIsInvalid] = useState(false);
  const [image, setImage] = useState(
    defaultImage || user?.settings?.profileImageUrl
  );
  const [theUser, setTheUser] = useState();
  const [file, setFile] = useState();

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  useEffect(() => {
    if (!defaultImage) {
      client
        .service("users")
        .get(user._id)
        .then(u => {
          setImage(u.settings.profileImageUrl);
        })
        .catch(console.log);
    }
  }, []);

  const onFileSelect = files => {
    const f = files[0];
    if (f) {
      setImage(window.URL.createObjectURL(f));
      setFile(f);
    }
  };

  const onFileSelectErrors = errors => {
    /* TOOD: add an option to see which file errored out */
    if (errors?.length) {
      const first = errors[0].errors[0];
      const code = first.code;
      if (code === "file-too-large") {
        setIsInvalid(true);
        ellio.toaster.warning("File size limit is 4MB", {
          title: "File too large"
        });
      } else if (code === "file-invalid-type") {
        ellio.toaster.warning(
          "At least one of the selected files was not an image."
        );
      }
    }
  };

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.imageModal}>
        <h1 className={typeCls.ctrl5}>{title}</h1>
        <div
          className={klass([
            cls.avatar,
            isInvalid ? cls.invalidImage : "",
            className
          ])}
        >
          <FileSelect
            isOpen={true}
            onDrop={onFileSelect}
            onErrors={onFileSelectErrors}
            maxSize={4}
            accept="image/*"
            dropArea={() => (
              <div className={cls.avatarWrapper}>
                {image ? (
                  <div className={cls.newImageBg}>
                    <div
                      className={cls.theImage}
                      style={{
                        backgroundImage: `url(${image})`
                      }}
                    ></div>
                  </div>
                ) : (
                  <Placeholder user={user} />
                )}
                <button className={buttonCls.button}>Select image</button>
              </div>
            )}
          />
        </div>
        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          <button
            className={buttonCls.primarySm}
            onClick={() => onSaveImage(file)}
          >
            Save {isProcessing ? <div className="el-spinner"></div> : null}
          </button>
        </div>
      </div>
    </div>
  );
}
