const klass = cls => {
  return cls
    .filter(Boolean)
    .map(v => v.trim())
    .join(" ");
};

const k = (...cls) =>
  cls
    .filter(Boolean)
    .map(v => v.trim())
    .join(" ");

export default klass;

export { k };
