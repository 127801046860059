import cls from "./billing-toggle.module.css";
import typeCls from "common/type.module.css";
import { isValidSubscription } from "common/client";
import { k } from "common/klass";

export default function BillingToggle(props) {
  const { user, isYearly, setIsYearly } = props;
  if (isValidSubscription(user?.subscription)) {
    return null;
  }

  return (
    <div className={cls.toggle}>
      <p className={k(typeCls.p2, cls.title)}>Billing cycle</p>
      <div className={cls.options}>
        <div className={[cls.option, isYearly ? cls.active : ""].join(" ")}>
          <button onClick={() => setIsYearly(true)}>Yearly</button>
        </div>
        <div className={[cls.option, !isYearly ? cls.active : ""].join(" ")}>
          <button onClick={() => setIsYearly(false)}>Monthly</button>
        </div>
      </div>
    </div>
  );
}
