import client, { useServiceEvents } from "common/client";
import { useState, useEffect, useRef, useCallback } from "react";
import { paramsForServer as qq } from "feathers-hooks-common";

const useFolders = (params = {}, deps = []) => {
  const foldersService = useRef(client.service("folders"));
  const [folders, setFolders] = useState();
  const [foldersError, setFoldersError] = useState();

  const refreshFolders = (
    prms = {
      query: {
        isDeleted: false
      }
    }
  ) => {
    return foldersService.current
      .find(prms)
      .then(resp => setFolders(resp.data))
      .catch(setFoldersError);
  };

  useEffect(refreshFolders, []);

  useServiceEvents(foldersService, refreshFolders);

  const createFolder = useCallback(
    (folder, successMsg = "Folder was created successfully.") => {
      return foldersService.current
        .create(folder)
        .then(resp => {
          ellio.toaster.success(successMsg, { dismiss: 3500 });
          return resp;
        })
        .catch(err => {
          ellio.toaster.failure(err.message);
          return Promise.reject(err);
        });
    },
    []
  );

  const deleteFolder = useCallback(
    (folder, successMsg = "Folder was removed successfully.") => {
      return foldersService.current
        .remove(folder._id)
        .then(resp => {
          ellio.toaster.success(successMsg, { dismiss: 3500 });
          return resp;
        })
        .catch(err => {
          ellio.toaster.failure(err.message);
          return Promise.reject(err);
        });
    },
    []
  );

  const deleteFolders = useCallback(
    (folders, successMsg = "Folders were removed successfully.") => {
      return foldersService.current
        .remove(null, {
          query: {
            _id: {
              $in: folders.map(v => v._id)
            }
          }
        })
        .then(resp => {
          ellio.toaster.success(successMsg);
          return resp;
        })
        .catch(err => ellio.toaster.failure(err.message));
    },
    []
  );

  return {
    folders,
    setFolders,
    foldersError,
    foldersService,
    createFolder,
    deleteFolder,
    deleteFolders,
    refreshFolders
  };
};

export default useFolders;
