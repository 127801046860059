const { REACT_APP_FIGMA_CALLBACK, REACT_APP_FIGMA_CLIENT_ID } = process.env;
import { DateTime } from "luxon";
import client from "common/client";
import { popupwindow } from "common/iframe";

const client_id = REACT_APP_FIGMA_CLIENT_ID;
const callback = REACT_APP_FIGMA_CALLBACK;

export const figmaTokenInfo = r => {
  const {
    access_token: token,
    expires_in: expiresIn,
    refresh_token: refreshToken
  } = r;
  const now = DateTime.now();
  const nowUTCObj = now.toUTC();
  const expiresAtUTC = nowUTCObj.plus({ seconds: expiresIn }).toISO();
  const setTokenAtUTC = nowUTCObj.toISO();

  return {
    token,
    refreshToken,
    expiresAtUTC,
    setTokenAtUTC
  };
};

export const storeFigmaTokenInfoLocalStorage = info => {
  const { token, refreshToken, expiresAtUTC, setTokenAtUTC } = info;
  window.localStorage.setItem("figma_token", token);
  window.localStorage.setItem("figma_refresh_token", refreshToken);
  window.localStorage.setItem("figma_token_expires_at", expiresAtUTC);
  window.localStorage.setItem("figma_token_at", setTokenAtUTC);
};

export const removeKeysFromLocalStorage = () => {
  window.localStorage.removeItem("figma_token");
  window.localStorage.removeItem("figma_refresh_token");
  window.localStorage.removeItem("figma_token_expires_at");
  window.localStorage.removeItem("figma_token_at");
};

export const fileIdFromUrl = fileUrl => {
  return fileUrl.match(/http.*figma.com\/file\/(.*)\//)[1].trim();
};

export const extractFrames = (fileResp, pageName) => {
  if (!pageName) {
    throw new Error("Figma page name was not provided.");
  }

  if (!fileResp) {
    return [];
  }

  const page = fileResp.document.children.find(
    c => c.type === "CANVAS" && c.name === pageName
  );

  if (!page) {
    return [];
  }

  return page.children
    .map(n => {
      if (n.type === "FRAME") {
        return {
          name: n.name,
          id: n.id
        };
      }
    })
    .filter(Boolean);
};

export const hasTokenExpired = expireAtIso => {
  if (!expireAtIso) {
    throw new Error("No ISO date-time string was provided.");
  }

  const nowUTC = DateTime.now().toUTC();
  const thenUTC = DateTime.fromISO(expireAtIso).toUTC();
  return nowUTC > thenUTC;
};

/* will refresh token if necessary */
export const refreshToken = () => {
  const localRefreshToken = window.localStorage.getItem("figma_refresh_token");
  const expireAtIso = window.localStorage.getItem("figma_token_expires_at");

  if (localRefreshToken) {
    return client
      .service("figma")
      .create(
        {
          refreshToken: localRefreshToken
        },
        {
          query: {
            resource: "refresh-token"
          }
        }
      )
      .then(r => {
        const info = figmaTokenInfo(r);
        storeFigmaTokenInfoLocalStorage(info);
        return info.token;
      })
      .catch(err => {
        return Promise.resolve("");
      });
  }
  return Promise.resolve("");
};

export const connectFigma = (opt = {}) => {
  const NO_TOKEN = "Could not get Figma access token.";
  return new Promise((resolve, reject) => {
    if (typeof opt.preventDefault === "function") {
      opt.preventDefault();
    }

    let ref;

    const { userId } = opt;

    const localRefreshToken = window.localStorage.getItem(
      "figma_refresh_token"
    );
    const localToken = window.localStorage.getItem("figma_token");
    const expireAtIso = window.localStorage.getItem("figma_token_expires_at");

    /* initially I was refreshing token only if it was expired, but it was not
     * reliable, so if we have a refresh token, we will refresh and use the new
     * token for every call.
     */
    if (localRefreshToken) {
      return refreshToken()
        .then(newToken => {
          if (newToken) {
            return resolve(newToken);
          }

          return resolve("");
        })
        .catch(err => {
          return resolve("");
        });
    } else {
      const url = `https://www.figma.com/oauth?client_id=${client_id}&redirect_uri=${callback}&scope=file_read&state=${window.encodeURI(
        userId
      )}&response_type=code`;

      ref = popupwindow(url, 800, 800);

      /* from the popup window, we get figma token info */
      window.addEventListener(
        "message",
        event => {
          if (event.origin !== window.origin) {
            return;
          }

          if (event.data.name === "figma") {
            if (event.data.token) {
              storeFigmaTokenInfoLocalStorage(event.data);
              return resolve(event.data.token);
            } else {
              return resolve("");
            }
          }
        },
        false
      );
    }
  });
};

export const forceConnect = async userId => {
  removeKeysFromLocalStorage();
  const token = await connectFigma({ userId });
  return token;
};
