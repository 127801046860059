import { useState, useEffect } from "react";
import Tippy from "@tippyjs/react";
import cls from "./login-code-modal.module.css";
import { k } from "common/klass";
import typeCls from "common/type.module.css";
import inputCls from "common/input.module.css";
import buttonCls from "common/button.module.css";
import overlayCls from "common/overlay.module.css";
import client from "common/client";
import crossSvg from "./cross.svg";

export default function LoginCodeModal(props) {
  const { onClose, onLogin, isProcessing } = props;
  const [overlay, setOverlay] = useState();

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.loginCodeModal}>
        <Tippy content="Close">
          <button onClick={onClose} className={k(buttonCls.button, cls.close)}>
            <img draggable={false} src={crossSvg} />
          </button>
        </Tippy>
        <h1 className={typeCls.ctrl5}>Login link sent</h1>
        <p className={typeCls.p}>
          We sent a login link to <strong>{props.email}</strong>, it should
          arrive shortly. If you can’t find the email, please check your Spam
          folder or click the button below to resend the email. If you have any
          questions or issues please send an email to{" "}
          <strong>contact@ellio.app</strong>.
        </p>

        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          <button
            className={buttonCls.primarySm}
            onClick={() => {
              props
                .resendCode()
                .then(r =>
                  ellio.toaster.success(
                    `A new login link was sent to ${props.email}`,
                    { dismiss: false }
                  )
                )
                .catch(err => ellio.toaster.failure(err.message));
            }}
          >
            Resend email{" "}
            {isProcessing ? <div className="el-spinner"></div> : null}
          </button>
        </div>
      </div>
    </div>
  );
}
