import cls from "./drawer.module.css";
import buttonCls from "common/button.module.css";
import klass from "common/klass";
import { useState, useEffect } from "react";
import Select from "react-select";
import { studioActionEvents } from "common/eve";
import closeSvg from "./close.svg";
import jiraSvg from "./jira.svg";
import Tippy from "@tippyjs/react";
import commentSvg from "./comment.svg";
import { ReactSVG } from "react-svg";
import * as canvasUtil from "studio/canvas-util";
import client, { sendStudioEvent } from "common/client";
import plugSvg from "./plug.svg";
import parseTask from "./parse-task-content";
import { refreshExistingToken } from "common/jira-common";
import marked from "marked";
import DOMPurify from "dompurify";

const getTaskHtml = (markdown = "") => {
  const dirty = marked(markdown.replace(/\\\n/g, ""));
  const clean = DOMPurify.sanitize(dirty);
  return clean;
};

const getFillForItem = t => {
  return t.objects.filter(v => v.type === "path")[0].fill;
};

const noop = v => v;

const Drawer = props => {
  const {
    isOpen,
    setIsOpen,
    annotation,
    design,
    canvas,
    handleUpdateModel,
    isJiraConnected,
    setIsJiraConnected,
    userId,
    connectJira,
    onProjectSelect
  } = props;
  const [tab, setTab] = useState("tasks");

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [projectsOptions, setProjectsOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState();

  /* get a list of jira projects when jira is connected */
  useEffect(() => {
    const token = window.localStorage.getItem("jira_token");
    const cloudId = window.localStorage.getItem("jira_cloud_id");

    if (isJiraConnected) {
      client
        .service("jira")
        .find({
          query: {
            token,
            cloudId
          }
        })
        .then(r => {
          setProjectsOptions(
            r.map(v => ({
              value: v.key,
              label: v.name
            }))
          );
        })
        .catch(err => {
          ellio.toaster.failure(
            "Could not get a list of Jira projects, please refresh the page and try again."
          );
        });
    }
  }, [isJiraConnected]);

  /*
  when a ticket is created/updated in jira, updated the associated index
  */
  const [jiraBusy, setJiraBusy] = useState({});

  const isJiraBusy = t => jiraBusy[t.metaProp.id];

  const failToast = e => {
    console.log("fail toast", e.stack);
    return ellio.toaster.failure(e.message);
  };

  const startJiraBusy = t => () => {
    setJiraBusy(p => ({ ...p, [t.metaProp.id]: true }));
  };

  const endJiraBusy = t => () => {
    setJiraBusy(p => ({ ...p, [t.metaProp.id]: false }));
  };

  const tasks = annotation?.model?.objects.filter(v =>
    v.metaProp?.type.includes("task")
  );

  const isTaskSelected = t => {
    return selectedTasks.find(v => v.metaProp.id === t.metaProp.id);
  };

  const updateTaskSelect = t => {
    setSelectedTasks(p => {
      const c = [...p];
      const idx = c.findIndex(v => v.metaProp.id === t.metaProp.id);
      if (idx !== -1) {
        c.splice(idx, 1);
      } else {
        c.push(t);
      }
      return c;
    });
  };

  const comments = annotation?.model?.objects.filter(v =>
    v.metaProp?.type.includes("comment")
  );

  /*
  TODO: these three functions are very similar, clean up to just use the
  * bulk handler: `createOrUpdateTickets`
  */
  const createJiraTicket = async task => {
    let token;

    try {
      token = await refreshExistingToken();
      setIsJiraConnected(token);
    } catch (err) {
      token = "";
    }

    if (!token) {
      ellio.toaster.warning(
        'Jira is not connected. You can use the "Connect to Jira" option in the toolbard to connect to Jira first.',
        { title: "Oops...", dismiss: false }
      );
      setIsJiraConnected(false);
      return;
    }

    const cloudId = window.localStorage.getItem("jira_cloud_id");
    const cloudUrl = window.localStorage.getItem("jira_cloud_url");
    const ellioUrl = `${ellio.webHost}/designs/${design._id}`;

    const { title, description } = parseTask(task.metaProp.content);
    const ticketDescription = description + `\n\n---\n\nEllio Url: ${ellioUrl}`;

    let ticket;

    if (!selectedProject) {
      return ellio.toaster.warning(
        "Please pick a project from the dropdown list."
      );
    }

    try {
      ticket = await client.service("jira").create({
        newTicket: true,
        token,
        cloudId,
        ticketTitle: title,
        ticketDescription,
        projectKey: selectedProject?.value
      });
    } catch (err) {
      return Promise.reject(err);
    }

    let taskOnCanvas;

    canvas.getObjects().forEach(v => {
      if (v.metaProp?.id === task.metaProp.id) {
        v.metaProp.ticket = ticket;
        const ticketUrl = `${cloudUrl}/browse/${ticket.key}`;
        v.metaProp.ticketUrl = ticketUrl;
        v.metaProp.content += `\n\n---\n\nEllio Url: ${ellioUrl}`;
        v.metaProp.content += `\nJira Ticket: ${ticketUrl}`;
        taskOnCanvas = v;
      }
    });

    if (taskOnCanvas) {
      taskOnCanvas = taskOnCanvas.toObject(canvasUtil.extraPropsToSave);

      const snapshotUrl = taskOnCanvas.metaProp?.snapshotUrl;

      /* if there is any attachment, add to the ticket */
      if (snapshotUrl) {
        try {
          await client.service("jira").create({
            token,
            cloudId,
            ticketId: ticket.key,
            imageUrl: snapshotUrl,
            requestAttachFile: true
          });
          console.log("added attachment to", ticket.key);
        } catch (err) {
          console.log("could not add attachment");
          console.log(err);
        }
      }
    }

    return handleUpdateModel({ canvas, annotationId: annotation._id })
      .then(_ => {
        ellio.toaster.success("Created ticket successfully!", {
          dismiss: 2000
        });
        if (taskOnCanvas) {
          sendStudioEvent(
            studioActionEvents.jiraTicketCreated,
            [taskOnCanvas],
            {
              userId,
              annotationId: annotation._id
            }
          );
        }
      })
      .catch(err => {
        ellio.toaster.failure(
          "Couldn't create the ticket, try connecting to Jira again"
        );
      });
  };

  const updateJiraTicket = async task => {
    let token;

    try {
      token = await refreshExistingToken();
      setIsJiraConnected(token);
    } catch (err) {
      token = "";
    }

    if (!token) {
      ellio.toaster.warning(
        'Jira is not connected. You can use the "Connect to Jira" option in the toolbard to connect to Jira first.',
        { title: "Oops...", dismiss: false }
      );
      setIsJiraConnected(false);
      return;
    }

    const cloudId = window.localStorage.getItem("jira_cloud_id");
    const { title, description } = parseTask(task.metaProp.content);
    const ticketId = task.metaProp.ticket.id;

    return client
      .service("jira")
      .patch(ticketId, {
        ticketTitle: title,
        ticketDescription: description,
        token,
        cloudId
      })
      .then(r => {
        ellio.toaster.success("Updated ticket successfully!", {
          dismiss: 2000
        });
      })
      .catch(err => {
        ellio.toaster.failure(
          "Couldn't update the ticket. Try connecting to Jira again."
        );
      });
  };

  const createOrUpdateTickets = async sTasks => {
    let token;

    try {
      token = await refreshExistingToken();
      setIsJiraConnected(token);
    } catch (err) {
      token = "";
    }

    if (!token) {
      return ellio.toaster.warning(
        'Jira is not connected. You can use the "Connect to Jira" option in the toolbard to connect to Jira first.',
        { title: "Oops...", dismiss: false }
      );
      setIsJiraConnected(false);
      return;
    }

    if (!selectedProject) {
      return ellio.toaster.warning(
        "Please pick a project from the dropdown list."
      );
    }

    const cloudId = window.localStorage.getItem("jira_cloud_id");
    const cloudUrl = window.localStorage.getItem("jira_cloud_url");
    const ellioUrl = `${ellio.webHost}/designs/${design._id}`;

    const busyItems = {};
    sTasks.forEach(t => (busyItems[t.metaProp.id] = true));
    setJiraBusy(busyItems);

    const toCreate = sTasks.filter(v => !v.metaProp.ticket);
    const toUpdate = sTasks.filter(v => v.metaProp.ticket);

    if (toCreate.length) {
      const result = await client.service("jira").create({
        bulk: toCreate.map(v => {
          const { title, description } = parseTask(v.metaProp.content);

          const ticketDescription =
            description + `\n\n---\n\nEllio Url: ${ellioUrl}`;
          return {
            ticketTitle: title,
            ticketDescription
          };
        }),
        token,
        cloudId,
        projectKey: selectedProject?.value
      });

      const { issues, errors } = result;
      const tasksToSync = [];

      canvas.getObjects().forEach(co => {
        toCreate.forEach((t, i) => {
          if (co.metaProp?.id === t.metaProp?.id) {
            co.metaProp.ticket = issues[i];
            const ticketUrl = `${cloudUrl}/browse/${issues[i].key}`;
            co.metaProp.ticketUrl = ticketUrl;
            co.metaProp.content += `\n\n---\n\nEllio Url: ${ellioUrl}`;
            co.metaProp.content += `\nJira Ticket: ${ticketUrl}`;
            tasksToSync.push(co);
          }
        });
      });

      await handleUpdateModel({ canvas, annotationId: annotation._id });

      sendStudioEvent(
        studioActionEvents.jiraTicketCreated,
        tasksToSync.map(v => v.toObject(canvasUtil.extraPropsToSave)),
        {
          userId,
          annotationId: annotation._id
        }
      );
    }

    /* update */
    if (toUpdate.length) {
      await Promise.all(
        toUpdate.map(t => {
          const { title, description } = parseTask(t.metaProp.content);
          return client.service("jira").patch(t.metaProp.ticket.id, {
            ticketTitle: title,
            ticketDescription: description,
            token,
            cloudId
          });
        })
      );
    }

    const notBusy = {};
    sTasks.forEach(t => (notBusy[t.metaProp.id] = false));
    setJiraBusy(notBusy);

    ellio.toaster.success("Finished bulk update successfully.");
  };

  return (
    <div className={klass([cls.wrapper, isOpen ? cls.open : cls.closed])}>
      <div className={cls.inner}>
        <div className={cls.top}>
          <div className={cls.toggles}>
            <button
              onClick={() => setTab("tasks")}
              className={klass([
                "mr",
                buttonCls.outlineSmallInvert,
                tab === "tasks" ? buttonCls.outlineActive : ""
              ])}
            >
              Tasks
            </button>
            <button
              onClick={() => setTab("comments")}
              className={klass([
                buttonCls.outlineSmallInvert,
                tab === "comments" && buttonCls.outlineActive
              ])}
            >
              Comments
            </button>
          </div>

          <div className={cls.closeWrapper}>
            <Tippy content="Close">
              <button
                className={cls.closeButton}
                onClick={() => setIsOpen(false)}
              >
                <img src={closeSvg} alt="close" />
              </button>
            </Tippy>
          </div>
        </div>

        <div className={cls.selectProjects}>
          {isJiraConnected ? (
            <Select
              options={projectsOptions}
              value={selectedProject || null}
              placeholder="Select project"
              onChange={v => {
                setSelectedProject(v);
                onProjectSelect(v);
              }}
            />
          ) : null}
        </div>
        {tab === "tasks" ? (
          <div className={klass([cls.scroll])}>
            {!tasks || !tasks.length ? <div>No tasks</div> : null}
            {tasks?.length && !selectedTasks.length ? (
              <div className={cls.extraTasksOptions}>
                {!isJiraConnected ? (
                  <Tippy content="Connect to Jira">
                    <a href="" onClick={connectJira} className="mr">
                      <ReactSVG className={cls.plug} src={plugSvg} />
                    </a>
                  </Tippy>
                ) : null}
                <button
                  hidden={!isJiraConnected}
                  onClick={() => createOrUpdateTickets(tasks)}
                  className={klass([buttonCls.link, "mr"])}
                >
                  Create/update tickets
                </button>
              </div>
            ) : null}
            {tasks?.length && selectedTasks.length ? (
              <div className={cls.extraTasksOptions}>
                <button
                  onClick={() => {
                    createOrUpdateTickets(selectedTasks)
                      .then(noop)
                      .catch()
                      .then(() => setSelectedTasks([]));
                  }}
                  className={klass([buttonCls.link, "mr"])}
                >
                  Create/update tickets ({selectedTasks.length})
                </button>
                <button
                  onClick={() => setSelectedTasks([])}
                  className={buttonCls.link}
                >
                  Deselect all
                </button>
              </div>
            ) : null}
            {tasks?.map((t, i) => (
              <div
                onClick={() => updateTaskSelect(t)}
                className={klass([
                  cls.block,
                  isTaskSelected(t) ? cls.blockActive : ""
                ])}
                key={t.metaProp.id}
              >
                <div className={cls.blockContent}>
                  <div className={cls.blockNumber}>
                    <span
                      style={{ background: getFillForItem(t) }}
                      className={cls.number}
                    >
                      {t.metaProp.taskNumber}
                    </span>
                  </div>
                  <div
                    className={cls.itemText}
                    dangerouslySetInnerHTML={{
                      __html: getTaskHtml(t.metaProp?.content)
                    }}
                  ></div>
                </div>
                <div className={cls.blockFooter}>
                  <Tippy
                    content={
                      !isJiraConnected
                        ? "Connect to Jira"
                        : t.metaProp.ticket
                        ? "Update Jira ticket"
                        : "Create Jira ticket"
                    }
                  >
                    <div style={{ display: "inline-block" }}>
                      <button
                        hidden={isJiraBusy(t)}
                        className={cls.newJiraTicketButton}
                        onClick={e => {
                          e.stopPropagation();
                          if (isJiraBusy(t)) {
                            return;
                          }
                          if (!isJiraConnected) {
                            connectJira(e);
                          } else {
                            if (t.metaProp.ticket) {
                              startJiraBusy(t)();
                              updateJiraTicket(t)
                                .then(noop)
                                .catch(failToast)
                                .then(endJiraBusy(t));
                            } else {
                              startJiraBusy(t)();
                              createJiraTicket(t)
                                .then(noop)
                                .catch(failToast)
                                .then(endJiraBusy(t));
                            }
                          }
                        }}
                      >
                        {!isJiraConnected ? (
                          <ReactSVG className={cls.plug} src={plugSvg} />
                        ) : (
                          <img src={jiraSvg} alt="Jira" />
                        )}
                      </button>
                      <div hidden={!isJiraBusy(t)} className="el-spinner"></div>
                    </div>
                  </Tippy>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={klass([cls.scroll])}>
            {!comments || !comments.length ? <div>No comments</div> : null}
            {comments?.map(t => (
              <div className={cls.block} key={t.metaProp.id}>
                <div className={cls.blockContent}>
                  <div className={cls.blockNumber}>
                    <span
                      style={{ background: getFillForItem(t) }}
                      className={cls.number}
                    >
                      {t.metaProp.commentNumber}
                    </span>
                  </div>
                  <p className={cls.itemText}>{t.metaProp?.content}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Drawer;
