import { useEffect, useState } from "react";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";
import auth from "@feathersjs/authentication-client";
import rest from "@feathersjs/rest-client";
import axios from "axios";
import { removeKeysFromLocalStorage as removeFigmaKeysFromLS } from "common/figma-common";

import { removeJiraKeysFromLS } from "common/jira-common";

const apiHost = window.ellio.apiHost || "http://localhost:3535";

const { NODE_ENV } = process.env;
console.log("env", NODE_ENV);
const isProd = NODE_ENV === "production";

/* rest client: only for certain things, one-off binary upload for example
 * because for some reason, the socket client throws an error when it comes
 * to uploading files.
 */
const restInstance = rest(window.ellio.apiHost);
const restClient = feathers();
const token = window.localStorage.getItem("feathers-jwt");
restClient.configure(restInstance.axios(axios));
restClient.configure(
  auth({
    storage: window.localStorage
  })
);

if (!isProd) {
  window.restClient = restClient;
}

/* socket client: for everything */
const socket = io(apiHost, {
  transports: ["polling", "websocket"],
  query: {
    hello: "world"
  }
});

const client = feathers();
client.configure(
  socketio(socket, {
    timeout: isProd ? 10e3 : 5e3
  })
);

client.configure(
  auth({
    storage: window.localStorage
  })
);

/* event handlers */
const serviceEvents = ["created", "patched", "removed", "updated"];
const useServiceEvents = (
  svc,
  cb,
  deps = [],
  events = serviceEvents,
  cbDelay
) => {
  useEffect(() => {
    if (svc.current) {
      events.forEach(e =>
        svc.current.on(e, d => {
          if (cbDelay) {
            setTimeout(() => {
              cb(d, e);
            }, cbDelay);
          } else {
            cb(d, e);
          }
        })
      );
    }
    return () => {
      if (svc.current) {
        events.forEach(e => svc.current.off(e));
      }
    };
  }, [...deps]);
};

const sendStudioEvent = (name, objects, meta = {}) => {
  if (!name || !objects) {
    throw new Error("must provide action name and associated studio objects.");
  }
  return client.service("studio-events").create({
    name,
    objects,
    meta: { ...meta, tabId: window.sessionStorage.getItem("tabId") }
  });
};

const sendUploadEvent = (name, objects, meta = {}) => {
  if (!name || !objects) {
    throw new Error("must provide action name and associated objects.");
  }
  return client.service("upload-events").create({
    name,
    objects,
    meta
  });
};

const cleanupLS = () => {
  window.localStorage.removeItem("ellio_active_folder");
  window.localStorage.removeItem("feathers-jwt");
  removeFigmaKeysFromLS();
  removeJiraKeysFromLS();
};

if (!isProd) {
  window.client = client;
}

const isValidSubscription = subscription => {
  if (!subscription) {
    return false;
  }

  if (subscription.status === "active" || subscription.status === "trialing") {
    return true;
  }

  return false;
};

const getResourceCount = (svc, query = {}) =>
  client
    .service(svc)
    .find({
      query: {
        $limit: 0,
        ...query
      }
    })
    .then(r => r.total);

const useUsage = ({
  user,
  services = ["folders", "tasks", "comments", "annotations"],
  cb = () => {}
}) => {
  const events = ["created", "removed", "patched"];
  const [usage, setUsage] = useState({
    images: null,
    folders: null,
    tasks: null,
    comments: null,
    annotations: null
  });

  const refreshUsage = () => {
    return Promise.all(
      services.map(svc =>
        getResourceCount(svc, {
          isDeleted: false,
          owner: {
            $in: [user._id]
          }
        })
      )
    )
      .then(r => {
        setUsage({
          folders: r[0],
          tasks: r[1],
          comments: r[2],
          annotations: r[3],
          images: r[3]
        });
        return r;
      })
      .catch(err => ellio.toaster.failure(err.message));
  };

  useEffect(refreshUsage, []);

  useEffect(() => {
    services.forEach(svcName => {
      events.forEach(event => {
        client.service(svcName).on(event, d => {
          cb();

          if (!isValidSubscription(user?.subscription)) {
            refreshUsage();
          }
        });
      });
    });

    return () => {
      services.forEach(svcName => {
        events.forEach(event => {
          client.service(svcName).off(event);
        });
      });
    };
  }, []);

  return {
    usage,
    setUsage,
    refreshUsage
  };
};

export default client;

export {
  useServiceEvents,
  sendStudioEvent,
  sendUploadEvent,
  restClient,
  serviceEvents,
  cleanupLS,
  isValidSubscription,
  getResourceCount,
  useUsage
};
