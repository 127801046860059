import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Tippy from "@tippyjs/react";
import client from "common/client";
import cls from "./settings.module.css";
import teamCls from "./team.module.css";
import teamModalCls from "./team-modal.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import orgSvg from "./org.svg";
import { ReactSVG } from "react-svg";
import { k } from "common/klass";
import { Link } from "react-router-dom";
import useScrollToTop from "common/use-scroll-to-top";
import routes from "routes";
import teamPng from "./team10.png";
import TeamModal from "./team-modal";
import s3Upload from "common/s3/s3-upload";
import MembersList, { useMembers } from "./members-list";
import Editable from "common/editable";
import DeleteOrgModal from "./delete-org-modal";
import picturePng from "./picture.png";
import ImageModal from "./image-modal";
import orgSquarePng from "./org-square.png";

const LogoPlaceholder = props => {
  return (
    <div className={teamModalCls.logoPlaceholder}>
      <ReactSVG src={orgSvg} />
    </div>
  );
};

export default function Team(props) {
  const { user, setUser, manageBilling } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingOrg, setIsCreatingOrg] = useState(false);
  useScrollToTop();

  /* TODO: find a better solution for avatars and logos
  this is a workaround to refresh user info
  as part of getting user info, it gets the company logo etc
  */

  useEffect(() => {
    client.service("users").get(user._id).then(setUser).catch(console.log);
  }, []);

  const isSub = user.subscription;
  const isTeams = user?.subscription?.name === "Teams";

  const [members, setMembers, membersErr] = useMembers({ user });
  const isSeatLimit =
    user?.subscription?.quantity > 0 &&
    user?.subscription?.quantity === members?.length;

  const [isDeletingOrg, setIsDeletingOrg] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState();
  const [isOpenLogoChangeModal, setIsOpenLogoChangeModal] = useState(false);
  const [isSavingImage, setIsSavingImage] = useState(false);

  const start = () => {
    setIsModalOpen(true);
  };

  const onCreateOrg = async d => {
    setIsCreatingOrg(true);
    const { name, file } = d;
    const orgSvc = client.service("organizations");
    let url = "";
    if (file) {
      try {
        url = await s3Upload({ file, uuid: uuidv4() });
      } catch (e) {
        console.log("could not upload logo");
      }
    }

    await orgSvc
      .create({
        orgNameId: name,
        name,
        logoUrl: url,
        adminEmail: user.email
      })
      .then(r => {
        ellio.toaster.success("Created organization successfully.");
        setIsModalOpen(false);
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
        setIsCreatingOrg(false);
      });
  };

  const deleteOrg = o => {
    setIsDeletingOrg(true);
    return client
      .service("organizations")
      .remove(o._id)
      .then(r => {
        ellio.toaster.success("Removed organization successfully");
        setOrgToDelete(null);
        setMembers([user]);
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
        setIsDeletingOrg(false);
      });
  };

  const updateOrgName = name => {
    return client
      .service("organizations")
      .patch(user?.org?._id, { name })
      .then(r => {
        ellio.toaster.success("Updated organization name successfully");
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
      });
  };

  const onUpdateLogo = image => {
    setIsSavingImage(true);
    if (image) {
      return s3Upload({
        file: image,
        uuid: uuidv4()
      })
        .then(url => {
          setIsSavingImage(false);
          return client.service("organizations").patch(user?.org?._id, {
            logoUrl: url
          });
        })
        .then(o => {
          setIsOpenLogoChangeModal(false);
          ellio.toaster.success("Updated logo  successfully");
          // setOrg(o)
          return o;
        })
        .catch(err => {
          setIsSavingImage(false);
          ellio.toaster.failure(err.message);
        });
    }
  };

  if (!user || !members) {
    return <div>Loading ...</div>;
  }

  return (
    <div className={teamCls.team}>
      <div className={k(cls.sectionHeader, !user.org && teamCls.hide)}>
        <h2 className={cls.sectionTitle}>Organization</h2>
      </div>

      {isSeatLimit && (
        <div className={teamCls.seatLimit}>
          <p className={typeCls.p3}>
            There are <strong>{user?.subscription?.quantity} seats</strong> in
            your plan, and you have reached the limit. You can use the{" "}
            <a onClick={manageBilling} href="">
              Customer Portal
            </a>{" "}
            to add more seats to your plan.
          </p>
        </div>
      )}

      {membersErr && <div className="mb">{membersErr.message}</div>}

      {user.org && (
        <div className={teamCls.org}>
          <div className={teamCls.orgDetails}>
            <div className={teamCls.orgLogo}>
              {user.org.logoUrl ? (
                <div
                  className={teamCls.orgLogoCover}
                  style={{ backgroundImage: `url(${user.org.logoUrl})` }}
                ></div>
              ) : (
                <div
                  style={{ border: "none" }}
                  className={teamModalCls.logoPlaceholder}
                >
                  <ReactSVG src={orgSvg} />
                </div>
              )}
              <div className={teamCls.changePicture}>
                <Tippy content="Change logo">
                  <button
                    onClick={() => {
                      setIsOpenLogoChangeModal(true);
                    }}
                    className={buttonCls.button}
                  >
                    <img draggable={false} src={picturePng} />
                  </button>
                </Tippy>
              </div>
            </div>
            <div>
              <Editable
                style={{ fontSize: "18px" }}
                value={user?.org?.name}
                onConfirm={updateOrgName}
              />
            </div>
            <button
              className={k(buttonCls.dangerSm, teamCls.delete)}
              onClick={() => setOrgToDelete(user.org)}
            >
              Delete organization
            </button>
          </div>
          <div className={teamCls.orgMembers}>
            <MembersList user={user} members={members} />
          </div>
        </div>
      )}

      {!user.org && (
        <div className={teamCls.teamImg}>
          <div className={teamCls.teamInner}>
            <div>
              <img draggable={false} src={teamPng} />
            </div>

            {isTeams ? (
              user.stripeCustomerId ? (
                <>
                  <div>
                    <p className={k(typeCls.p3, teamCls.teamIntro)}>
                      Create your organization and invite members. Click on the
                      button below to get started.
                    </p>
                  </div>
                  <button onClick={start} className={buttonCls.primarySm}>
                    Get started
                  </button>
                </>
              ) : (
                <div>
                  <p>This page is managed by your organization's admin.</p>
                </div>
              )
            ) : (
              <>
                <div>
                  <p className={k(typeCls.p3, teamCls.teamIntro)}>
                    Create your organization and invite members. This feature is
                    available in the Teams plan.
                  </p>
                </div>
                <Link
                  className={buttonCls.primarySm}
                  to={routes.settings.subRoutes.subscriptions.url}
                >
                  Upgrade
                </Link>
              </>
            )}
          </div>
        </div>
      )}

      {isModalOpen && (
        <TeamModal
          onCreate={onCreateOrg}
          isProcessing={isCreatingOrg}
          onClose={() => setIsModalOpen(false)}
        />
      )}

      {orgToDelete && (
        <DeleteOrgModal
          onClose={() => setOrgToDelete(null)}
          onDelete={deleteOrg}
          org={orgToDelete}
          isProcessing={isDeletingOrg}
        />
      )}

      {isOpenLogoChangeModal && (
        <ImageModal
          user={user}
          defaultImage={user?.org?.logoUrl || orgSquarePng}
          className={teamCls.changeLogoModal}
          title="Change logo"
          placeholder={LogoPlaceholder}
          onClose={() => setIsOpenLogoChangeModal(false)}
          isProcessing={isSavingImage}
          onSaveImage={onUpdateLogo}
        />
      )}
    </div>
  );
}
