import { useState, useEffect } from "react";
import Tippy from "@tippyjs/react";
import { track, trackActions } from "ui-track";
import { ReactSVG } from "react-svg";
import client from "common/client";
import Select from "react-select";
import klass from "common/klass";
import cls from "./jira-sync-modal.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import overlayCls from "common/overlay.module.css";
import checkSvg from "./check.svg";
import crossSvg from "./cross.svg";
import syncToJiraPng from "./sync-to-jira.png";
import arrowDownPng from "./arrow-down.png";
import { getS3KeyFromUrl } from "studio/canvas-util";

export default function ImportFromFigmaModal(props) {
  const {
    onClose,
    onSync,
    isProcessing,
    setIsProcessing,
    selectedProject,
    setSelectedProject,
    options,
    canvasObjects,
    design
  } = props;
  const [overlay, setOverlay] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [isStory, setIsStory] = useState(true);
  const [isPreview, setIsPreview] = useState(false);
  const [toCreate, setToCreate] = useState();
  const [toUpdate, setToUpdate] = useState();

  const objectWithStory = canvasObjects.find(
    v => typeof v.metaProp?.jiraStory === "object"
  );

  let story = objectWithStory?.metaProp?.jiraStory;

  const [storyTitle, setStoryTitle] = useState(story?.title || "");

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  useEffect(() => {
    async function genPreview() {
      const newTasks = canvasObjects
        .filter(
          v => v.metaProp?.type.includes("ellio_task") && !v.metaProp?.ticket
        )
        .map(v => v.metaProp)
        .map(async v => {
          const sUrl = v.snapshotUrl;
          const url = await client
            .service("uploads")
            .get(getS3KeyFromUrl(sUrl));
          return {
            ...v,
            snapshotUrl: url.signedUrl
          };
        });

      const existingTasks = canvasObjects
        .filter(
          v => v.metaProp?.type.includes("ellio_task") && v.metaProp?.ticket
        )
        .map(v => v.metaProp)
        .map(async v => {
          const sUrl = v.snapshotUrl;
          const url = await client
            .service("uploads")
            .get(getS3KeyFromUrl(sUrl));
          return {
            ...v,
            snapshotUrl: url.signedUrl
          };
        });

      const newTasksExpanded = await Promise.all(newTasks);
      const existingTasksExpanded = await Promise.all(existingTasks);

      return {
        newTasksExpanded,
        existingTasksExpanded
      };
    }

    genPreview().then(r => {
      setToCreate(r.newTasksExpanded);
      setToUpdate(r.existingTasksExpanded);
    });
  }, [isPreview]);

  useEffect(() => {
    if (design.jiraProject) {
      setSelectedProject(design.jiraProject);
    }
  }, [design.jiraProject]);

  const handleSync = opts => {
    const { isStory, storyTitle } = opts;
    track(trackActions.jira.sync);
    setIsProcessing(true);
    onSync({ isStory, storyTitle })
      .then(r => {
        setTimeout(() => {
          setIsProcessing(false);
          setIsConfirmed(true);
        }, 500);
      })
      .catch(err => {
        setIsProcessing(false);
      });
  };

  const previewCount = 2;

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={klass([cls.jiraSyncModal])}>
        {isConfirmed ? null : <h1 className={typeCls.ctrl5}>Sync with Jira</h1>}

        {isConfirmed ? (
          <div className={cls.confirm}>
            <ReactSVG className={cls.check} src={checkSvg} />
            <p className={typeCls.p}>
              Tasks are being synced with Jira, you will be notified at the top
              right corner of the page once it's finished.
            </p>
          </div>
        ) : (
          <div>
            {isPreview ? (
              <div className={cls.preview}>
                <section>
                  <h2 className={[typeCls.p3, "mb"].join(" ")}>
                    The Jira Story "{storyTitle}" will be{" "}
                    {story ? "updated" : "created"} under the "
                    {selectedProject.label}" project with {toCreate.length} new
                    Sub-tasks.{" "}
                    {toUpdate.length ? (
                      <span>
                        In addition, {toUpdate.length} Sub-tasks will be
                        updated.
                      </span>
                    ) : null}
                  </h2>
                </section>
                <section className={cls.toCreate}>
                  {toCreate.length === 0 ? (
                    <div className={cls.extraPreview}>
                      <span>0</span>
                    </div>
                  ) : null}
                  <div>
                    {toCreate.slice(0, previewCount).map((t, i) => (
                      <div key={t.id}>
                        <img
                          draggable={false}
                          className={cls.previewImage}
                          style={{
                            left: `${i * 14}px`,
                            top: `${i * 10}px`
                          }}
                          src={t.snapshotUrl}
                        />
                      </div>
                    ))}
                    {toCreate.length - previewCount - 1 > 0 ? (
                      <div
                        className={cls.extraPreview}
                        style={{
                          backgroundImage: `url(${toCreate[previewCount].snapshotUrl})`,
                          left: `${previewCount * 14}px`,
                          top: `${previewCount * 10}px`
                        }}
                      >
                        <span>+{toCreate.length - previewCount - 1}</span>
                      </div>
                    ) : null}
                  </div>
                  <p className={typeCls.p3}>New Sub-tasks</p>
                </section>

                <section className={cls.toUpdate}>
                  {toUpdate.length === 0 ? (
                    <div className={cls.extraPreview} style={{ top: "15px" }}>
                      <span>0</span>
                    </div>
                  ) : null}
                  <div>
                    {toUpdate.slice(0, previewCount).map((t, i) => (
                      <div key={t.id}>
                        <img
                          draggable={false}
                          className={cls.previewImage}
                          style={{
                            left: `${i * 14}px`,
                            top: `${i * 10}px`
                          }}
                          src={t.snapshotUrl}
                        />
                      </div>
                    ))}
                    {toUpdate.length - previewCount - 1 > 0 ? (
                      <div
                        className={cls.extraPreview}
                        style={{
                          backgroundImage: `url(${toUpdate[previewCount].snapshotUrl})`,
                          left: `${previewCount * 14}px`,
                          top: `${previewCount * 10}px`
                        }}
                      >
                        <span>+{toUpdate.length - previewCount - 1}</span>
                      </div>
                    ) : null}
                  </div>
                  <p className={typeCls.p3}>Sub-tasks to update</p>
                </section>
              </div>
            ) : (
              <div>
                <p className={typeCls.pMb2} style={{ marginTop: "18px" }}>
                  Syncing with Jira is one-way from Ellio to Jira.
                </p>
                <input
                  className={[inputCls.textInput, "mb"].join(" ")}
                  type="text"
                  placeholder="New Jira Story name"
                  disabled={story}
                  value={storyTitle}
                  onChange={e => {
                    setStoryTitle(e.target.value);
                  }}
                />

                <Select
                  isDisabled={design.jiraProject}
                  options={design.jiraProject ? [] : options || []}
                  value={
                    design.jiraProject
                      ? design.jiraProject
                      : selectedProject || null
                  }
                  placeholder="Select project from Jira"
                  onChange={setSelectedProject}
                />

                <div className={cls.graphic}>
                  <img draggable={false} src={syncToJiraPng} />
                </div>
              </div>
            )}
          </div>
        )}

        <div className={cls.footerButtons}>
          <div className={cls.buttonGroup}>
            {isPreview && !isConfirmed ? (
              <button
                className={buttonCls.primaryTextSmall}
                onClick={() => setIsPreview(false)}
              >
                Go back
              </button>
            ) : (
              <span />
            )}
          </div>
          <div className={cls.buttonGroup}>
            <button onClick={onClose} className={buttonCls.primaryTextSmall}>
              Close
            </button>
            {isConfirmed ? null : (
              <>
                {isPreview ? (
                  <button
                    disabled={!storyTitle || !selectedProject}
                    className={buttonCls.primarySm}
                    onClick={() => handleSync({ isStory, storyTitle })}
                  >
                    Sync{" "}
                    {isProcessing ? <div className="el-spinner"></div> : null}
                  </button>
                ) : (
                  <button
                    disabled={!storyTitle || !selectedProject}
                    className={buttonCls.primarySm}
                    onClick={() => setIsPreview(true)}
                  >
                    Preview sync
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
