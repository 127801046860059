import { useState, useEffect } from "react";
import cls from "./remove-member-modal.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import overlayCls from "common/overlay.module.css";
import client from "common/client";

export default function RemoveMemberModal(props) {
  const { onClose, onRemove, isProcessing, member } = props;
  const [overlay, setOverlay] = useState();

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.removeMember}>
        <h1 className={typeCls.ctrl5}>Are you sure?</h1>
        <p className={typeCls.p}>
          You are about to remove member "<strong>{member.email}</strong>". You
          can always them back later.
        </p>
        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          <button className={buttonCls.dangerSm} onClick={onRemove}>
            Remove {isProcessing ? <div className="el-spinner"></div> : null}
          </button>
        </div>
      </div>
    </div>
  );
}
