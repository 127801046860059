import { useRef, useEffect } from "react";
const noop = v => v;

export default function Video(props) {
  const videoRef = useRef();

  const {
    style,
    mp4,
    webm,
    loop = true,
    autoPlay = true,
    controls = false,
    width = "100%",
    onLoadStart = noop,
    onLoadedData = noop
  } = props;

  return (
    <video
      ref={videoRef}
      onLoadStart={onLoadStart}
      onLoadedData={onLoadedData}
      muted
      style={style}
      loop={loop}
      autoPlay={autoPlay}
      controls={controls}
      width={width}
    >
      <source src={webm} type="video/webm" />
      <source src={mp4} type="video/mp4" />
    </video>
  );
}
