import { useEffect } from "react";

export default function useZoomAndPan(opts) {
  const { canvas } = opts;
  useEffect(() => {
    if (canvas) {
      return;
      canvas.on("mouse:wheel", function (opt) {
        var delta = opt.e.deltaY;
        var evt = opt.e;
        console.log(delta);
        /* negative number zooms in */
        if (evt.altKey === true) {
          // var zoom = canvas.getZoom();
          // zoom *= 0.999 ** delta;
          // if (zoom > 20) zoom = 20;
          // if (zoom < 0.01) zoom = 0.01;
          // canvas.setZoom(zoom);
          // opt.e.preventDefault();
          // opt.e.stopPropagation();

          var delta = opt.e.deltaY;
          var zoom = canvas.getZoom();
          zoom *= 0.999 ** delta;
          if (zoom > 20) zoom = 20;
          if (zoom < 0.01) zoom = 0.01;
          canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
          opt.e.preventDefault();
          opt.e.stopPropagation();
          canvas.renderAll();
        }
      });
    }
  }, [canvas]);
}
