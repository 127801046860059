import { useEffect, useRef } from "react";
import shortId from "pkgs/random/id";

const useTabId = () => {
  const idRef = useRef(shortId());

  useEffect(() => {
    window.sessionStorage.setItem("tabId", idRef.current);
  }, []);

  return idRef.current;
};

export default useTabId;
