import { useEffect } from "react";
const useScrollToTop = () => {
  useEffect(() => {
    const wrapper = document.querySelector("#js-main");
    if (wrapper) {
      wrapper.scrollTo(0, 0);
    }
  }, []);
};

export default useScrollToTop;
