import { useState, useEffect, useRef } from "react";
import { track, trackActions } from "ui-track";
import cls from "./feedback-modal.module.css";
import { ReactSVG } from "react-svg";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import overlayCls from "common/overlay.module.css";
import inputCls from "common/input.module.css";
import client from "common/client";
import attachSvg from "./assets/attach.svg";
import FileSelect from "common/file-select";
import Tippy from "@tippyjs/react";
import s3Upload from "common/s3/s3-upload";
import { v4 as uuidv4 } from "uuid";
import checkSvg from "./check.svg";

export default function FeedbackModal(props) {
  const {
    onClose,
    onSend,
    isProcessing,
    setIsProcessing,
    setIsAllowDrag
  } = props;

  const [textArea, setTextArea] = useState();

  useEffect(() => {
    if (textArea) {
      textArea.focus();
    }
  }, [textArea]);

  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState();
  const [feedback, setFeedback] = useState({
    title: "",
    content: "",
    url: ""
  });
  const feedbackRef = useRef({
    title: "",
    content: ""
  });

  const [isInvalid, setInvalid] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [overlay, setOverlay] = useState();

  useEffect(() => {
    setIsAllowDrag(false);
    return () => setIsAllowDrag(true);
  }, []);

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  const onFileSelect = async files => {
    ellio.toaster.clear();
    if (!files[0]) {
      return;
    }
    setFile(files[0]);
    setUploading(true);
    setInvalid(false);

    const url = await s3Upload({
      file: files[0],
      uuid: uuidv4()
    });

    setUploading(false);

    setFeedback(p => ({ ...p, url }));
  };

  const onFileSelectErrors = errors => {
    /* TOOD: add an option to see which file errored out */
    if (errors?.length) {
      const first = errors[0].errors[0];
      const code = first.code;
      if (code === "file-too-large") {
        setInvalid(true);
        ellio.toaster.warning(
          "At least one of the selected files is too large.",
          { title: "File too large" }
        );
      } else if (code === "file-invalid-type") {
        ellio.toaster.warning(
          "At least one of the selected files was not an image."
        );
      }
    }
  };

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.feedbackModal}>
        {isConfirmed ? (
          <div className={cls.confirm}>
            <ReactSVG className={cls.check} src={checkSvg} />
            <p className={typeCls.p}>
              Your message was sent successfully! Thanks for trying out the
              beta. If you have any questions or issues, email{" "}
              <strong>
                <a href="mailto:contact@ellio.app">contact@ellio.app</a>
              </strong>
            </p>
          </div>
        ) : (
          <>
            <h1 className={typeCls.ctrl5}>Contact us</h1>
            <p className={typeCls.p}>
              Thanks for trying out the beta. If you have any suggestions,
              questions, or would like to report a bug, send us a message. You
              can also email us at{" "}
              <strong>
                <a href="mailto:contact@ellio.app">contact@ellio.app</a>
              </strong>
            </p>
            <div className={cls.inputs}>
              <input
                style={{ display: "none" }}
                placeholder="Title"
                type="text"
                className={inputCls.textInput}
                onChange={e => (feedbackRef.current.title = e.target.value)}
              />
              <textarea
                ref={setTextArea}
                className={inputCls.textarea}
                onChange={e => (feedbackRef.current.content = e.target.value)}
              />
            </div>
          </>
        )}

        <div className={cls.footer}>
          <div>
            <div className={[cls.attach, isConfirmed ? "hide" : ""].join(" ")}>
              {file ? (
                <div className={!uploading ? cls.deleteAttach : ""}>
                  <span>
                    {uploading ? (
                      <div className={cls.spinner}>
                        <span className="el-spinner"></span>
                      </div>
                    ) : (
                      file.name
                    )}
                  </span>
                  {uploading ? null : (
                    <Tippy content="Delete">
                      <button
                        className={buttonCls.button}
                        onClick={() => {
                          setFile(null);
                          setFeedback(p => ({
                            ...p,
                            url: ""
                          }));
                        }}
                      >
                        &times;
                      </button>
                    </Tippy>
                  )}
                </div>
              ) : (
                <FileSelect
                  isOpen={true}
                  onDrop={onFileSelect}
                  onErrors={onFileSelectErrors}
                  maxSize={30}
                  accept="video/*, image/*"
                  dropArea={() => (
                    <div className={cls.fileButton}>
                      <button className={buttonCls.button}>
                        <img draggable={false} src={attachSvg} />
                        <span className={typeCls.p3}>Attach file</span>
                      </button>
                      <div>
                        <p
                          className={[
                            typeCls.p3,
                            cls.fileSize,
                            isInvalid ? cls.invalid : ""
                          ].join(" ")}
                        >
                          Max file size: 30 MB
                        </p>
                      </div>
                    </div>
                  )}
                />
              )}
            </div>
          </div>
          <div className={cls.footerButtons}>
            <button
              onClick={() => {
                track(trackActions.contact.close);
                onClose();
              }}
              className={buttonCls.primaryTextSmall}
            >
              Close
            </button>
            {isConfirmed ? null : (
              <>
                <button
                  className={buttonCls.primarySm}
                  disabled={uploading}
                  onClick={() => {
                    setIsProcessing(true);
                    track(trackActions.contact.send);
                    onSend({
                      ...feedback,
                      ...feedbackRef.current
                    })
                      .then(r => {
                        ellio.toaster.success("Message was sent successfully");
                        setTimeout(() => {
                          setIsConfirmed(true);
                        }, 250);
                      })
                      .catch(err => {
                        if (/validation failed/i.test(err.message)) {
                          ellio.toaster.failure("Content was empty");
                        } else {
                          ellio.toaster.failure(err.message);
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setIsProcessing(false);
                        }, 250);
                      });
                  }}
                >
                  Send{" "}
                  {isProcessing ? <div className="el-spinner"></div> : null}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
