import { useEffect, useState } from "react";
import typeCls from "common/type.module.css";
import cls from "./block-user.module.css";
import client from "common/client";
import rocketPng from "./rocket.png";
import buttonCls from "common/button.module.css";
import confirmPng from "./confirm.png";
import checkSvg from "./check.svg";

export default function VerifyEmail(props) {
  const { user } = props;
  const [isVerified, setIsVerified] = useState();
  const [isSending, setIsSending] = useState(false);

  const betaUserMsg = `We couldn't find your email in the beta list. Please go to https://ellio.app to ask for an invite.`;

  useEffect(() => {
    const query = new window.URLSearchParams(window.location.search);
    const token = query.get("token");

    client
      .service("email-confirm")
      .create({
        verifyEmailToken: token
      })
      .then(r => {
        setIsVerified(r.isEmailVerified);
        if (r.isEmailVerified) {
          ellio.toaster.success("Your email was verified successfully", {
            dismiss: false
          });
        }
      })
      .catch(err => {
        setIsVerified(false);
      });
  }, []);

  const sendEmail = () => {
    if (!user.isBeta) {
      return;
    }
    setIsSending(true);
    client
      .service("email-confirm")
      .create({
        email: user.email
      })
      .then(() => {
        ellio.toaster.success(`Verification email was sent to ${user.email}`, {
          dismiss: false
        });
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  if (isVerified == null) {
    return (
      <div className={cls.notAllowed}>
        <div className="el-spinner"></div>
      </div>
    );
  }

  if (!user.isBeta) {
    return (
      <div className={cls.notAllowed}>
        <img draggable={false} className={cls.confirmImage} src={confirmPng} />
        <div className={cls.body}>
          <h1>Beta access</h1>
          <p className={typeCls.p2}>{betaUserMsg}</p>
        </div>
      </div>
    );
  }

  if (isVerified) {
    return (
      <div className={cls.notAllowed}>
        <img draggable={false} className={cls.confirmImage} src={confirmPng} />
        <div className={cls.body}>
          <h1>
            Email confirmed <img draggable={false} src={checkSvg} />
          </h1>
          <p className={typeCls.p2}>
            Thanks for confimring your email, your account was activated
            successfully.
          </p>
          <button
            onClick={() => {
              window.location.href = "/";
            }}
            className={buttonCls.primarySm}
          >
            Go to app
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={cls.notAllowed}>
      <img draggable={false} className={cls.confirmImage} src={confirmPng} />
      <div className={cls.body}>
        <h1>Code expired</h1>
        <p className={typeCls.p2}>
          The issued token has expired, please click on the button below to send
          another email.
        </p>
        <button
          disabled={isSending}
          onClick={sendEmail}
          className={buttonCls.primarySm}
        >
          Send email
        </button>
      </div>
    </div>
  );
}
