import { useEffect, useCallback } from "react";
const useSelect = textarea => {
  const keydownHandler = useCallback(e => {
    if (e.target === textarea.current && e.code === "KeyA" && e.metaKey) {
      textarea.current.select();
    }
  }, []);

  useEffect(() => {
    if (textarea.current) {
      textarea.current.focus();
      textarea.current.addEventListener("keydown", keydownHandler);
    }

    return () => {
      if (textarea.current) {
        textarea.current.removeEventListener("keydown", keydownHandler);
      }
    };
  }, []);
};

export default useSelect;
