const { NODE_ENV } = process.env;
const isProd = NODE_ENV === "production";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";
import client, { cleanupLS } from "common/client";
import { Spinner } from "@blueprintjs/core";
import useLogin from "common/use-login";
import Dash2 from "dash2";
import Providers from "common/providers";
import Account from "account/account";
import LoginForm from "account/login-form";
import SignupForm from "account/signup-form";
import LoginCodeModal from "account/login-code-modal";
import DOMPurify from "dompurify";
import Auth from "./auth";

function Main() {
  const {
    AUTH_STATUS,
    user,
    setUser,
    loginStatus,
    setLoginStatus
  } = useLogin();

  const [loginModal, setLoginModal] = useState();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const passwordLessAuth = userEmail => {
    cleanupLS();
    const clean = DOMPurify.sanitize(userEmail);
    return client
      .service("passwordless-auth")
      .create({
        email: clean
      })
      .then(r => {
        setLoginModal(r);
      });
  };

  const finishPasswordlessAuth = (t, _email) => {
    setIsLoggingIn(true);
    const cleanToken = DOMPurify.sanitize(t);
    return client
      .authenticate({
        strategy: "passwordless",
        email: _email || loginModal.email,
        token: cleanToken
      })
      .then(r => {
        setLoginStatus(AUTH_STATUS.LOGGED_IN);
        return client.service("users").get(r.user._id);
      })
      .then(u => {
        setUser(u);
        window.location.href = "/";
      })
      .catch(err => {
        // ellio.toaster.failure(err.message);
        // setIsLoggingIn(false);
        // return Promise.reject(err);
        window.location.href = "/";
      });
  };

  if (loginStatus === AUTH_STATUS.LOADING) {
    return (
      <div className="page-loading">
        <div className="el-spinner"></div>
      </div>
    );
  }

  if (loginStatus === AUTH_STATUS.NOT_LOGGED_IN) {
    return (
      <Router>
        <Switch>
          <Route path="/auth">
            <Auth finishPasswordlessAuth={finishPasswordlessAuth} />
          </Route>
          <Route path="/login">
            <Account
              status={AUTH_STATUS}
              isLoginForm={true}
              setLoginStatus={setLoginStatus}
              message={{
                title: "Welcome back",
                text:
                  "If you already have an account, use the options below to login."
              }}
              header={{
                text: "Not a member?",
                link: "/signup",
                buttonText: "Sign up"
              }}
              actionButton="Login"
            >
              <>
                <LoginForm
                  onLogin={r => setLoginStatus(AUTH_STATUS.LOGGED_IN)}
                  onFail={r => setLoginStatus(AUTH_STATUS.NOT_LOGGED_IN)}
                  passwordLessAuth={passwordLessAuth}
                />
                {loginModal && (
                  <LoginCodeModal
                    email={loginModal.email}
                    onClose={() => setLoginModal(null)}
                    onLogin={finishPasswordlessAuth}
                    resendCode={() => passwordLessAuth(loginModal.email)}
                    isProcessing={isLoggingIn}
                  />
                )}
              </>
            </Account>
          </Route>
          <Route path="/signup">
            <Account
              status={AUTH_STATUS}
              message={{
                title: "Create your account",
                text:
                  "Use the options below to create an account, no credit card required."
              }}
              setLoginStatus={setLoginStatus}
              header={{
                text: "Already a member?",
                link: "/login",
                buttonText: "Login"
              }}
              actionButton="Sign up"
            >
              <>
                <SignupForm
                  passwordLessAuth={passwordLessAuth}
                  onFail={r => setLoginStatus(AUTH_STATUS.NOT_LOGGED_IN)}
                />
                {loginModal && (
                  <LoginCodeModal
                    email={loginModal.email}
                    onClose={() => setLoginModal(null)}
                    onLogin={finishPasswordlessAuth}
                    resendCode={() => passwordLessAuth(loginModal.email)}
                    isProcessing={isLoggingIn}
                  />
                )}
              </>
            </Account>
          </Route>
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>
    );
  }

  if (user && user._id && loginStatus === AUTH_STATUS.LOGGED_IN) {
    return (
      <Router>
        <Providers user={user}>
          <Switch>
            <Route path="/">
              <Dash2 setUser={setUser} />
            </Route>
          </Switch>
        </Providers>
      </Router>
    );
  }

  return null;
}

export default Main;
