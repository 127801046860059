import { useEffect } from "react";
import { studioActionEvents } from "common/eve";
import { isTask } from "studio/canvas-util";
import { track, trackActions } from "ui-track";

const useTaskDbClick = opt => {
  const {
    canvas,
    annotationId,
    openTask,
    setOpenTask,
    emitAction,
    image,
    user
  } = opt;

  useEffect(() => {
    if (canvas) {
      canvas.on("mouse:dblclick", event => {
        track(trackActions.studio.dbclick);
        if (!image.collaborators.includes(user.email)) {
          console.log("not a collaborator");
          return;
        }

        const isActiveObjects = canvas.getActiveObject();

        const isDesign = event.target?.metaProp?.type.includes("ellio_design");
        if (isDesign && !isActiveObjects) {
          /* to account for zoom */
          // const pointer = canvas.getPointer(event.e);
          // console.log(event);
          // const { x, y } = pointer;
          const { x, y } = event.pointer;

          /* emit add task at location */
          emitAction({
            name: studioActionEvents.addTask,
            x,
            y,
            canvas,
            annotationId,
            setOpenTask
          });
        } else if (isTask(event.target)) {
          setOpenTask(event);
        }
      });
    }
  }, [canvas]);
};

export default useTaskDbClick;
