import cls from "./avatar.module.css";
import { useState, useEffect } from "react";
import { identicon } from "minidenticons";

const Avatar = props => {
  const now = String(+new Date());
  const { string = now, className, style, bgColor = "#000" } = props;
  const st = {
    ...style,
    background: bgColor
  };
  const [node, setNode] = useState();

  useEffect(() => {
    const image = identicon(string);
    if (node) {
      node.innerHTML = image;
      node.classList.add(cls.avatar);
      if (className) {
        node.classList.add(className);
      }
    }
  }, [node]);
  return <div style={st} ref={setNode}></div>;
};

export default Avatar;
