import cls from "./nav.module.css";
import { useEffect, useState } from "react";
import klass from "common/klass";
import Tippy from "@tippyjs/react";
import { Link, useLocation } from "react-router-dom";
import designsSvg from "./designs.svg";
import settingsSvg from "./settings.svg";
import planningSvg from "./planning.svg";
import compassSvg from "./compass.svg";
import projectsSvg from "./projects.svg";
import reportsSvg from "./reports.svg";
import logoutSvg from "./logout.svg";
import mapSvg from "./map.svg";
import dropletSvg from "./droplet.svg";
import briefcaseSvg from "./briefcase.svg";
import penSvg from "./pen.svg";
import fileSvg from "./file.svg";
import retroSvg from "./retro.svg";

const iconForNav = lnk => {
  const icons = {
    designs: penSvg,
    planning: mapSvg,
    projects: briefcaseSvg,
    reports: fileSvg,
    other: projectsSvg,
    settings: settingsSvg,
    retro: retroSvg,
    "figma page": dropletSvg
  };

  const k = lnk.display.toLowerCase();
  if (icons[k]) {
    return icons[k];
  }

  return icons.other;
};

const Nav = props => {
  const { links, onLogout } = props;
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState("");

  const logout = e => {
    e.preventDefault();
    onLogout();
  };

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  const isActiveRoute = link => {
    return activeRoute.includes(link.url);
  };

  return (
    <nav className={cls.nav}>
      <ul className={cls.navList}>
        {links.map((link, i) => (
          <li key={link.id} className={cls.navItem}>
            <Tippy content={link.display} placement="right">
              <Link
                draggable={false}
                className={klass([
                  cls.navLink,
                  isActiveRoute(link) ? cls.active : ""
                ])}
                to={link.url}
              >
                <img draggable={false} src={iconForNav(link)} />
              </Link>
            </Tippy>
          </li>
        ))}
      </ul>
      <Tippy placement="right" content="Log out">
        <a
          draggable={false}
          href=""
          onClick={logout}
          className={klass([cls.navLink, cls.logout])}
        >
          <img draggable={false} src={logoutSvg} />
        </a>
      </Tippy>
    </nav>
  );
};

export default Nav;
