import { useState, useEffect } from "react";
import { urlToFile } from "common/blob";
import s3Upload from "common/s3/s3-upload";
import { v4 as uuidv4 } from "uuid";
import cls from "./shortcut-sync-modal.module.css";
import { k } from "common/klass";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import overlayCls from "common/overlay.module.css";
import client from "common/client";
import Select from "react-select";
import syncToShortcutPng from "./sync-to-shortcut.png";

const toOption = vs =>
  vs.map(v => ({
    value: v.id,
    label: v.name
  }));

export default function ShortcutSyncModal(props) {
  const { onClose, user, design, canvas, designAnnotation, setUser } = props;
  const [overlay, setOverlay] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [projects, setProjects] = useState();
  const [token, setToken] = useState(user.shortcutToken || "");
  const [storyTitle, setStoryTitle] = useState("");
  const [selectedProject, setSelectedProject] = useState();

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  const refreshProjects = () => {
    ellio.toaster.clear();
    return client
      .service("shortcut")
      .find({
        query: {
          resource: "projects"
        }
      })
      .then(r => {
        console.log(r.data);
        setProjects(r.data);
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
        return client
          .service("shortcut")
          .remove(user.shortcutToken, {
            query: {
              resource: "token"
            }
          })
          .then(u => {
            setUser(u);
            setToken("");
          });
      });
  };

  /* get projects */
  useEffect(() => {
    if (user.shortcutToken) {
      refreshProjects();
    }
  }, [user]);

  const sync = async () => {
    setIsProcessing(true);
    const tasks = designAnnotation?.model?.objects
      .filter(v => v.metaProp?.type.includes("task"))
      .map(v => v.metaProp);

    const canvasDataUrl = canvas.toDataURL({
      format: "jpeg",
      quality: 1,
      enableRetinaScaling: true
    });

    const canvasFile = await urlToFile(canvasDataUrl);

    const canvasUrl = await s3Upload({ uuid: uuidv4(), file: canvasFile });

    client
      .service("shortcut")
      .create(
        {
          name: storyTitle,
          description: "",
          project_id: selectedProject.value,
          tasks,
          canvasUrl
        },
        {
          query: {
            resource: "story"
          }
        }
      )
      .then(r => {
        const { story_id } = r;
        setIsProcessing(false);
        console.log(story_id);
      })
      .catch(err => {
        setIsProcessing(false);
        ellio.toaster.failure(err.message);
      });
  };

  const sendToken = () => {
    console.log("token is", token);
    client
      .service("shortcut")
      .create(
        {
          token
        },
        {
          query: {
            resource: "token"
          }
        }
      )
      .then(u => {
        setUser(u);
      })
      .catch(err => ellio.toaster.failure(err.message));
  };

  const shortcutProjects = projects ? toOption(projects) : [];

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.shortcutSyncModal}>
        <h1 className={typeCls.ctrl5}>Sync with Shortcut</h1>
        <p className={typeCls.pMb2} style={{ marginTop: "18px" }}>
          Syncing with Shortcut is one-way from Ellio to Shortcut.
        </p>

        {user.shortcutToken ? (
          <>
            <input
              className={k(inputCls.textInput, "mb")}
              type="text"
              placeholder="New story name"
              disabled={false}
              value={storyTitle}
              onChange={e => {
                setStoryTitle(e.target.value);
              }}
            />

            <Select
              isDisabled={false}
              options={shortcutProjects}
              value={selectedProject}
              placeholder="Select project from Shortcut"
              onChange={setSelectedProject}
            />
          </>
        ) : (
          <input
            className={k(inputCls.textInput, "mb")}
            type="text"
            placeholder="Shortcut API token"
            disabled={false}
            value={token}
            onChange={e => {
              setToken(e.target.value);
            }}
          />
        )}

        <div className={cls.graphic}>
          <img draggable={false} src={syncToShortcutPng} />
        </div>

        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          {user.shortcutToken ? (
            <button
              disabled={isProcessing}
              className={buttonCls.primarySm}
              onClick={sync}
            >
              Sync {isProcessing ? <div className="el-spinner"></div> : null}
            </button>
          ) : (
            <button
              disabled={isProcessing}
              className={buttonCls.primarySm}
              onClick={sendToken}
            >
              Set token{" "}
              {isProcessing ? <div className="el-spinner"></div> : null}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
