export default function multiSelect(list, item, selectedList) {
  const selectedIdx = list.findIndex(v => v._id === item._id);
  const lastSelIdx = list.findIndex(
    v => v._id === selectedList.slice(-1)[0]._id
  );
  const firstSelIdx = list.findIndex(v => v._id === selectedList[0]._id);
  const dir = selectedIdx > lastSelIdx ? "right" : "left";

  const start = dir === "right" ? firstSelIdx : selectedIdx;
  const end = dir === "right" ? selectedIdx : firstSelIdx;
  const toAdd = list.slice(start, end + 1);
  return toAdd;

  // setSelectedDesigns(p => toAdd);
}
