import { useState, useEffect } from "react";
import cls from "./new-folder-modal.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import overlayCls from "common/overlay.module.css";
import client from "common/client";

const NewFolderModal = props => {
  const { onClose, onNewFolder, isProcessing, setIsProcessing } = props;
  const [overlay, setOverlay] = useState();
  const [folderName, setFolderName] = useState("new folder");
  const [input, setInput] = useState();

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  useEffect(() => {
    if (input) {
      input.focus();
      input.select();
    }
  }, [input]);

  const handleCreate = v => {
    setIsProcessing(true);
    onNewFolder(v)
      .then(r => {
        setIsProcessing(false);
        onClose();
      })
      .catch(err => {
        setIsProcessing(false);
      });
  };

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.newFolderModal}>
        <h1 className={typeCls.ctrl5}>New folder</h1>
        <input
          ref={setInput}
          className={inputCls.textInput}
          placeholder="new folder"
          value={folderName}
          type="text"
          onChange={e => setFolderName(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleCreate({ name: folderName });
            }
          }}
        />
        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          <button
            className={buttonCls.primarySm}
            onClick={() => handleCreate({ name: folderName })}
          >
            Create {isProcessing ? <div className="el-spinner"></div> : null}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewFolderModal;
