const { NODE_ENV } = process.env;
const isProd = NODE_ENV === "production";
import { track, trackActions } from "ui-track";
import Boundary, { useException } from "common/error";
import { useHotkeys } from "react-hotkeys-hook";
import cls from "./dash2.module.css";
import { useCallback, useState, useEffect, useContext } from "react";
import Tippy from "@tippyjs/react";
import Dropdown from "common/dropdown";
import typeCls from "common/type.module.css";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useHistory,
  Link
} from "react-router-dom";
import { newEve, studioActionEvents } from "common/eve";
import client, {
  restClient,
  sendStudioEvent,
  cleanupLS,
  useUsage,
  useServiceEvents
} from "common/client";
import klass from "common/klass";
import UserCtx from "common/user.context";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import useTabId from "common/use-tab-id";
import { Spinner } from "@blueprintjs/core";
import { ReactSVG } from "react-svg";

import ReactTimeAgo from "react-time-ago";
import Breadcrumb from "common/breadcrumb";
import useBreadcrumb from "common/breadcrumb/use-breadcrumb";
import routes, { defaultRoute, routesList } from "routes";
import Nav from "./nav";

import logoWithTextSvg from "./assets/logo-with-text.svg";
import uploadCloud from "./assets/upload.svg";
import bellSvg from "./assets/bell.svg";
import checkSvg from "./assets/check.svg";
import crossSvg from "./assets/cross.svg";
import feedbackSvg from "./assets/feedback.svg";
import layersSvg from "./assets/layers.svg";

import Avatar from "common/avatar";
import useStream from "common/stream/use-stream";

/* TODO: turn these into one hook: useService(name, params, deps) */
import useImages from "./use-images";
import useFolders from "./use-folders";
import useNotifications from "./use-notifications";

import useActiveUsers from "./use-active-users";

import Designs from "designs";
import DesignDetails from "design-details";
import Settings from "settings";

import DesignsSelector from "./designs-selector";
import Jira from "jira";
import Figma from "figma";
import BlockUser from "./block-user";
import VerifyEmail from "./verify-email";
import FeedbackModal from "./feedback-modal";

const Dash2 = props => {
  const { setUser } = props;
  const user = useContext(UserCtx);
  const userId = user._id;
  const history = useHistory();
  const tabId = useTabId();
  // const [readOnly, setReadOnly] = useState(false);

  // if (user.mode === 'read-only') {
  //   console.log('app is read only now')
  // }

  const [plansInfo, setPlansInfo] = useState();

  useEffect(() => {
    if (user) {
      client
        .service("plans")
        .find()
        .then(r => setPlansInfo(r.data))
        .catch(err => {
          ellio.toaster.failure(err.message);
        });
    }
  }, [user]);

  /* when payment is processed and subscription is active, or canceled,
   * refresh user.
   */
  useEffect(() => {
    client.service("users").on("payment", d => {
      client
        .service("users")
        .get(user._id)
        .then(setUser)
        .catch(err => {
          ellio.toaster.failure(err.message);
        });
    });

    client.service("users").on("subscription_cancel", d => {
      ellio.toaster.success("Subscription was canceled successfully.");
      client
        .service("users")
        .get(user._id)
        .then(setUser)
        .catch(err => {
          ellio.toaster.failure(err.message);
        });
    });
  }, []);

  const [isFileSelectorOpen, setIsFileSelectorOpen] = useState(false);
  const [imagesUploading, setImagesUploading] = useState({});
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [activeDesign, setActiveDesign] = useState();
  const [isAllowDrag, setIsAllowDrag] = useState(true);

  const [breadItems] = useBreadcrumb();
  const location = useLocation();
  const { emit: emitGlobalEvent, ref: globalEveRef } = newEve("global");
  const [isFeedback, setIsFeedback] = useState(false);
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);

  const [activeFolder, setActiveFolder] = useState(
    JSON.parse(window.localStorage.getItem("ellio_active_folder"))
  );
  const handleSetActiveFolder = v => {
    window.localStorage.setItem("ellio_active_folder", JSON.stringify(v));
    setActiveFolder(v);
  };
  const [activeFolderImages, setActiveFolderImages] = useState();
  const [activeImagesLoading, setActiveImagesLoading] = useState(false);

  useHotkeys(
    "escape",
    e => {
      if (isFileSelectorOpen) {
        setIsFileSelectorOpen(false);
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"]
    },
    [isFileSelectorOpen]
  );

  const { usage, refreshUsage } = useUsage({ user });

  const {
    images,
    setImages,
    imagesError,
    deleteImage,
    deleteImages,
    refreshImages
  } = useImages(
    {
      query: {
        isDeleted: false,
        parent: activeFolder ? activeFolder._id : ""
      }
      // query: {
      //   $sort: {
      //     createdAt: -1
      //   }
      // }
    },
    [user]
  );

  const {
    folders,
    foldersError,
    createFolder,
    deleteFolder,
    deleteFolders,
    refreshFolders
  } = useFolders(
    {
      // query: {
      //   $sort: {
      //     createdAt: -1
      //   }
      // }
    },
    [user]
  );

  const syncAnnotationTickets = async d => {
    /* need to pull the latest annotation model, and need to update
     * the nodes that have been updates. Need to update the model this way
     * because notification comes later in time, we need to subscribe to
     * when the notification comes through.
     */
    const {
      data: { annotationId, issues }
    } = d;

    if (d.type === "ellio:sync_tasks_to_tickets") {
      const annObject = await client.service("annotations").get(annotationId);
      const objects = annObject.model.objects.filter(v =>
        issues.find(is => is.task.taskId === v.metaProp?.id)
      );

      sendStudioEvent(studioActionEvents.jiraTicketCreated, objects, {
        userId,
        annotationId,
        syncOwn: true
      });
    }
  };

  const { notifications, notificationsErr } = useNotifications(
    {
      query: {
        isDismissed: false,
        userId: user._id,
        $sort: {
          createdAt: -1
        }
      }
    },
    [],
    [
      {
        fn: () =>
          client
            .service("images")
            .find({
              query: {
                isDeleted: false
              }
            })
            .then(r => setImages(r.data))
      },
      { fn: refreshFolders },
      { event: "created", fn: syncAnnotationTickets }
    ]
  );

  useEffect(() => {
    client.service("notifications").on("created", d => {
      if (d.type === "upgrade") {
        ellio.toaster.warning(d.message, {
          title: "Version update"
        });
        // if (d.data.mode === 'read-only') {
        //   console.log("yes, readonly");
        //   setReadOnly(true);
        // }
        client
          .service("notifications")
          .find({
            query: {
              userId: user._id,
              $sort: {
                createdAt: -1
              }
            }
          })
          .then(r => {
            if (r.total) {
              const n = r.data[0];
              return client.service("notifications").patch(n._id, {
                isDismissed: true
              });
            }
          });
      }
    });

    return () => {
      client.service("notifications").off("created");
    };
  }, []);

  const { activeUsers, refreshActiveUsers } = useActiveUsers(
    activeDesign,
    user
  );
  const [isInvalidUser, setIsInvalidUser] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useStream(
    client.service("images"),
    v => {
      if (["created", "removed", "patched"].includes(v.event)) {
        const imagesQueryParams = activeFolder
          ? {
              query: {
                isDeleted: false,
                parent: activeFolder._id
              }
            }
          : {
              query: {
                isDeleted: false
              }
            };
        client
          .service("images")
          .find(imagesQueryParams)
          .then(r => {
            if (activeFolder) {
              setActiveFolderImages(r.data);
            } else {
              setImages(r.data);
            }
          });
      }
    },
    {
      every: 800,
      deps: [activeFolder]
    }
  );

  useEffect(() => {
    setActiveImagesLoading(true);
    // setActiveFolderImages([]);
    if (activeFolder) {
      client
        .service("images")
        .find({
          query: {
            parent: activeFolder._id,
            isDeleted: false
          }
        })
        .then(r => {
          setActiveFolderImages(r.data);
        })
        .catch(err => ellio.toaster.failure(err.message))
        .finally(() => {
          setActiveImagesLoading(false);
        });
    } else {
      setActiveFolderImages([]);
    }
  }, [activeFolder, images]);

  const noop = v => v;
  const logout = useCallback(
    async (redirectTo = "/") => {
      if (activeDesign) {
        await client.service("active-users").remove(null, {
          query: {
            userId,
            imageId: activeDesign._id
          }
        });
      }

      cleanupLS();

      try {
        await client.logout();
        window.location.href = redirectTo;
      } catch (err) {
        window.location.href = redirectTo;
      }
    },
    [activeDesign]
  );

  /* if user not beta user, show block page */
  useEffect(() => {
    const email = user.email;
    setIsInvalidUser(!user.isBeta || !user.isEmailVerified);
    setIsLoading(false);
  }, [user]);

  /* primary authentication happens by the socket client,
   * here I'm passing the jwt token also to the rest client.
   * the rest client is only used for one-off cases like binary upload
   * i tried to upload binary files with the socket client, it didnt
   * work ...
   */
  // useEffect(() => {
  //   restClient
  //     .reAuthenticate()
  //     .then(r => r)
  //     .catch(err => {
  //       console.log("could not authenticate REST client.", err);
  //     });
  // }, []);

  /* refresh user when org created or updated */
  useServiceEvents(
    {
      current: client.service("organizations")
    },
    async d => {
      const u = await client.service("users").get(user._id);
      setUser(u);
    }
  );

  const [value, setValue] = useState("");
  const onChange = e => setValue(e.target.value);

  const logoutAndGoToEllio = e => {
    e.preventDefault();
    logout("https://ellio.app");
  };

  const dismissMyNotifications = async () => {
    client
      .service("notifications")
      .patch(
        null,
        { isDismissed: true },
        {
          query: {
            userId,
            isDismissed: false
          }
        }
      )
      .then(r => {
        // console.log(r);
      })
      .catch(console.log);
  };

  const dismissNotifiation = async n => {
    client.service("notifications").patch(n._id, {
      isDismissed: true
    });
  };

  const onFileSelectErrors = errors => {
    /* TOOD: add an option to see which file errored out */
    if (errors?.length) {
      const first = errors[0].errors[0];
      const code = first.code;
      if (code === "file-too-large") {
        ellio.toaster.warning(
          "At least one of the selected files is too large."
        );
      } else if (code === "file-invalid-type") {
        ellio.toaster.warning(
          "At least one of the selected files was not an image."
        );
      }
    }
  };

  const [exception] = useException({
    userId
  });

  if (isLoading || !plansInfo) {
    return (
      <div className="page-loading">
        <div className="el-spinner"></div>
      </div>
    );
  }

  if (isInvalidUser) {
    return (
      <div>
        <div className={cls.logoutTop}>
          <button onClick={logout} className={buttonCls.linkMd}>
            Log out
          </button>
        </div>
        <Switch>
          <Route path="/verify_email">
            <VerifyEmail user={user} />
          </Route>
          <Route path="/">
            <BlockUser user={user} logoutAndGoToEllio={logoutAndGoToEllio} />
          </Route>
        </Switch>
      </div>
    );
  }

  return (
    <div className={cls.dashboard}>
      <div id="js-spinner" className={window.ellio.spinner.klass.wrapper}></div>
      {user.mode === "read-only" && (
        <div className={cls.readOnly}>
          Currently, the application is in read-only mode until maintanance is
          finished. Sorry for the inconvenience.
        </div>
      )}

      <div className={cls.header}>
        <div className={cls.headerLeft}>
          <img
            draggable={false}
            onClick={() => history.push("/")}
            className={cls.logo}
            src={logoWithTextSvg}
            alt="Logo"
          />

          <span className={cls.beta}>Beta</span>
        </div>
        <div className={cls.headerRight}>
          <input
            style={{ display: "none" }}
            className={inputCls.input}
            value={value}
            onChange={onChange}
            placeholder="Search"
          />
          {(!user?.subscription ||
            user?.subscription?.status === "canceled") && (
            <button
              onClick={() =>
                history.push(routes.settings.subRoutes.subscriptions.url)
              }
              className={klass([
                cls.button,
                cls.upgradeButton,
                buttonCls.svgIcon
              ])}
            >
              Upgrade
              <ReactSVG src={layersSvg} />
            </button>
          )}
          <Dropdown
            autoClose={false}
            className={cls.notificationsMenu}
            placement="bottom-end"
            trigger={
              <button className={klass([buttonCls.iconOnly, "rel"])}>
                <img alt="Notification" src={bellSvg} />
                <span
                  style={{
                    display: notifications?.length > 0 ? "flex" : "none"
                  }}
                  className={cls.bellCircle}
                >
                  {notifications?.length}
                </span>
              </button>
            }
          >
            <div className={klass(["my-menu", cls.notificationsInner])}>
              <div className={cls.notificationsHeader}>
                <h2 className={typeCls.ctrlDarkNavy}>Notifications</h2>
                <a
                  style={{
                    display: notifications?.length === 0 ? "none" : "block"
                  }}
                  onClick={e => {
                    e.preventDefault();
                    dismissMyNotifications();
                  }}
                  href=""
                >
                  Mark all as read
                </a>
              </div>
              <div className={cls.notifyBody}>
                {notifications?.length === 0 ? (
                  <div className={cls.notifyEmpty}>
                    <ReactSVG src={checkSvg} />
                  </div>
                ) : (
                  <div className={cls.notifyList}>
                    {notifications?.map(n => (
                      <div className={cls.notifyRow} key={n._id}>
                        <div className={cls.notifyDismiss}>
                          <Tippy content="Dismiss">
                            <button
                              className={buttonCls.button}
                              onClick={e => {
                                dismissNotifiation(n);
                              }}
                            >
                              <img src={crossSvg} />
                            </button>
                          </Tippy>
                        </div>
                        <h2 className={typeCls.p2}>{n.message}</h2>
                        <ReactTimeAgo
                          className={typeCls.p3}
                          date={
                            n.updatedAt ? new Date(n.updatedAt) : new Date()
                          }
                          locale="en-US"
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Dropdown>

          <div
            className={cls.profile}
            onClick={() => {
              history.push(routes.settings.subRoutes.profile.url);
            }}
          >
            {user?.settings?.profileImageUrl ? (
              <div
                className={cls.profileImage}
                style={{
                  backgroundImage: `url(${user?.settings?.profileImageUrl})`
                }}
              ></div>
            ) : (
              <Avatar
                string={user.email}
                bgColor={user.settings.avatar.bgColor}
              />
            )}
          </div>
        </div>
      </div>

      <div className={cls.breadcrumb}>
        <Breadcrumb items={breadItems} location={location} />
      </div>

      <div className={cls.nav} id="js-nav">
        <Nav links={routesList} onLogout={logout} />
      </div>

      <div id="js-main" className={cls.main}>
        <Switch>
          <Route path="/jira">
            <Jira />
          </Route>
          <Route path="/figma">
            <Figma user={user} />
          </Route>
          <Route path={routes.settings.url}>
            <Boundary exception={exception}>
              <Settings
                user={user}
                setUser={setUser}
                usage={usage}
                refreshUsage={refreshUsage}
                plansInfo={plansInfo}
                setIsAllowDrag={setIsAllowDrag}
              />
            </Boundary>
          </Route>
          <Route exact path={routes.designs.subRoutes.details.url}>
            <Boundary exception={exception}>
              <DesignDetails
                activeUsers={activeUsers}
                refreshActiveUsers={refreshActiveUsers}
                user={user}
                setUser={setUser}
                onActiveDesign={setActiveDesign}
                onOpenFileSelector={() => setIsFileSelectorOpen(true)}
                refreshImages={refreshImages}
                refreshFolders={refreshFolders}
                setIsAllowDrag={setIsAllowDrag}
                activeFolder={activeFolder}
                activeFolderImages={activeFolderImages}
                images={images}
                plansInfo={plansInfo}
              />
            </Boundary>
          </Route>
          <Route path={routes.designs.url}>
            <Boundary exception={exception}>
              <Designs
                activeFolder={activeFolder}
                setActiveFolder={handleSetActiveFolder}
                activeFolderImages={activeFolderImages}
                activeImagesLoading={activeImagesLoading}
                folders={folders}
                refreshFolders={refreshFolders}
                createFolder={createFolder}
                foldersError={foldersError}
                images={images}
                setImages={setImages}
                refreshImages={refreshImages}
                imagesError={imagesError}
                deleteImage={deleteImage}
                deleteImages={deleteImages}
                deleteFolder={deleteFolder}
                deleteFolders={deleteFolders}
                globalEveRef={globalEveRef}
                onOpenFileSelector={() => setIsFileSelectorOpen(true)}
                imagesUploading={imagesUploading}
                isUploadInProgress={isUploadInProgress}
                setUser={setUser}
              />
            </Boundary>
          </Route>

          <Redirect from="/" to={defaultRoute.url} />
        </Switch>
      </div>

      <DesignsSelector
        refreshImages={refreshImages}
        activeFolder={activeFolder}
        setActiveFolder={setActiveFolder}
        activeFolderImages={activeFolderImages}
        user={user}
        onFileSelectErrors={onFileSelectErrors}
        onIsUploadInProgress={setIsUploadInProgress}
        imagesUploading={imagesUploading}
        setImagesUploading={setImagesUploading}
        isFileSelectorOpen={isFileSelectorOpen}
        setIsFileSelectorOpen={setIsFileSelectorOpen}
        isAllowDrag={isAllowDrag}
        images={images}
      />

      <div className={cls.feedbackWrapper}>
        <Tippy content="Contact us">
          <button
            onClick={() => {
              track(trackActions.contact.start);
              setIsFeedback(true);
            }}
            className={[buttonCls.button, cls.feedback].join(" ")}
          >
            <ReactSVG src={feedbackSvg} />
          </button>
        </Tippy>
      </div>

      {isFeedback && (
        <FeedbackModal
          setIsAllowDrag={setIsAllowDrag}
          onSend={f => client.service("feedback").create(f)}
          isProcessing={isSendingFeedback}
          setIsProcessing={setIsSendingFeedback}
          onClose={() => setIsFeedback(false)}
        />
      )}
    </div>
  );
};

export default Dash2;
