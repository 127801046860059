const { NODE_ENV } = process.env;
const isProd = NODE_ENV === "production";
import mixpanel from "mixpanel-browser";

export const trackActions = {
  figma: {
    startImport: "figma_start_import",
    import: "figma_finish_import"
  },
  designs: {
    addButton: "add_designs_button_when_no_designs",
    view: "designs_view_single_design",
    dragOver: "add_designs_drag_over_to_page",
    dragDrop: "add_designs_drag_drop_on_page",
    deleteIcon: "designs_delete_single_design",
    deleteIconConfirm: "designs_delete_single_design_confirm",

    collabIcon: "designs_collab_single_design_icon",
    collabManage: "designs_collab_manage_for_signle_design"
  },
  contact: {
    start: "contact_start_button",
    send: "contact_send_button",
    close: "contact_close_button"
  },
  studio: {
    drawLine: "studio_select_draw_line_option",
    startJira: "studio_select_sync_with_jira_option",
    dbclick: "studio_canvas_double_click"
  },
  jira: {
    sync: "jira_sync_action"
  }
};

export default function init(debug = !isProd) {
  if (!isProd) {
    return;
  }
  mixpanel.init("185ece54953a83f6b5d620363a609b05", { debug });
}

export const track = (action, params = {}) => {
  if (!isProd) {
    return;
  }
  if (!action) {
    return;
  }

  mixpanel.track(action, { ...params, env: isProd ? "production" : "local" });
};

export const identify = user => {
  if (!isProd) {
    return;
  }
  if (!user.email) {
    return;
  }
  mixpanel.identify(user.email);
};
