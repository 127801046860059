import { useState, useRef, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { track, trackActions } from "ui-track";
import client from "common/client";
import cls from "./design-preview.module.css";
import inputCls from "common/input.module.css";
import { useHotkeys } from "react-hotkeys-hook";
import Editable from "common/editable";
import { ReactSVG } from "react-svg";
import buttonCls from "common/button.module.css";
import Tippy from "@tippyjs/react";
import klass from "common/klass";
import { useHistory } from "react-router-dom";
import { Button } from "@blueprintjs/core";
import ReactTimeAgo from "react-time-ago";
import downloadSvg from "./download.svg";
import trashSvg from "./trash.svg";
import copySvg from "./copy.svg";
import personAddSvg from "./person-add.svg";
import InviteUsers from "studio/invite-users";
import DeleteImageModal from "./delete-image-modal";
import checkSvg from "./check.svg";
import circleSvg from "./circle.svg";
import Collaborators from "common/collaborators";

const DesignPreview = props => {
  const {
    user,
    image,
    onDelete,
    isProgress,
    selectDesign,
    selectedDesigns,
    resetSelect,
    canSelectDesign,
    uploadStatus
  } = props;
  const match = useRouteMatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const [startDelete, setStartDelete] = useState(false);
  const [isInvitingUsers, setIsInvitingUsers] = useState(false);
  const history = useHistory();

  useHotkeys(
    "escape",
    e => {
      if (isInvitingUsers) {
        setIsInvitingUsers(false);
      }

      if (startDelete) {
        setStartDelete(false);
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"]
    },
    [isInvitingUsers, startDelete]
  );

  const handleEditName = newName => {
    client
      .service("images")
      .patch(image._id, {
        name: newName
      })
      .then(r => ellio.toaster.success("Updated image name successfully"))
      .catch(err => ellio.toaster.failure(err.message));
  };

  const isThisSelected = selectedDesigns?.find(im => im._id === image._id);

  return (
    <div className={cls.box}>
      <div className={cls.design}>
        <Collaborators
          style={{
            left: "15px",
            bottom: "15px"
          }}
          collaborators={image.collabsWithProfileImage}
        />
        <div
          className={klass([
            cls.options,
            isThisSelected || uploadStatus ? cls.optionsActive : ""
          ])}
        >
          <div
            className={klass([
              cls.optionsHeader,
              uploadStatus ? cls.noDisplay : cls.display
            ])}
          >
            <div
              className={cls.check}
              style={{ visibility: canSelectDesign ? "visible" : "hidden" }}
            >
              {isThisSelected ? (
                <Tippy content="Deselect">
                  <img
                    onClick={e => {
                      selectDesign(image, e.shiftKey);
                    }}
                    src={checkSvg}
                  />
                </Tippy>
              ) : (
                <Tippy content="Select">
                  <img
                    draggable={false}
                    onClick={e => {
                      selectDesign(image, e.shiftKey);
                    }}
                    src={circleSvg}
                  />
                </Tippy>
              )}
            </div>
            <div
              className="el-vc"
              style={{ display: isThisSelected ? "none" : "flex" }}
            >
              <Tippy content="Delete">
                <div className={cls.option}>
                  <ReactSVG
                    onClick={e => {
                      e.stopPropagation();
                      track(trackActions.designs.deleteIcon);
                      resetSelect();
                      setStartDelete(true);
                    }}
                    src={trashSvg}
                  />
                </div>
              </Tippy>
              <Tippy content="Collaborators">
                <div className={cls.option}>
                  <ReactSVG
                    onClick={e => {
                      e.stopPropagation();
                      track(trackActions.designs.collabIcon);
                      if (["Teams"].includes(user?.subscription?.name)) {
                        setIsInvitingUsers(true);
                      } else {
                        ellio.toaster.warning(
                          "Sorry, the sharing feature is not included in your current plan, please upgrade to a different plan",
                          {
                            title: "Oops..."
                          }
                        );
                      }
                    }}
                    src={personAddSvg}
                  />
                </div>
              </Tippy>
            </div>
          </div>
          <div className={cls.optionsMain}>
            <Link
              draggable={false}
              to={`${match.url}/${image._id}`}
              rel="noopener noreferrer"
              className={klass([
                buttonCls.outline,
                uploadStatus || (isThisSelected && !uploadStatus)
                  ? cls.noDisplay
                  : cls.display
              ])}
            >
              View Design
            </Link>
            {uploadStatus && (
              <progress
                className={cls.progress}
                max="100"
                value={String(uploadStatus.progress) || 0}
              >
                {uploadStatus.progress || 0}%
              </progress>
            )}
          </div>
        </div>
        <img draggable={false} src={image.backgroundDataUrl || image.dataUrl} />
      </div>
      <div className={cls.footer}>
        <Editable
          style={{ display: uploadStatus ? "none" : "flex" }}
          value={image.name}
          onConfirm={handleEditName}
        />

        <p className={cls.updatedAt}>
          <ReactTimeAgo
            date={image.updatedAt ? new Date(image.updatedAt) : new Date()}
            locale="en-US"
          />
        </p>
      </div>

      {isInvitingUsers ? (
        <InviteUsers
          image={image}
          user={user}
          onClose={() => {
            setIsInvitingUsers(false);
          }}
        />
      ) : null}

      {startDelete ? (
        <DeleteImageModal
          image={image}
          isProcessing={isDeleting}
          onClose={() => {
            setStartDelete(false);
          }}
          onDelete={() => {
            setIsDeleting(true);
            return onDelete(image)
              .then(() => {
                // setStartDelete(false);
              })
              .catch(err => {
                setIsDeleting(false);
                ellio.toaster.failure(err.message);

                // setStartDeletee(false);
              });
          }}
        />
      ) : null}
    </div>
  );
};

export default DesignPreview;
