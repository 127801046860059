import { useState } from "react";
import cls from "./hints.module.css";
import client from "common/client";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import Tippy from "@tippyjs/react";
import { ReactSVG } from "react-svg";
import crossSvg from "./cross.svg";
import doubleClickSvg from "./assets/double-click.svg";
import rightClickSvg from "./assets/right-click.svg";

export default function Hints(props) {
  const { section, setIshintDismissed, user, children, title = "Tips" } = props;

  const userId = user._id;

  const dismiss = async section => {
    try {
      const r = await client.service("users").patch(userId, {
        settings: {
          ...user.settings,
          dismissedHints: {
            ...user.settings.dismissedHints,
            [section]: true
          }
        }
      });
      setIshintDismissed(true);
    } catch (err) {
      console.log(err);
    }

    setIshintDismissed(true);
    // window.localStorage.setItem("ellio_is_hints_dismissed", true);
  };

  return (
    <div className={cls.studioHints}>
      <div className={cls.hintsAction}>
        <h2 className={typeCls.ctrl4}>{title}</h2>
        <Tippy content="Dismiss">
          <button className={buttonCls.button} onClick={() => dismiss(section)}>
            <ReactSVG src={crossSvg} />
          </button>
        </Tippy>
      </div>
      {children}
    </div>
  );
}

export function useHints(user, section) {
  const [isHintsDismissed, setIshintDismissed] = useState(
    user.settings?.dismissedHints?.[section]
  );
  return [isHintsDismissed, setIshintDismissed];
}
