const { NODE_ENV } = process.env;
const isProd = NODE_ENV === "production";

import pkg from "../../package.json";
import "./css/css";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "react-circular-progressbar/dist/styles.css";
import "./toaster-theme.css";
import "@ajmey/toaster/themes/theme-default.min.css";

import React from "react";
import ReactDOM from "react-dom";
import Main from "./main";
import reportWebVitals from "./reportWebVitals";
import monitor from "./ui-monitor";
import track from "./ui-track";

import { useToaster } from "@ajmey/toaster/toaster";
const toaster = useToaster({ animation: "slide-left", theme: "light" });

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);

import spinner from "common/spinner";
window.ellio.spinner = spinner;
window.ellio.toaster = toaster;

window.ellio.version = pkg.version;
console.log(ellio.version);

/* */
monitor();
track();

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
