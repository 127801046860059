import cls from "./hints.module.css";
import typeCls from "common/type.module.css";
import doubleClickSvg from "./assets/double-click.svg";
import rightClickSvg from "./assets/right-click.svg";

export default function StudioHints() {
  return (
    <>
      <div className={cls.hint}>
        <img src={doubleClickSvg} />
        <div className={cls.hintInner}>
          <h2 className={typeCls.ctrl}>Double click</h2>
          <p className={typeCls.p3}>
            Double click anywhere to create a task. Double click on a task
            circle to open it.
          </p>
        </div>
      </div>

      <div className={cls.hint}>
        <img src={rightClickSvg} />
        <div className={cls.hintInner}>
          <h2 className={typeCls.ctrl}>Right click</h2>
          <p className={typeCls.p3}>
            Right click on the design area for additional functions
          </p>
        </div>
      </div>
    </>
  );
}
