import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import id from "pkgs/random/id";
import client from "common/client";

const newBreadItem = name => {
  const display = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  return {
    id: id(),
    name,
    display
  };
};

const useBreadcrumb = () => {
  const [breadItems, setBreadItems] = useState([]);
  const imagesService = useRef(client.service("images"));
  const location = useLocation();

  useEffect(() => {
    const dashboardBreadItem = newBreadItem("dashboard");

    const currentBreads = location.pathname
      .split("/")
      .filter(Boolean)
      .map(newBreadItem);

    // const items = [dashboardBreadItem, ...currentBreads];
    const items = [...currentBreads];

    const secondLast = items[items.length - 2];

    if (secondLast?.name === "designs") {
      const imgId = items[items.length - 1].name;
      imagesService.current
        .get(imgId)
        .then(r => {
          const last = items.length - 1;
          const copy = [...items];
          copy[last] = { ...items[last], display: r.name };
          setBreadItems(copy);
        })
        .catch(err => {
          setBreadItems(items);
        });
    } else {
      setBreadItems(items);
    }
  }, [location]);

  return [breadItems, setBreadItems];
};

export default useBreadcrumb;
