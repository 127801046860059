import cls from "./task.module.css";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import client, { sendStudioEvent } from "common/client";
import debounceFn from "debounce-fn";
import Task from "./task";
import * as canvasUtil from "./canvas-util";
import { studioActionEvents } from "common/eve";
import callOnce from "common/call-once";

const isTask = node => {
  return node?.metaProp?.type === "ellio_task_node";
};

const newWrapper = ({ canvas, selectedTaskRef, className, wrapperRef }) => {
  const selected = selectedTaskRef.current;
  const { x, y } = selected.aCoords.bl;
  const parent = canvas.node.parentElement;
  const node = document.createElement("div");
  node.classList.add(className);
  node.classList.add("js-task-wrapper");
  const id = selectedTaskRef.current.metaProp.id;
  node.id = id;
  parent.appendChild(node);
  const width = node.offsetWidth;

  node.style.left = `${x - width}px`;
  node.style.top = `${y}px`;

  wrapperRef.current = node;
  return { id, node, width };
};

const renderTask = ({ selectedTaskRef, wrapperRef, userId }) => {
  const selected = selectedTaskRef.current;
  // const isOwner = selected?.metaProp?.userId === userId;
  const { content, id } = selected.metaProp;
  const onChange = v => {
    selected.metaProp.content = v.task;
  };
  ReactDOM.render(
    <Task isDisabled={false} id={id} task={content} onChange={onChange} />,
    wrapperRef.current.node
  );
};

const removeAllTasks = () => {
  const comments = Array.from(document.querySelectorAll(".js-task-wrapper"));
  comments.forEach(c => {
    ReactDOM.unmountComponentAtNode(c);
    c.remove();
  });
};

const invokeOnce = callOnce();

const useTask = opts => {
  const { canvas, emitAction, annotationId, userId } = opts;
  const wrapperRef = useRef();
  const selectedTaskRef = useRef();

  useEffect(() => {
    if (canvas) {
      const onSelect = v => {
        removeAllTasks();
        selectedTaskRef.current = v.target;
        wrapperRef.current = newWrapper({
          canvas,
          selectedTaskRef,
          className: cls.wrapper,
          wrapperRef
        });
        renderTask({
          selectedTaskRef,
          wrapperRef,
          userId,
          annotationId,
          canvas
        });
      };

      const onSelectClear = v => {
        removeAllTasks();
        selectedTaskRef.current = null;
      };

      canvas.on({
        "selection:updated": v => {
          const selected = v.selected;
          if (selected.length) {
            onSelectClear();
            emitAction({
              name: studioActionEvents.taskDeselected,
              objects: v.deselected
            });
          }
          if (isTask(v.target)) {
            onSelect(v);
          }
        },
        "selection:created": v => {
          if (isTask(v.target)) {
            onSelect(v);
          }

          v.selected.forEach(s => {
            if (isTask(s)) {
              const isMine = userId === s.metaProp?.userId;
              s.lockMovementX = isMine ? false : true;
              s.lockMovementY = isMine ? false : true;
            }
          });

          const anyNotMine = v.selected.some(
            s => isTask(s) && s.metaProp.userId !== userId
          );

          if (anyNotMine) {
            canvas.getActiveObject().set({
              lockMovementX: true,
              lockMovementY: true
            });
          }
        },
        "selection:cleared": v => {
          if (isTask(selectedTaskRef.current)) {
            onSelectClear(v);
            emitAction({
              name: studioActionEvents.taskDeselected,
              objects: v.deselected
            });
          }
        }
      });

      let resetMoveTask;

      canvas.on("object:moving", v => {
        const nodes = canvasUtil.getNodesByType(v.target, "task");

        if (nodes) {
          onSelectClear(v);
          resetMoveTask = invokeOnce(() => {
            sendStudioEvent(
              studioActionEvents.taskMoveStart,
              nodes.map(n => n.toObject(canvasUtil.extraPropsToSave)),
              {
                annotationId,
                userId
              }
            );
          });
        }
      });

      canvas.on("object:moved", v => {
        const nodes = canvasUtil.getNodesByType(v.target, "task");
        if (nodes) {
          canvas.discardActiveObject();
          resetMoveTask();
        }
      });

      canvas.on("selection:created", event => {
        const { target } = event;
        if (!target || !event?.selected?.length) {
          return;
        }
        // const nodes = target.size ? target.getObjects() : [];
        const nodes = canvasUtil.getNodesByType(target, "task");
        if (nodes) {
          const sel = canvas.getActiveObject();
          if (sel) {
            sel.setControlsVisibility({ mtr: false });
            sel.set({
              ...canvasUtil.TASK_PROPS
            });
          }
        }
      });
    }
  }, [canvas]);
};

export default useTask;
