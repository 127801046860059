export default function callOnce() {
  let count = 0;
  const reset = () => {
    count = 0;
  };
  return function (cb) {
    count += 1;
    if (count < 2) {
      cb();
    }
    return reset;
  };
}
