import { useState, useEffect } from "react";
import Select from "react-select";
import klass from "common/klass";
import cls from "./move-to-folder.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import overlayCls from "common/overlay.module.css";

const MoveToFolderModal = props => {
  const { onClose, onMove, isProcessing, setIsProcessing, folders } = props;
  const [overlay, setOverlay] = useState();
  const [folder, setFolder] = useState();
  const [folderName, setFolderName] = useState("");

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  /* will pass up either a string (new folder name)
   * or an object (an existing folder).
   */
  const handleMove = v => {
    setIsProcessing(true);
    onMove(v)
      .then(r => {
        setIsProcessing(false);
        onClose();
      })
      .catch(err => {
        setIsProcessing(false);
      });
  };

  const foldersOptions = folders
    // .filter(f => f.name !== "Root Folder")
    .map(f => ({
      label: f.name,
      value: f._id
    }));

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={klass([cls.moveToFolderModal])}>
        <h1 className={typeCls.ctrl5}>Move designs to folder</h1>
        <p className={typeCls.pMb}>Select an existing folder</p>
        <Select
          isDisabled={folderName}
          className="mb2"
          options={foldersOptions}
          value={folder || null}
          placeholder="Select folder"
          onChange={setFolder}
        />
        <p className={typeCls.pMb}>or create a new one</p>
        <input
          disabled={folder}
          className={klass([inputCls.textInput, "mb2"])}
          placeholder="new folder"
          value={folderName}
          type="text"
          onChange={e => setFolderName(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleMove(folderName || folder);
            }
          }}
        />
        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          <button
            className={buttonCls.primarySm}
            onClick={() => handleMove(folderName || folder)}
          >
            Move {isProcessing ? <div className="el-spinner"></div> : null}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MoveToFolderModal;
