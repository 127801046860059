import { useState, useEffect } from "react";
import cls from "./delete-folders-modal.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import overlayCls from "common/overlay.module.css";
import client from "common/client";

const DeleteDesignsModal = props => {
  const { onClose, onDelete, isProcessing, folders } = props;
  const [overlay, setOverlay] = useState();

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.deleteFoldersModal}>
        <h1 className={typeCls.ctrl5}>Are you sure?</h1>
        <p className={typeCls.p}>
          You are about to delete <strong>{folders.length}</strong> folders(s).
          Deleted folders cannot be recovered.
        </p>
        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          <button className={buttonCls.dangerSm} onClick={onDelete}>
            Delete {isProcessing ? <div className="el-spinner"></div> : null}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDesignsModal;
