import { useState, useEffect } from "react";
import cls from "./team.module.css";
import client from "common/client";
import DOMPurify from "dompurify";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";

export default function AddMembers(props) {
  const { user, members } = props;

  const orgNameId = user?.org?.orgNameId;

  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);

  const addMember = () => {
    if (isSending) {
      return;
    }
    setIsSending(true);
    const cleanEmail = DOMPurify.sanitize(email);
    client
      .service("org-member")
      .create({
        orgNameId,
        email: cleanEmail
      })
      .then(r => {
        ellio.toaster.success("Added member successfully");
        setEmail("");
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  if (!user.org) {
    return <div>Loading ...</div>;
  }

  const isSeatLimit = user?.subscription?.quantity === members?.length;

  return (
    <div className={cls.addInput}>
      <input
        disabled={isSending || isSeatLimit}
        placeholder="Email"
        type="text"
        className={inputCls.textInput}
        value={email}
        onChange={e => setEmail(e.target.value)}
        onKeyDown={e => {
          if (e.key === "Enter") {
            addMember();
          }
        }}
      />
      <button
        disabled={isSending || isSeatLimit}
        className={buttonCls.primarySm}
        onClick={addMember}
      >
        Add member {isSending ? <div className="el-spinner"></div> : null}
      </button>
    </div>
  );
}
