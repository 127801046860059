import { useEffect } from "react";
import * as canvasUtil from "./canvas-util";
import { studioActionEvents } from "common/eve";

const useSetBackground = ({
  canvas,
  backgroundImage,
  studioActionsRef,
  canvasWidth,
  emitAction
}) => {
  let p;
  useEffect(() => {
    if (canvas && backgroundImage) {
      canvasUtil.clearAll({
        canvas,
        isDeleteBackground: true
      });
      p = canvasUtil
        .newCanvasImage({ canvas, backgroundImage, canvasWidth })
        .then(r => {
          // canvas.add(r.oImg).renderAll();
          emitAction({
            name: studioActionEvents.setBackground,
            object: r.oImg,
            canvas,
            backgroundImage
          });
        });
    }
  }, [backgroundImage, canvas, canvasWidth]);

  return p;
};

export default useSetBackground;
