import cls from "./studio.module.css";
import { useState, useEffect } from "react";
import { RgbaColorPicker } from "react-colorful";
import { Button } from "@blueprintjs/core";
const defaultColor = { r: 255, g: 0, b: 0, a: 0.04 };

const ColorPicker = props => {
  const { x, y, onClose, onChange, initialColor } = props;
  const [color, setColor] = useState(initialColor || defaultColor);

  return (
    <div style={{ left: x, top: y }} className={cls.colorPicker}>
      <RgbaColorPicker
        color={color}
        onChange={v => {
          setColor(v);
          onChange(v);
        }}
      />
      <Button onClick={onClose}>Close</Button>
    </div>
  );
};

export default ColorPicker;
