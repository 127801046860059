import { useState, useEffect } from "react";
import cls from "./invite-users.module.css";
import { track, trackActions } from "ui-track";
import klass from "common/klass";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import overlayCls from "common/overlay.module.css";
import client from "common/client";
import CreatableSelect from "react-select/creatable";

const styles = {
  multiValue: (p, state) => ({
    ...p,
    border: "1px solid hsl(0, 0%, 90%)",
    backgroundColor: "hsl(0, 0%, 95%)"
  })
};

const toOption = v => ({
  value: v,
  label: v
});

const InviteUsers = props => {
  const { image, setIsInvitingUsers, onClose, user } = props;
  const imageId = image._id;
  const [overlay, setOverlay] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [members, setMembers] = useState();
  const [membersOptions, setMembersOptions] = useState();
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  useEffect(() => {
    client
      .service("org-member")
      .find({
        query: {
          orgNameId: user.orgNameId
        }
      })
      .then(r => {
        setMembers(r.data);
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
      });
  }, []);

  useEffect(() => {
    if (members) {
      setMembersOptions(
        members.map(m => ({
          value: m.email,
          label: m.email
        }))
      );
    }
  }, [members]);

  const invite = () => {
    setIsProcessing(true);
    track(trackActions.designs.collabManage);
    const collaborators = emails.map(v => v.value);

    return client
      .service("collaborators")
      .create(
        {
          collaborators,
          imageId
        },
        {
          query: {
            resource: "images"
          }
        }
      )
      .then(r => {
        ellio.toaster.success("Collaborators were updated successfully!", {
          dismiss: 3000
        });
        onClose();
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
        setIsProcessing(false);
      });
  };

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.inviteModal}>
        <h1 className={typeCls.ctrl5}>Manage collaborators</h1>
        <p className={klass([typeCls.p, "mb"])}>
          You can invite collaborators to your design using their email.
        </p>
        {members ? (
          <>
            <div className={cls.select}>
              <CreatableSelect
                defaultValue={image.collaborators.map(toOption)}
                isMulti
                closeMenuOnSelect={false}
                styles={styles}
                onChange={setEmails}
                name="members"
                placeholder="Type team member's email"
                options={membersOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className={cls.footerButtons}>
              <button onClick={onClose} className={buttonCls.primaryTextSmall}>
                Close
              </button>
              <button className={buttonCls.primarySm} onClick={invite}>
                Update{" "}
                {isProcessing ? <div className="el-spinner"></div> : null}
              </button>
            </div>
          </>
        ) : (
          <div className={cls.loading}>
            <div className="el-spinner"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InviteUsers;
