import cls from "./comment.module.css";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import client, { sendStudioEvent } from "common/client";
import debounceFn from "debounce-fn";
import Comment from "./comment";
import * as canvasUtil from "./canvas-util";
import { studioActionEvents } from "common/eve";
import updateModel, { handleUpdateModel } from "./update-model";
import callOnce from "common/call-once";

const isComment = node => {
  return node?.metaProp?.type === "ellio_comment_node";
};

function positionCommentBubble({ canvas, object, div, divWidth }) {
  const pos = canvas.getAbsoluteCoords(object);
  const { x, y } = pos;
  div.current.style.left = `${x - divWidth.current}px`;
  div.current.style.top = `${y + object.height}px`;
}

const newWrapper = ({ canvas, selectedCommentRef, className, wrapperRef }) => {
  const selected = selectedCommentRef.current;
  const { x, y } = selected.aCoords.bl;
  const parent = canvas.node.parentElement;
  const node = document.createElement("div");
  node.classList.add(className);
  node.classList.add("js-comment-wrapper");
  const id = selectedCommentRef.current.metaProp.id;
  node.id = id;
  parent.appendChild(node);
  const width = node.offsetWidth;

  node.style.left = `${x - width - 5}px`;
  node.style.top = `${y + 5}px`;

  wrapperRef.current = node;
  return { id, node, width };
};

const renderComment = ({
  selectedCommentRef,
  wrapperRef,
  canvas,
  annotationId,
  userId
}) => {
  const selected = selectedCommentRef.current;
  const { content, id } = selected.metaProp;

  const onChange = v => {
    selected.metaProp.content = v.comment;
  };

  // const isOwner = selected?.metaProp?.userId === userId;

  ReactDOM.render(
    <Comment
      isDisabled={false}
      id={id}
      comment={content}
      onChange={onChange}
    />,
    wrapperRef.current.node
  );
};

const removeComment = wrapperRef => {
  if (wrapperRef.current) {
    ReactDOM.unmountComponentAtNode(wrapperRef.current.node);
    wrapperRef.current.node.remove();
  }
};

const removeAllComments = () => {
  const comments = Array.from(document.querySelectorAll(".js-comment-wrapper"));
  comments.forEach(c => {
    ReactDOM.unmountComponentAtNode(c);
    c.remove();
  });
};

const invokeOnce = callOnce();

const useComment = opts => {
  const { canvas, emitAction, annotationId, userId } = opts;
  const wrapperRef = useRef();
  const selectedCommentRef = useRef();
  // const isCommentMoving = useRef(false);

  useEffect(() => {
    if (canvas) {
      const onSelect = v => {
        removeAllComments();
        selectedCommentRef.current = v.target;
        wrapperRef.current = newWrapper({
          canvas,
          selectedCommentRef,
          className: cls.wrapper,
          wrapperRef
        });
        renderComment({
          selectedCommentRef,
          wrapperRef,
          annotationId,
          canvas,
          userId
        });
      };

      const onSelectClear = v => {
        removeAllComments();
        selectedCommentRef.current = null;
      };

      canvas.on({
        "selection:updated": v => {
          const selected = v.selected;
          if (selected.length) {
            onSelectClear();
            emitAction({
              name: studioActionEvents.commentDeselected,
              objects: v.deselected
            });
          }
          if (isComment(v.target)) {
            onSelect(v);
          }
        },
        "selection:created": v => {
          if (isComment(v.target)) {
            onSelect(v);
          }

          v.selected.forEach(s => {
            if (isComment(s)) {
              const isMine = userId === s.metaProp?.userId;
              s.lockMovementX = isMine ? false : true;
              s.lockMovementY = isMine ? false : true;
            }
          });

          const anyNotMine = v.selected.some(
            s => isComment(s) && s.metaProp.userId !== userId
          );

          if (anyNotMine) {
            canvas.getActiveObject().set({
              lockMovementX: true,
              lockMovementY: true
            });
          }
        },
        "selection:cleared": v => {
          if (isComment(selectedCommentRef.current)) {
            onSelectClear(v);
            emitAction({
              name: studioActionEvents.commentDeselected,
              objects: v.deselected
            });
          }
        }
      });

      let resetMoveComment;

      canvas.on("object:moving", v => {
        // isCommentMoving.current = true;
        const nodes = canvasUtil.getNodesByType(v.target, "comment");

        if (nodes) {
          onSelectClear(v);
          resetMoveComment = invokeOnce(() => {
            sendStudioEvent(
              studioActionEvents.commentMoveStart,
              nodes.map(n => n.toObject(canvasUtil.extraPropsToSave)),
              {
                annotationId,
                userId
              }
            );
          });
        }
      });

      canvas.on("object:moved", v => {
        const nodes = canvasUtil.getNodesByType(v.target, "comment");
        if (nodes) {
          canvas.discardActiveObject();
          resetMoveComment();
        }
      });

      /* hacky to get end of object move */
      // canvas.on("mouse:up", () => {
      //   if (isCommentMoving.current) {
      //     isCommentMoving.current = false;
      //   }
      // });

      /* multi selected comments cannot be moved or rotated. */
      canvas.on("selection:created", event => {
        const { target } = event;
        if (!target || !event?.selected?.length) {
          return;
        }
        // const nodes = target.size ? target.getObjects() : [];
        const nodes = canvasUtil.getNodesByType(target, "comment");
        if (nodes) {
          const sel = canvas.getActiveObject();
          if (sel) {
            sel.setControlsVisibility({ mtr: false });
            sel.set({
              ...canvasUtil.COMMENT_PROPS
            });
          }
        }
      });
    }
  }, [canvas]);
};

export default useComment;
