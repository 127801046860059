import { useEffect } from "react";
import { fabric } from "fabric";
import * as canvasUtil from "./canvas-util";
import { studioActionEvents } from "common/eve";
import id from "pkgs/random/id";

const useAddAssetsToCanvas = opts => {
  const { canvas, assets, annotationId, emitAction, userId } = opts;
  useEffect(() => {
    if (canvas && assets && assets.imgs) {
      const { x, y } = assets;
      assets.imgs.forEach(image => {
        fabric.Image.fromURL(image.dataUrl, function (img) {
          const oImg = img
            .set({ left: x, top: y, angle: 0, hoverCursor: "move" })
            .scale(0.9);
          oImg.set("selectable", true);
          const metaProp = {
            type: "ellio_asset_node",
            id: `ellio_asset_node_${id()}`
          };
          canvasUtil.addMetaProp({ metaProp, object: oImg });
          oImg.metaProp.userId = userId;
          canvas.add(oImg);
          canvas.renderAll();
          emitAction({
            name: studioActionEvents.addAsset,
            canvas,
            annotationId,
            object: oImg,
            x,
            y
          });
        });
      });
    }
  }, [canvas, assets]);
};

export default useAddAssetsToCanvas;
