import { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";

import typeCls from "common/type.module.css";
import cls from "./design-details.module.css";
import client from "common/client";
import Studio from "studio/studio";
import DisplayError from "common/display-error";
const noop = v => v;

function DesignDetails(props) {
  const {
    user,
    setUser,
    onActiveDesign,
    activeUsers,
    refreshActiveUsers,
    onOpenFileSelector,
    setIsAllowDrag,
    activeFolderImages,
    activeFolder,
    images,
    plansInfo
  } = props;
  const routeMatch = useRouteMatch();
  const { id } = routeMatch.params;
  const [design, setDesign] = useState();
  const [err, setErr] = useState();

  const refreshDesign = () => {
    return client
      .service("images")
      .get(id)
      .then(img => {
        setDesign(img);
        return img;
      })
      .catch(err => {
        setErr(err);
      });
  };

  useEffect(refreshDesign, []);

  useEffect(() => {
    refreshDesign().then(d => {
      onActiveDesign(d);
    });
    return () => onActiveDesign(null);
  }, []);

  if (err) {
    return <DisplayError error={err} />;
  }

  if (!design) {
    return (
      <div className="page-loading">
        <div className="el-spinner"></div>
      </div>
    );
  }

  return (
    <div className={cls.wrapper}>
      <Studio
        user={user}
        setUser={setUser}
        onOpenFileSelector={onOpenFileSelector}
        design={design}
        setDesign={setDesign}
        refreshDesign={refreshDesign}
        activeUsers={activeUsers}
        refreshActiveUsers={refreshActiveUsers}
        setIsAllowDrag={setIsAllowDrag}
        activeFolder={activeFolder}
        activeFolderImages={activeFolderImages}
        images={images}
        plansInfo={plansInfo}
      />
    </div>
  );
}

export default DesignDetails;
