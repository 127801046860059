const message = error => {
  if (error.message.toLowerCase().includes("no record found")) {
    return `Sorry, to view this page, you need permissions from the owner.`;
  }
  return error.message;
};

const DisplayError = props => {
  const { error } = props;
  return <div className="page-loading">{message(error)}</div>;
};

export default DisplayError;
