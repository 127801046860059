import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import Tippy from "@tippyjs/react";
import { k } from "common/klass";
import cls from "./members-list.module.css";
import buttonCls from "common/button.module.css";
import typeCls from "common/type.module.css";
import client, { useServiceEvents } from "common/client";
import RemoveMemberModal from "./remove-member-modal";
import AddMembers from "./add-members";
import moreSvg from "./more-vertical.svg";
import trashSvg from "./trash3.svg";
import planeSvg from "./plane.svg";
import Dropdown from "common/dropdown";

export function useMembers(options) {
  const { user } = options;
  const [members, setMembers] = useState();
  const [err, setErr] = useState();

  const refresh = () => {
    if (user?.org?.orgNameId) {
      client
        .service("org-member")
        .find({
          query: {
            orgNameId: user?.org?.orgNameId
          }
        })
        .then(r => {
          setMembers(r.data);
        })
        .catch(err => {
          // ellio.toaster.failure(err.message);
          // console.log(err);
          setMembers([]);
          setErr(err);
        });
    } else {
      setMembers([]);
    }
  };

  useEffect(refresh, [user]);

  useServiceEvents(
    {
      current: client.service("org-member")
    },
    refresh,
    [user]
  );

  return [members, setMembers, err];
}

function Member(props) {
  const { member, user, index, setMemberToRemove } = props;
  const [isSpinner, setIsSpinner] = useState(false);

  const reInvite = m => {
    if (isSpinner) {
      return;
    }

    setIsSpinner(true);
    const memberEmail = m.email;
    return client
      .service("org-member")
      .create({
        orgNameId: user.org.orgNameId,
        email: memberEmail
      })
      .then(r => {
        ellio.toaster.success("Invite was sent successfully");
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
      })
      .finally(() => {
        setIsSpinner(false);
      });
  };

  const isMe = user.email === member.email;

  return (
    <div className={k(cls.row, isMe && cls.me)}>
      <div>
        <p>{index + 1}</p>
      </div>
      <div>
        <p>{member.name || "--"}</p>
      </div>
      <div>
        <p>
          {member.email}
          {isMe && " (You)"}
        </p>
      </div>
      <div>
        {!isMe && (
          <Dropdown
            trigger={
              <Tippy content="Options">
                <button className={buttonCls.button}>
                  {isSpinner ? (
                    <div className="el-spinner"></div>
                  ) : (
                    <img
                      style={{ height: "18px" }}
                      draggable={false}
                      src={moreSvg}
                    />
                  )}
                </button>
              </Tippy>
            }
          >
            <div className="my-menu">
              <div className="menu-row">
                <div className="menu-row-text">
                  <button
                    onClick={() => {
                      reInvite(member);
                    }}
                    className={buttonCls.buttonSm}
                  >
                    <ReactSVG className="menu-row-icon" src={planeSvg} />
                    Re-invite
                  </button>
                </div>
              </div>

              <div className="menu-row danger">
                <div className="menu-row-text">
                  <button
                    onClick={() => {
                      setMemberToRemove(member);
                    }}
                    className={buttonCls.buttonSm}
                  >
                    <ReactSVG className="menu-row-icon" src={trashSvg} />
                    Remove member
                  </button>
                </div>
              </div>
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
}

export default function MembersList(props) {
  const { user, members } = props;
  const [memberToRemove, setMemberToRemove] = useState();
  const [isSpinner, setIsSpinner] = useState(false);

  const remove = () => {
    const mId = memberToRemove._id;
    setIsSpinner(true);
    client
      .service("org-member")
      .remove(mId)
      .then(r => {
        ellio.toaster.success("Removed member from organization successfully");
        setMemberToRemove(null);
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
        setIsSpinner(false);
      })
      .finally(() => {});
  };

  if (!user.org) {
    return null;
  }

  if (!members) {
    return <div>Loading...</div>;
  }

  return (
    <div className={cls.members}>
      <div className={cls.membersHeader}>
        <div>
          <h2 className={typeCls.h2}>
            Members ({members.length}/{user?.subscription?.quantity})
          </h2>
        </div>
        <div>
          <AddMembers user={user} members={members} />
        </div>
      </div>

      <div className={cls.table}>
        <div className={cls.tableHeader}>
          <div>
            <p>#</p>
          </div>
          <div>
            <p>Name</p>
          </div>
          <div>
            <p>Email</p>
          </div>
          <div></div>
        </div>
        <div className={cls.tableBody}>
          {members.map((m, i) => (
            <Member
              key={m._id}
              index={i}
              member={m}
              setMemberToRemove={setMemberToRemove}
              user={user}
            />
          ))}
        </div>
      </div>
      {memberToRemove && (
        <RemoveMemberModal
          onClose={() => setMemberToRemove(null)}
          onRemove={remove}
          isProcessing={isSpinner}
          member={memberToRemove}
        />
      )}
    </div>
  );
}
