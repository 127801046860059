import { useState } from "react";
import { useHistory } from "react-router-dom";
import cls from "./breadcrumb.module.css";
import klass from "common/klass";
import lineSvg from "./line.svg";

const goToItem = (items, item, itemIndex, history) => {
  if (itemIndex === items.length - 1) {
    return;
  }
  let p = `/${items
    .slice(0, itemIndex + 1)
    .map(v => v.name)
    .join("/")}`;

  history.push(p);
};

const Breadcrumb = props => {
  const { items, location } = props;
  const activePath = items[items.length - 1];
  const activeIdx = items.findIndex(v => v.name === activePath.name);
  const history = useHistory();

  return (
    <div className={cls.wrapper}>
      <div className={cls.items}>
        {items.map((item, i) => (
          <div key={item.id}>
            <div
              className={klass([cls.item, i === activeIdx ? cls.active : ""])}
            >
              <span
                className={cls.goTo}
                onClick={() => goToItem(items, item, i, history)}
              >
                {item.display}
              </span>
              {i !== items.length - 1 && (
                <img className={cls.line} src={lineSvg} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
