import { useRef, useEffect, useState } from "react";
import cls from "./editable.module.css";
import inputCls from "common/input.module.css";
import klass from "common/klass";
const noop = v => v;

export default function Editable(props) {
  const { onConfirm, value, style = {}, className = "" } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef();

  useEffect(() => {
    const handler = e => {
      if (!inputRef.current || !isEditing) {
        return;
      }

      if (e.target !== inputRef.current) {
        setIsEditing(false);
        onConfirm(inputValue);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [isEditing, inputValue]);

  /* if the outside value changes, update internal value */
  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <input
      style={style}
      ref={inputRef}
      onClick={() => setIsEditing(true)}
      onFocus={e => e.target.select()}
      onKeyDown={e => {
        if (e.key === "Enter") {
          setIsEditing(false);
          inputRef.current.blur();
          onConfirm(inputValue);
        }
      }}
      className={klass([cls.value, inputCls.editable, className])}
      type="text"
      value={inputValue}
      onChange={e => setInputValue(e.target.value)}
    />
  );
}
