import anime from "animejs/lib/anime.es.js";

const appear = (t, conf) => {
  const defaults = {
    duration: 550,
    yMax: "-16px",
    easing: "easeInOutSine",
    initialDelay: 0
  };
  const settings = Object.assign(defaults, conf);
  const yMax = settings.yMax;
  return anime({
    targets: t,
    easing: settings.easing,
    duration: settings.duration,
    translateY: [yMax, 0],
    opacity: [0, 1],
    autoplay: false,
    delay: settings.initialDelay
  });
};

export default appear;
