import Url from "url-parse";
import { useEffect, useRef } from "react";
import client from "common/client";
import { getCloudInfo } from "common/jira-common";

/* after giving access, user is re directed here to get token
 * and pass up to the parent window.
 */
const Jira = () => {
  const tokenRef = useRef("");
  const cloudInfoRef = useRef("");
  const refreshTokenRef = useRef("");

  useEffect(() => {
    const u = new Url(window.location.href, true);
    if (u.query.code) {
      const code = u.query.code;
      /* 3lo flow */
      client
        .service("jira")
        .create({ code })
        .then(r => {
          /*
          r = {
            access_token: '',
            expires_in: 3600,
            scope: 'read:jira-user',
            token_type: 'Bearer'
          }
        */

          if (r?.access_token) {
            tokenRef.current = r.access_token;
            if (r?.refresh_token) {
              refreshTokenRef.current = r.refresh_token;
            }

            return getCloudInfo(r.access_token);
          }
        })
        .then(r => {
          cloudInfoRef.current = r;
          window.opener.postMessage(
            {
              name: "jira",
              token: tokenRef.current,
              refreshToken: refreshTokenRef.current,
              cloudId: cloudInfoRef.current.id,
              cloudInfo: cloudInfoRef.current
            },
            window.opener.origin
          );
          window.close();
        })
        .catch(err => {
          ellio.toaster.failure(err.message);
        });
    } else {
      // window.location.href = "/designs";
    }
  }, []);

  return (
    <div style={{ padding: "12px" }}>
      <div>
        <div className="mb">Connecting to Jira ...</div>
        <div className="el-spinner"></div>
      </div>
    </div>
  );
};

export default Jira;
