import { useState, useEffect } from "react";
import cls from "./folder.module.css";
import Avatar from "common/avatar";
import Collaborators from "common/collaborators";
import client from "common/client";
import klass from "common/klass";
import buttonCls from "common/button.module.css";
import { ReactSVG } from "react-svg";
import typeCls from "common/type.module.css";
import Tippy from "@tippyjs/react";
import Editable from "common/editable";
import ReactTimeAgo from "react-time-ago";
import trashSvg from "./trash2.svg";
import trashSvg3 from "./trash3.svg";
import personAddSvg from "./person-add.svg";
import circleSvg from "./circle.svg";
import checkSvg from "./check.svg";
import bigPlusSvg from "./big-plus.svg";
import jiraSvg from "./jira.svg";
import moreVerticalSvg from "./more-vertical.svg";
import Dropdown from "common/dropdown";
import { connectJira } from "common/jira-common";

const Folder = props => {
  const {
    style = {},
    selectedFolders,
    folder,
    onDelete,
    onOpen,
    isNewFolder,
    onNewFolder,
    onAddCollaborator,
    onSelectJiraFolder,
    addFolder,
    removeFolder,
    canSelectFolder,
    userId
  } = props;
  const [isSelected, setIsSelected] = useState(false);
  const [isHover, setIsHover] = useState(false);

  /* if not in the list, deselect */
  useEffect(() => {
    if (selectedFolders) {
      if (!selectedFolders.find(v => v._id === folder._id)) {
        setIsSelected(false);
      } else {
        setIsSelected(true);
      }
    }
  }, [selectedFolders]);

  if (isNewFolder) {
    return (
      <div style={style} className={cls.newFolder}>
        <Tippy content="New folder">
          <button onClick={onNewFolder}>
            <ReactSVG src={bigPlusSvg} />
          </button>
        </Tippy>
      </div>
    );
  }
  return (
    <div style={style} className={cls.folder}>
      <div className={klass([cls.header])}>
        <div style={{ visibility: canSelectFolder ? "visible" : "hidden" }}>
          {isSelected ? (
            <div className={cls.check}>
              <Tippy content="Deselect">
                <button
                  onClick={() => {
                    setIsSelected(false);
                    removeFolder(folder);
                  }}
                >
                  <ReactSVG src={checkSvg} />
                </button>
              </Tippy>
            </div>
          ) : (
            <div className={cls.select}>
              <Tippy content="Select">
                <button
                  onClick={e => {
                    setIsSelected(true);
                    addFolder(folder, e.shiftKey);
                  }}
                >
                  <ReactSVG src={circleSvg} />
                </button>
              </Tippy>
            </div>
          )}
        </div>

        <div
          className={klass([])}
          style={{ display: isSelected ? "none" : "flex" }}
        >
          <Dropdown
            placement="bottom-end"
            trigger={
              <button className={klass([buttonCls.button, cls.extras])}>
                <ReactSVG className={cls.more} src={moreVerticalSvg} />
              </button>
            }
          >
            <div className="my-menu">
              <div className="menu-row">
                <div className="menu-row-text">
                  <button
                    onClick={async () => {
                      const token = await connectJira({ userId });
                      onSelectJiraFolder(folder, token);
                    }}
                    className={buttonCls.buttonSm}
                  >
                    <ReactSVG className="menu-row-icon" src={jiraSvg} />
                    Jira tickets
                  </button>
                </div>
              </div>

              <div className="menu-row">
                <div className="menu-row-text">
                  <button
                    onClick={() => {
                      onAddCollaborator(folder);
                    }}
                    className={buttonCls.buttonSm}
                  >
                    <ReactSVG className="menu-row-icon" src={personAddSvg} />
                    Collaborators
                  </button>
                </div>
              </div>

              <div className="menu-row danger">
                <div className="menu-row-text">
                  <button
                    onClick={() => {
                      onDelete(folder);
                    }}
                    className={buttonCls.buttonSm}
                  >
                    <ReactSVG className="menu-row-icon" src={trashSvg3} />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>

      <div className={cls.body}>
        <Editable
          value={folder.name}
          onConfirm={newName => {
            client
              .service("folders")
              .patch(folder._id, {
                name: newName
              })
              .then(r =>
                ellio.toaster.success("Updated folder name successfully")
              )
              .catch(err => ellio.toaster.failure(err.message));
          }}
        />
        <ReactTimeAgo
          className={typeCls.pSm}
          date={folder.updatedAt ? new Date(folder.updatedAt) : new Date()}
          locale="en-US"
        />
      </div>
      {!isSelected ? (
        <Collaborators collaborators={folder.collabsWithProfileImage} />
      ) : null}

      <button
        style={{
          marginTop: "10px",
          display: isSelected ? "none" : "flex"
        }}
        className={buttonCls.linkMd}
        onClick={() => onOpen(folder)}
      >
        Open
      </button>
    </div>
  );
};

export default Folder;
