import cls from "./comment.module.css";
import { useState, useRef, useEffect, useCallback } from "react";
import useSelect from "common/use-select";

const noop = v => v;
const Comment = props => {
  const { comment, id, onChange = noop, isDisabled } = props;
  const [value, setValue] = useState(comment || "");
  const textarea = useRef();
  useSelect(textarea);

  return (
    <div className={cls.comment}>
      <textarea
        ref={textarea}
        disabled={isDisabled}
        type="text"
        value={value}
        className={cls.textArea}
        onChange={e => {
          const v = e.target.value;
          setValue(v);
          onChange({
            comment: v,
            id
          });
        }}
      />
    </div>
  );
};

export default Comment;
