import client, { useServiceEvents } from "common/client";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

const useActiveUsers = (activeDesign, user) => {
  const userId = user?._id;
  const profileImageUrl = user?.settings?.profileImageUrl;
  const [activeUsers, setActiveUsers] = useState();
  const activeUsersService = useRef(client.service("active-users"));
  const location = useLocation();

  const noop = v => v;

  /* handle adding/removing active users */
  useEffect(() => {
    if (activeDesign) {
      if (/\/designs\/.*/.test(location.pathname)) {
        activeUsersService.current
          .create({
            imageId: activeDesign._id,
            userId,
            profileImageUrl,
            annotationId: activeDesign.annotationId
          })
          .then(noop)
          .catch(err => {
            if (err.message === "imageId: value already exists.") {
              return; // ignore for now
            }
            console.log(err);
          });
      } else {
        activeUsersService.current
          .remove(null, {
            query: {
              userId,
              imageId: activeDesign._id
            }
          })
          .then(noop)
          .catch(err => {
            console.log(err);
          });
      }
    }
  }, [location, activeDesign]);

  const refreshActiveUsers = design => {
    return activeUsersService.current
      .find({
        query: {
          imageId: design._id
        }
      })
      .then(r => {
        if (r.total) {
          setActiveUsers(r.data);
          return r.data;
        }
      });
  };

  /* get active users on load */
  useEffect(() => {
    if (activeDesign) {
      refreshActiveUsers(activeDesign);
      // activeUsersService.current
      //   .find({
      //     query: {
      //       imageId: activeDesign._id
      //     }
      //   })
      //   .then(r => {
      //     if (r.total) {
      //       setActiveUsers(r.data);
      //     }
      //   });
    }
  }, [activeDesign]);

  /* whenever active users are added/removed, query for the active users
  for the active design */
  useServiceEvents(
    activeUsersService,
    (d, e) => {
      if (activeDesign) {
        activeUsersService.current
          .find({
            query: {
              imageId: activeDesign._id
            }
          })
          .then(r => {
            if (r.total) {
              setActiveUsers(r.data);
            }
          })
          .catch(console.log);
      }
    },
    [activeDesign],
    ["created", "removed"]
  );

  /* TODO: uncomment for production */
  /* will remove the user from active users before the window is closed
   * in theory it should work.
   */
  // const alertUser = () => {
  //   event.preventDefault();
  //   event.returnValue = "";
  // };

  // const handleTabClosing = () => {
  //   if (activeDesign) {
  //     client.service("active-users").remove(null, {
  //       query: {
  //         userId,
  //         imageId: activeDesign._id
  //       }
  //     });
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   window.addEventListener("unload", handleTabClosing);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //     window.removeEventListener("unload", handleTabClosing);
  //   };
  // });

  // return activeUsers;
  return {
    activeUsers,
    refreshActiveUsers
  };
};

export default useActiveUsers;
