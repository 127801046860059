import { createContext } from "react";

const UserContext = createContext({
  id: "",
  name: "",
  organization: "",
  permissions: "",
  orgId: "",
  settings: {}
});

export default UserContext;
