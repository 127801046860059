import * as canvasUtil from "./canvas-util";
import client from "common/client";

/* handles saving/updating the annotation model to the db */
const updateModel = opts => {
  const { canvas, annotationId, event = "" } = opts;
  const model = canvasUtil.toObject({ canvas });

  const commentsIds = model.objects
    .map(o =>
      o.metaProp?.type === "ellio_comment_node" ? o.metaProp.id : null
    )
    .filter(Boolean);

  const tasksIds = model.objects
    .map(o => (o.metaProp?.type === "ellio_task_node" ? o.metaProp.id : null))
    .filter(Boolean);

  /* remove mouse objects */
  const modelNoMouse = model.objects.filter(
    o => !o.metaProp?.type?.includes("mouse")
  );

  model.objects = modelNoMouse;

  return client
    .service("annotations")
    .patch(annotationId, {
      model,
      comments: commentsIds,
      tasks: tasksIds,
      event
    })
    .catch(err => ellio.toaster.failure(err.message));
};

/* update model with toaster */
const handleUpdateModel = opts => {
  return updateModel(opts)
    .then(r => {
      return r;
    })
    .catch(err => {
      if (err.message.includes("maximum limit")) {
        ellio.toaster.warning(err.message, { title: "Oops..." });
      } else {
        ellio.toaster.failure(err.message);
      }
    });
};

export default updateModel;

export { handleUpdateModel };
