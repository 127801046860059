import { useState, useEffect } from "react";

const useDragOver = () => {
  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    let lastEnter;

    const enterHandler = event => {
      lastEnter = event.target;

      setIsDragOver(true);
      event.stopPropagation();
      event.dataTransfer.dropEffect = "move";
    };

    const dropHandler = event => {
      event.stopPropagation();
      event.preventDefault();
      const fileList = event.dataTransfer.files;
      // console.log(fileList);
      setIsDragOver(false);
    };

    const leaveHandler = event => {
      event.stopPropagation();
      event.preventDefault();
      if (lastEnter === event.target) {
        setIsDragOver(false);
      }
    };

    window.addEventListener("dragenter", enterHandler);

    window.addEventListener("drop", dropHandler);

    window.addEventListener("dragleave", leaveHandler);

    return () => {
      window.removeEventListener("dragenter", enterHandler);
      window.removeEventListener("drop", dropHandler);
      window.removeEventListener("dragleave", leaveHandler);
    };
  }, []);

  return [isDragOver, setIsDragOver];
};

export default useDragOver;
