import { useEffect, useRef } from "react";
import * as canvasUtil from "./canvas-util";
const noop = v => v;
const useTrackMouse = ({ canvas }, onPosition = noop) => {
  const positionRef = useRef({ x: 0, y: 0, event: null, target: null });

  useEffect(() => {
    if (canvas) {
      canvasUtil.trackMousePosition({
        canvas,
        cb: r => {
          positionRef.current = r;
          onPosition(r);
        }
      });
    }
  }, [canvas]);

  return positionRef;
};

export default useTrackMouse;
