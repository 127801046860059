import { useEffect, useState, useRef, useCallback } from "react";
import { track, trackActions } from "ui-track";
import overlayCls from "common/overlay.module.css";
import { useLocation } from "react-router-dom";
import client, { isValidSubscription } from "common/client";
import FileSelect from "common/file-select";
import { fabric } from "fabric";
import readFiles from "common/read-files";
import s3Upload from "common/s3/s3-upload";
import useDragOver from "common/use-drag-over";
import * as canvasUtil from "studio/canvas-util";
import pLimit from "promise-limit";
const limit = pLimit(2);

const isFeatureLimited = ({ files, user, images }) => {
  if (isValidSubscription(user?.subscription)) {
    return false;
  }

  const basicPlan = user?.plans?.find(v => v.name === "Basic");
  const imagesLimit = basicPlan?.limits.images.value;

  if (files.length > 3) {
    ellio.toaster.warning(
      "Concurrent uploads is limited in the Basic plan, try selecting a fewer files, or upgrading to another plan.",
      { title: "Oops..." }
    );
    return true;
  }

  if (images.length >= imagesLimit) {
    ellio.toaster.warning(basicPlan?.limits.images.message, {
      title: "Oops..."
    });
    return true;
  }

  // if (images.length + files.length > imagesLimit) {
  // ellio.toaster.warning(
  //   "Concurrent uploads is limited in the Basic plan, try selecting a fewer files, or upgrading to another plan.",
  //   { title: "Oops..." }
  // );
  // return true;
  // }

  return false;
};

const DesignsSelector = props => {
  const {
    user,
    onIsUploadInProgress,
    isFileSelectorOpen,
    setIsFileSelectorOpen,
    imagesUploading,
    setImagesUploading,
    activeFolder,
    setActiveFolder,
    activeFolderImages,
    refreshImages,
    isAllowDrag,
    onFileSelectErrors,
    images
  } = props;

  const imagesService = useRef(client.service("images"));
  const imagesMapRef = useRef({});
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [isDragOver] = useDragOver();
  const location = useLocation();
  const [overlay, setOverlay] = useState();

  useEffect(() => {
    if (overlay) {
      if ((isDragOver && isAllowDrag) || isFileSelectorOpen) {
        overlay.style.display = "block";
        setTimeout(() => {
          // track(trackActions.dragOver);
          overlay.classList.add(overlayCls.fadeIn);
        }, 100);
      } else {
        overlay.classList.remove(overlayCls.fadeIn);
        setTimeout(() => {
          // track(trackActions.dragDrop);
          overlay.style.display = "none";
        }, 500);
      }
    }
  }, [overlay, isDragOver, isAllowDrag, isFileSelectorOpen]);

  const isStudioPage = /designs\//.test(location.pathname);

  /* update isAnyInProgress based on the upload status for images */
  useEffect(() => {
    const isAnyInProgress = Object.keys(imagesUploading).some(k => {
      return !imagesUploading[k].isFinished;
    });

    setIsUploadInProgress(isAnyInProgress);

    if (typeof onIsUploadInProgress === "function") {
      onIsUploadInProgress(isAnyInProgress);
    }
  }, [imagesUploading, onIsUploadInProgress]);

  const onSelectFiles = useCallback(
    async _files => {
      if (isFeatureLimited({ files: _files, user, images })) {
        return;
      }

      let files = _files;

      if (files.length) {
        window.ellio.toaster.success(
          "Your designs will be uploaded in the background.",
          {
            title: "Uploading files...",
            dismiss: 4e3
          }
        );
      }

      if (!isValidSubscription(user?.subscription)) {
        const basicPlan = user?.plans?.find(v => v.name === "Basic");
        const imagesLimit = basicPlan?.limits.images.value;
        if (images.length + _files.length >= imagesLimit) {
          const diff = images.length + _files.length - imagesLimit;
          const n = Math.abs(diff - _files.length);
          files = _files.slice(0, n);
        }
      }

      const imgs = await readFiles(files, {
        newWidth: 384,
        imageArguments: 0.95
      });

      imgs.forEach(image => (imagesMapRef.current[image.uuid] = image));
      setImagesUploading(imagesMapRef.current);

      let allImages;

      try {
        allImages = await imagesService.current.create(
          imgs.map(im => ({
            name: im.name,
            uuid: im.uuid,
            url: "",
            isTaskable: true,
            annotations: {
              version: "4.4.0",
              objects: [],
              hoverCursor: "crosshair"
            },
            backgroundDataUrl: im.dataUrl,
            parent: activeFolder ? activeFolder._id : ""
          }))
        );

        refreshImages();

        await Promise.all(
          imgs.map(async image => {
            return limit(async () => {
              const r = await s3Upload(image, v => {
                imagesMapRef.current[image.uuid].progress = v.progress;
                setImagesUploading(p => {
                  const c = { ...p };
                  c[image.uuid].progress = v.progress;
                  return c;
                });
              });

              const newImage = {
                ...imagesMapRef.current[image.uuid],
                url: r
              };

              allImages.forEach(v => {
                if (v.uuid === image.uuid) {
                  v.url = r;
                }
              });

              const canvasNode = document.createElement("canvas");
              const canvas = new fabric.Canvas(canvasNode, {
                ...canvasUtil.defaultCanvasConfig
              });

              const cr = await canvasUtil.newCanvasImage({
                canvas,
                backgroundImage: newImage
              });
              canvas.add(cr.oImg);

              const imageTarget = allImages.find(v => v.uuid === image.uuid);
              const updated = await imagesService.current.patch(
                imageTarget._id,
                {
                  annotations: canvasUtil.toObject({ canvas }),
                  url: imageTarget.url
                },
                {
                  query: {
                    annotationId: imageTarget.annotationId
                  }
                }
              );

              imagesMapRef.current[image.uuid].isFinished = true;
              setImagesUploading(p => {
                const c = { ...p };
                c[image.uuid].isFinished = true;
                return c;
              });

              const uuid = updated.uuid;

              delete imagesMapRef.current[image.uuid];
              setImagesUploading(p => {
                const c = { ...p };
                delete c[uuid];
                return c;
              });
            });
          })
        );
      } catch (err) {
        ellio.toaster.failure(err.message);
      }
    },
    [activeFolder, user, images]
  );

  return (
    <div
      style={{
        display: "none"
      }}
      ref={setOverlay}
      className={overlayCls.blurOverlay}
    >
      <FileSelect
        isOpen={isFileSelectorOpen || (isDragOver && isAllowDrag)}
        noClick={false}
        onErrors={onFileSelectErrors}
        maxSize={50}
        onDrop={files => {
          onSelectFiles(files);
          setIsFileSelectorOpen(false);
        }}
        onClose={() => setIsFileSelectorOpen(false)}
      />
    </div>
  );
};

export default DesignsSelector;
