import { useState, useEffect } from "react";
import cls from "./success-modal.module.css";
import klass from "common/klass";
import { ReactSVG } from "react-svg";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import overlayCls from "common/overlay.module.css";
import checkSvg from "./check.svg";

export default function ImageModal(props) {
  const { onClose } = props;
  const [overlay, setOverlay] = useState();

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.successModal}>
        <div className={cls.confirm}>
          <ReactSVG className={cls.check} src={checkSvg} />
          <p className={typeCls.p}>Subscription was created successfully!</p>
        </div>
        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
