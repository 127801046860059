import { useEffect, useCallback, useRef } from "react";
import { EventManager } from "@axtk/event-manager";

const noop = v => v;

const studioAction = "studio_action";

const studioActionEvents = {
  clearAll: "ellio_studio_action:clear_all",
  setBackground: "ellio_studio_action:set_background",
  addBox: "ellio_studio_action:add_box",
  addLine: "ellio_studio_action:add_line",
  drawLineEnd: "ellio_studio_action:draw_line_end",
  addText: "ellio_studio_action:add_text",
  addAsset: "ellio_studio_action:add_asset",
  added: "ellio_studio_action:added",
  addComment: "ellio_studio_action:add_comment",
  addTask: "ellio_studio_action:add_task",
  taskAdded: "ellio_studio_action:task_added",
  taskEditEnd: "ellio_studio_action:task_edit_end",
  deselected: "ellio_studio_action:deselected",
  taskDeselected: "ellio_studio_action:task_deselected",
  taskRemoved: "ellio_studio_action:task_removed",
  taskMoveStart: "ellio_studio_action:task_move_start",
  group: "ellio_studio_action:group",
  grouped: "ellio_studio_action:grouped",
  ungroup: "ellio_studio_action:ungroup",
  ungrouped: "ellio_studio_action:ungrouped",
  remove: "ellio_studio_action:remove",
  removed: "ellio_studio_action:removed",
  pickColor: "ellio_studio_action:pick_color",
  colorChanged: "ellio_studio_action:color_changed",
  cancel: "ellio_studio_action:cancel",
  save: "ellio_studio_action:save",
  moved: "ellio_studio_action:moved",
  moving: "ellio_studio_action:moving",
  scaling: "ellio_studio_action:scaling",
  scaled: "ellio_studio_action:scaled",
  rotating: "ellio_studio_action:rotating",
  textEditingExited: "ellio_studio_action:text_editing_exited",
  commentSelected: "ellio_studio_action:comment_selected",
  commentDeselected: "ellio_studio_action:comment_deselected",
  commentRemoved: "ellio_studio_action:comment_removed",
  commentMoveStart: "ellio_studio_action:comment_move_start",
  modified: "ellio_studio_action:object_modified",
  mouseMove: "ellio_studio_action:mouse_move",
  mouseAdd: "ellio_studio_action:mouse_add",
  jiraTicketCreated: "ellio_studio_action:jira_ticket_created"
};

const globalEvent = "ellio_dashboard_event";

const uploadEvents = {
  uploading: "ellio_upload:uploading"
};

const newEmitter = (eventManagerRef, name = studioAction) => (data = {}) => {
  return eventManagerRef.current.dispatch(name, {
    ...data,
    tabId: window.sessionStorage.getItem("tabId")
  });
};

const useListener = (eventManagerRef, eventName, handler = noop) => {
  const wrapHandler = useCallback(v => {
    handler(v);
  }, []);

  let listener;

  useEffect(() => {
    listener = eventManagerRef.current.addListener(eventName, wrapHandler);

    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, []);

  return listener;
};

const newEve = name => {
  const ref = useRef(new EventManager());
  const emit = useCallback(newEmitter(ref, name), []);
  return { ref, emit };
};

export {
  studioActionEvents,
  studioAction,
  uploadEvents,
  newEmitter,
  globalEvent,
  useListener,
  newEve
};
