import { useState, useEffect } from "react";
import Tippy from "@tippyjs/react";
import { track, trackActions } from "ui-track";
import { ReactSVG } from "react-svg";
import klass from "common/klass";
import cls from "./import-from-figma.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import overlayCls from "common/overlay.module.css";
import checkSvg from "./check.svg";
import crossSvg from "./cross.svg";
import { fileIdFromUrl, removeKeysFromLocalStorage } from "common/figma-common";
import Url from "url-parse";
import figmaGuidePng from "./figma-guide.png";
import validator from "validator";

const extractFileIdAndNodesIds = pageUrl => {
  const fileId = fileIdFromUrl(pageUrl);
  const u = new Url(pageUrl, true);
  const nodesIds = [u.query["node-id"]];

  return {
    fileId,
    nodesIds
  };
};

export default function ImportFromFigmaModal(props) {
  const { onClose, onImport, isProcessing, setIsProcessing } = props;
  const [overlay, setOverlay] = useState();
  const [pageUrl, setPageUrl] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [input, setInput] = useState();

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  useEffect(() => {
    if (input) {
      input.focus();
    }
  }, [input]);

  const handleImport = pUrl => {
    track(trackActions.figma.import);
    ellio.toaster.clear();
    if (!validator.isURL(pUrl)) {
      ellio.toaster.failure("Not valid url");
      return;
    }
    const { fileId, nodesIds } = extractFileIdAndNodesIds(pUrl);
    setIsProcessing(true);
    return onImport({ fileId, nodesIds })
      .then(r => {
        if (!r) {
          setIsProcessing(false);
          removeKeysFromLocalStorage();
          ellio.toaster.failure(
            "Could not connect to Figma, try one more time."
          );
          return;
        }

        setTimeout(() => {
          setIsProcessing(false);
          setIsConfirmed(true);
        }, 500);
      })
      .catch(err => {
        console.log(err);
        ellio.toaster.failure(err.message);
      });
  };

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={klass([cls.importFigmaModal])}>
        {isConfirmed ? null : (
          <h1 className={typeCls.ctrl5}>Import designs from Figma</h1>
        )}

        {isConfirmed ? (
          <div className={cls.confirm}>
            <ReactSVG className={cls.check} src={checkSvg} />
            <p className={typeCls.p}>
              Import has successfully started. You will get a notification on
              the top right corner of the page when it's finished.
            </p>
          </div>
        ) : (
          <div>
            <p className={typeCls.pMb2}>
              Copy the link to your Figma page and paste it in the field below.
              All the frames within the page will be imported.
            </p>

            <input
              ref={setInput}
              className={klass([inputCls.textInput, "mb2"])}
              placeholder="Figma page url"
              value={pageUrl}
              type="text"
              onChange={e => setPageUrl(e.target.value)}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  handleImport(pageUrl);
                }
              }}
            />

            <img className={cls.figmaGuide} src={figmaGuidePng} />
          </div>
        )}

        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          {isConfirmed ? null : (
            <>
              <button
                className={buttonCls.primarySm}
                onClick={() => handleImport(pageUrl)}
              >
                Import{" "}
                {isProcessing ? <div className="el-spinner"></div> : null}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
