import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import cls from "./seat-modal.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import overlayCls from "common/overlay.module.css";
import inputCls from "common/input.module.css";
import client from "common/client";
import Tippy from "@tippyjs/react";
import { v4 as uuidv4 } from "uuid";
import NumberFormat from "react-number-format";
import infoSvg from "./info.svg";
import externalSvg from "./external-link.svg";

export default function SeatModal(props) {
  const {
    onClose,
    onCheckout,
    isProcessing,
    selectedPrice,
    manageBilling,
    checkout
  } = props;
  const [overlay, setOverlay] = useState();
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (selectedPrice?.product.name === "Pro") {
      setQuantity(1);
    }
  }, [selectedPrice]);

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.seatModal}>
        <h1 className={typeCls.ctrl5}>Seats</h1>
        <p className={typeCls.p}>
          Set the number of seats that you'd like to include in your
          subscription. You can always manage seats and other options using the{" "}
          <a href="" onClick={manageBilling}>
            Customer Portal.
          </a>{" "}
          After clicking "Continue" you'll be redirected to Stripe.
        </p>

        <div className={cls.seats}>
          <p className={cls.withInfo}>
            Number of seats{" "}
            <span>
              <Tippy content="A seat is a user from an organization identified by the organizations domain.">
                <img draggable={false} src={infoSvg} />
              </Tippy>
            </span>
          </p>

          {selectedPrice?.product?.name === "Pro" && (
            <p style={{ margin: 0 }} className={typeCls.p3}>
              The Pro plan is limitted to one seat.
            </p>
          )}

          {selectedPrice?.product?.name !== "Pro" && (
            <div className={cls.counter}>
              <button
                disabled={selectedPrice?.product?.name === "Pro"}
                onClick={() => setQuantity(p => (p - 1 <= 0 ? p : p - 1))}
              >
                -
              </button>
              <input
                type="text"
                className={inputCls.textInput}
                value={quantity}
                disabled={selectedPrice?.product?.name === "Pro"}
                onChange={e => setQuantity(e.target.value)}
              />
              <button
                disabled={selectedPrice?.product?.name === "Pro"}
                onClick={() => setQuantity(p => p + 1)}
              >
                +
              </button>
            </div>
          )}
        </div>

        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          <button
            className={[buttonCls.primarySm, buttonCls.svgIcon].join(" ")}
            disabled={!quantity}
            onClick={() =>
              onCheckout({
                quantity,
                name: selectedPrice?.product.name,
                productId: selectedPrice?.product.id
              })
            }
          >
            Continue {isProcessing ? <div className="el-spinner"></div> : null}
            {!isProcessing && <ReactSVG src={externalSvg} />}
          </button>
        </div>
      </div>
    </div>
  );
}
