import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "common/user.context";

function Providers(props) {
  return (
    <Fragment>
      <UserContext.Provider value={props.user}>
        {props.children}
      </UserContext.Provider>
    </Fragment>
  );
}

export default Providers;
