import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import cls from "dash2/block-user.module.css";

export default function Auth(props) {
  const { finishPasswordlessAuth } = props;
  const history = useHistory();
  useEffect(() => {
    const query = new window.URLSearchParams(window.location.search);
    const code = query.get("code");
    const email = query.get("email");

    if (code && email) {
      finishPasswordlessAuth(code, email)
        .then(() => {})
        .catch(err => {
          console.log(err);
          history.push("/");
        });
    } else {
      history.push("/");
    }
  }, []);
  return (
    <div className={cls.notAllowed}>
      <div className="el-spinner"></div>
    </div>
  );
}
