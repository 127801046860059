import ReactDOM from "react-dom";
import { Spinner } from "@blueprintjs/core";
import klass from "./spinner.module.css";

const add = () => {
  const parent = document.querySelector("#js-spinner");
  ReactDOM.render(<Spinner />, parent);
};

const remove = () => {
  const parent = document.querySelector("#js-spinner");
  ReactDOM.unmountComponentAtNode(parent);
};

const spinner = { add, remove, klass };

export default spinner;
