import { useState, useEffect, useRef } from "react";
import klass from "common/klass";
import { fabric } from "fabric";
import * as canvasUtil from "./canvas-util";
import id from "pkgs/random/id";

const Fabric = props => {
  const { config = {}, onInit } = props;
  const parentRef = useRef();
  const nodeRef = useRef();
  const canvasIdRef = useRef(id());

  useEffect(() => {
    const canvas = new fabric.Canvas(nodeRef.current, {
      ...canvasUtil.defaultCanvasConfig,
      ...config
    });

    canvas.id = canvasIdRef.current;
    canvas.parentNode = parentRef.current;
    canvas.node = nodeRef.current;

    if (typeof onInit === "function") {
      onInit(canvas);
    }

    return () => {
      canvas.dispose();
    };
  }, []);

  return (
    <div ref={parentRef} hidden={!nodeRef}>
      <canvas ref={nodeRef} />
    </div>
  );
};

export default Fabric;
