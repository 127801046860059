/*
- events emitted from here are handled by the `use-sync-models` module.
- updating the annotation to the db can only be called in this module via
the `updateModel` handler.
*/
import { useEffect, useCallback, useRef } from "react";
import { throttle } from "throttle-debounce";
import * as canvasUtil from "./canvas-util";
import client, { sendStudioEvent } from "common/client";
import { track } from "ui-track";
import {
  useEve,
  studioAction,
  sendStudioAction,
  studioActionEvents
} from "common/eve";
import { handleUpdateModel } from "./update-model";
import debounceFn from "debounce-fn";

const sendEventOnTransform = ({
  transforms = ["moving", "scaling", "rotating"],
  canvas,
  userId,
  annotationId,
  design
}) => {
  const handler = (event, eventName) => {
    const nodesObjects = [event.target.toObject(canvasUtil.extraPropsToSave)];
    /* dont sync moving, scaling rotating for now */
    // sendStudioEvent(studioActionEvents[eventName], nodesObjects, {
    //   userId,
    //   annotationId,
    // design
    // });
  };
  const handlerTh = throttle(150, handler);
  const sendTransformAction = eventName => event => {
    handlerTh(event, eventName);
  };

  transforms.forEach(t => canvas.on(`object:${t}`, sendTransformAction(t)));
};

const useStudioActions = ({
  canvas,
  annotationId,
  studioActionsRef,
  user,
  isPointerEvents,
  mouseMoveEventRef,
  emitMouseMove,
  setActiveObjects,
  emitAction,
  setIsSaving,
  plansInfo,
  design
}) => {
  const userId = user._id;
  const imagesServiceRef = useRef(client.service("images"));

  /* trigger other edit actions as studio actions. */
  useEffect(() => {
    if (canvas) {
      canvas.on("object:scaled", event => {
        const { target } = event;
        const nodes = target.size ? target.getObjects() : [target];
        const nodesObjects = nodes.map(v =>
          v.toObject(canvasUtil.extraPropsToSave)
        );
        sendStudioEvent(studioActionEvents.scaled, nodesObjects, {
          userId,
          annotationId,
          design
        });
      });

      canvas.on("object:modified", event => {
        const { target } = event;
        const nodesObjects = [target.toObject(canvasUtil.extraPropsToSave)];
        handleUpdateModel({
          canvas,
          annotationId,
          event: "object:modified"
        }).then(r => {
          sendStudioEvent(studioActionEvents.modified, nodesObjects, {
            userId,
            annotationId,
            design
          });
        });
      });

      canvas.on("object:moved", event => {
        const { target } = event;
        const active = target.toObject(canvasUtil.extraPropsToSave);
        const nodes = target.size ? target.getObjects() : [target];
        const nodesObjects = nodes.map(n =>
          n.toObject(canvasUtil.extraPropsToSave)
        );
        sendStudioEvent(studioActionEvents.moved, nodesObjects, {
          active,
          userId,
          annotationId,
          design
        });
      });

      /* on scaling, moving, rotating, send action to be synced */
      sendEventOnTransform({
        canvas,
        userId,
        annotationId,
        design
      });

      canvas.on("text:editing:exited", event => {
        const textNode = event.target.toObject(canvasUtil.extraPropsToSave);
        handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.textEditingExited
        }).then(r => {
          sendStudioEvent(studioActionEvents.textEditingExited, [textNode], {
            userId,
            annotationId,
            design
          });
        });
      });

      canvas.on("selection:cleared", event => {
        setActiveObjects([]);
        if (event.deselected) {
          const deselected = event.deselected.map(v =>
            v.toObject(canvasUtil.extraPropsToSave)
          );

          /* update annotation only if I own all the deselected items */
          // const isOwnerOfAll = deselected.every(v => {
          //   return v.metaProp?.userId === userId;
          // });

          const isOwnerOfAll = true;

          if (isOwnerOfAll) {
            handleUpdateModel({
              canvas,
              annotationId,
              event: studioActionEvents.deselected
            }).then(r => {
              sendStudioEvent(studioActionEvents.deselected, deselected, {
                userId,
                annotationId,
                design
              });
            });
          } else {
            console.log("At least one item is not owned by you, not syncing.");
          }
        }
      });

      const discardIfCannotSelect = ({ canvas, event, activeUserId }) => {
        const ignore = ["comment", "task"];
        const anyNotSelectable = event.selected.some(
          s =>
            s.metaProp?.userId !== userId &&
            !ignore.find(v => s.metaProp?.type?.includes(v))
        );
        if (anyNotSelectable) {
          // canvas.discardActiveObject();
        }
      };

      /* on select or updated select, check if the object
      is created by the logged in user, if not, discard */
      canvas.on("selection:created", event => {
        discardIfCannotSelect({ canvas, event, activeUserId: userId });
        setActiveObjects(canvas.getActiveObject());
      });

      canvas.on("selection:updated", event => {
        discardIfCannotSelect({ canvas, event, activeUserId: userId });
        setActiveObjects(canvas.getActiveObject());
      });
    }
  }, [canvas]);

  /* handles studio events emitted by useKeys or buttons in the toolbar */
  const actionsHandler = useCallback(
    async v => {
      /* hacky ... */
      if (v.name !== studioActionEvents.taskEditEnd) {
        setIsSaving(true);
      }

      if (v.name === studioActionEvents.save) {
        sendStudioEvent(studioActionEvents.save, [], {
          userId,
          annotationId,
          design
        });
      }

      if (v.name === studioActionEvents.setBackground) {
        const { object, canvas } = v;
        const bgObject = object.toObject(canvasUtil.extraPropsToSave);
        canvas.add(object).renderAll();
        sendStudioEvent(studioActionEvents.setBackground, [bgObject], {
          userId,
          annotationId,
          design
        });

        if (v.backgroundImage) {
          imagesServiceRef.current
            .patch(v.backgroundImage._id, {
              url: v.backgroundImage.url,
              backgroundDataUrl: v.backgroundImage.dataUrl
            })
            .then(r => {})
            .catch(console.log);
        }

        handleUpdateModel({
          canvas,
          annotationId,
          event: sendStudioEvent.setBackground
        });
      }

      if (v.name === studioActionEvents.addAsset) {
        const { x, y, object } = v;
        const assetObject = object.toObject(canvasUtil.extraPropsToSave);

        handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.addAsset
        }).then(r => {
          sendStudioEvent(studioActionEvents.addAsset, [assetObject], {
            userId,
            x,
            y,
            annotationId,
            design
          });
        });
      }

      if (v.name === studioActionEvents.addBox) {
        const { x, y } = v;
        track(studioActionEvents.addBox);
        const box = canvasUtil.newBox({ left: x, top: y });
        box.metaProp.userId = userId;
        box.set({ stroke: user.settings.avatar.bgColor });
        canvas.add(box).renderAll();
        handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.addBox
        }).then(r => {
          sendStudioEvent(
            studioActionEvents.addBox,
            [box.toObject(canvasUtil.extraPropsToSave)],
            {
              userId,
              annotationId,
              userColor: user.settings.avatar.bgColor,
              design
            }
          );
        });
      }

      if (v.name === studioActionEvents.addText) {
        const { x, y } = v;
        track(studioActionEvents.addText);
        const text = canvasUtil.newText({ left: x, top: y });
        text.metaProp.userId = userId;
        text.set({ fill: user.settings.avatar.bgColor });
        canvas.add(text).renderAll();
        handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.addText
        }).then(r => {
          sendStudioEvent(
            studioActionEvents.addText,
            [text.toObject(canvasUtil.extraPropsToSave)],
            { userId, annotationId, design }
          );
        });
      }

      if (v.name === studioActionEvents.addLine) {
        const { x, y } = v;
        const line = canvasUtil.newLine({ left: x - 10, top: y - 10 });
        line.set({ stroke: user.settings.avatar.bgColor });
        line.metaProp.userId = userId;
        canvas.add(line).renderAll();
        handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.addLine
        }).then(r => {
          sendStudioEvent(
            studioActionEvents.addLine,
            [line.toObject(canvasUtil.extraPropsToSave)],
            { userId, annotationId, design }
          );
        });
      }

      /* drawing a line is a little different than other actions:
       * for other actions nothing is drawn, just the event is passed
       * for line drawing, the object has been rendered, we just need
       * to send the event to the other clients to add line to their canvas
       */
      if (v.name === studioActionEvents.drawLineEnd) {
        const { object: line, canvas } = v;

        handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.drawLineEnd
        }).then(r => {
          sendStudioEvent(
            studioActionEvents.drawLineEnd,
            [line.toObject(canvasUtil.extraPropsToSave)],
            { userId, annotationId, design }
          );
        });
      }

      if (v.name === studioActionEvents.addComment) {
        const { x, y } = v;
        track(studioActionEvents.addComment);
        const basicPlan = plansInfo.find(v => v.name === "Basic");
        const commentsOnCanvas = canvas
          .getObjects()
          .filter(v => v.metaProp?.type.includes("ellio_comment"));

        const isCommentLimit =
          commentsOnCanvas.length >= basicPlan.limits.comments.value;

        if (isCommentLimit && !user.subscription) {
          ellio.toaster.warning(basicPlan.limits.comments.message, {
            title: "Oops..."
          });
          return;
        }

        /* TODO: refactor, new comment and new task are more or less
        the same, move to function later */
        canvasUtil
          .newComment(
            { left: x - 5, top: y - 25 },
            user.settings.avatar.bgColor
          )
          .then(async comment => {
            comment.metaProp.userId = userId;
            canvas.add(comment);
            const commentCount = canvas
              .getObjects()
              .filter(v => v.metaProp?.type.includes("comment")).length;

            comment.metaProp.commentNumber = commentCount;

            comment.getObjects().forEach(to => {
              if (to.type === "text") {
                to.set({ text: String(commentCount) });
              }
            });

            canvas.renderAll();

            const result = await handleUpdateModel({
              canvas,
              annotationId,
              event: studioActionEvents.addComment
            });

            // const existingComments = result.model.objects.filter(v =>
            //   v.metaProp?.type.includes("comment")
            // );

            // canvas.getObjects().forEach(co => {
            //   if (co.metaProp?.id === comment.metaProp.id) {
            //     co.getObjects().forEach(to => {
            //       if (to.type === "text") {
            //         to.set({ text: String(existingComments.length) });
            //         // to.metaProp.commentNumber = String(existingComments.length);
            //         canvas.renderAll();
            //       }
            //     });
            //   }
            // });

            sendStudioEvent(
              studioActionEvents.addComment,
              [comment.toObject(canvasUtil.extraPropsToSave)],
              { userId, annotationId, design }
            );
          });
      }

      if (v.name === studioActionEvents.addTask) {
        track(studioActionEvents.addTask);
        const basicPlan = plansInfo.find(v => v.name === "Basic");
        const tasksOnCanvas = canvas
          .getObjects()
          .filter(v => v.metaProp?.type.includes("ellio_task"));

        const isTaskLimit =
          tasksOnCanvas.length >= basicPlan.limits.tasks.value;

        if (isTaskLimit && !user.subscription) {
          ellio.toaster.warning(basicPlan.limits.tasks.message, {
            title: "Oops..."
          });
          return;
        }
        const { x, y, setOpenTask } = v;
        canvasUtil
          .newTask({ left: x - 15, top: y - 15 }, user.settings.avatar.bgColor)
          .then(async task => {
            task.metaProp.userId = userId;

            canvas.add(task);
            if (typeof setOpenTask === "function") {
              v.setOpenTask({ target: task });
            }

            const existingTasks = canvas
              .getObjects()
              .filter(v => v.metaProp?.type.includes("ellio_task"));

            let candidate = existingTasks.length;

            // const taskCount = canvas
            //   .getObjects()
            //   .filter(v => v.metaProp?.type.includes("task")).length;

            /* if the task count already exist, keep incrementing until
            a unique number can be used */

            const numbers = existingTasks.map(v => v.metaProp?.taskNumber);
            let isFoundValidNumber = false;

            while (!isFoundValidNumber) {
              /* ... */
              if (!numbers.includes(candidate)) {
                isFoundValidNumber = true;
                break;
              }

              candidate += 1;
            }

            const taskNumber = candidate;

            task.metaProp.taskNumber = taskNumber;

            task.getObjects().forEach(to => {
              if (to.type === "text") {
                to.set({ text: String(taskNumber) });
              }
            });

            canvas.renderAll();

            const result = await handleUpdateModel({
              canvas,
              annotationId,
              event: studioActionEvents.addTask
            });

            /* look for the item on the canvas, if found it then update the count
            and let other clients know */

            // const existingTasks = result.model.objects.filter(v =>
            //   v.metaProp?.type.includes("task")
            // );

            // canvas.getObjects().forEach(co => {
            //   if (co.metaProp?.id === task.metaProp.id) {
            //     co.getObjects().forEach(to => {
            //       if (to.type === "text") {
            //         to.set({ text: String(existingTasks.length) });
            //         canvas.renderAll();
            //       }
            //     });
            //   }
            // });

            // emitAction({
            //   name: studioActionEvents.taskAdded
            // });

            sendStudioEvent(
              studioActionEvents.addTask,
              [task.toObject(canvasUtil.extraPropsToSave)],
              { userId, annotationId, design }
            );
          });
      }

      if (v.name === studioActionEvents.group) {
        const { object: active } = v;
        if (active?.type === "activeSelection") {
          const selected = active
            .getObjects()
            .map(v => v.toObject(canvasUtil.extraPropsToSave));
          const isCommentOrTask = selected.some(
            s =>
              s.metaProp?.type?.includes("comment") ||
              s.metaProp?.type?.includes("task")
          );
          if (isCommentOrTask) {
            canvas.discardActiveObject();
            return;
          }
          canvasUtil.group({ canvas });
          canvas.getActiveObject().metaProp.userId = userId;
          canvas.discardActiveObject();
          handleUpdateModel({
            canvas,
            annotationId,
            event: studioActionEvents.group
          }).then(r => {
            sendStudioEvent(studioActionEvents.group, selected, {
              active,
              userId,
              annotationId,
              design
            });
          });
        }
      }

      if (v.name === studioActionEvents.ungroup) {
        const selected = canvas
          .getActiveObjects()
          .map(v => v.toObject(canvasUtil.extraPropsToSave));

        const isCommentOrTask = selected.some(
          s =>
            s.metaProp?.type?.includes("comment") ||
            s.metaProp?.type?.includes("task")
        );
        if (isCommentOrTask) {
          canvas.discardActiveObject();
          return;
        }
        const active = canvas
          .getActiveObject()
          .toObject(canvasUtil.extraPropsToSave);

        canvasUtil.ungroup({ canvas });
        canvas.discardActiveObject();
        handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.ungroup
        }).then(r => {
          sendStudioEvent(studioActionEvents.ungroup, selected, {
            active,
            userId,
            annotationId,
            design
          });
        });
      }

      if (v.name === studioActionEvents.remove) {
        track(studioActionEvents.remove);
        const nodes = canvas
          .getActiveObjects()
          .map(v => v.toObject(canvasUtil.extraPropsToSave));

        /* if any item is not mine, dont do anything */
        /*
        for now allow remove as well if not owner, uncomment to bring back
        ellio.toaster.clear();

        if (nodes.some(v => v.metaProp?.userId !== userId)) {
          ellio.toaster.warning(
            "It seems like you are trying to remove items that were created by other people. Try updating your selection.",
            { title: "Oops..." }
          );
          return;
        }
        */

        const activeObject = canvas.getActiveObject();
        const comments = canvasUtil.getNodesByType(activeObject, "comment");
        const tasks = canvasUtil.getNodesByType(activeObject, "task");

        /* when objects are about to removed, first check if there are
        any tasks or comments selected to be removed, if so, delete
        them from the db first.
        */

        if (comments) {
          const dr = await Promise.all(
            comments.map(async c => {
              const r = await client.service("comments").find({
                query: {
                  annotationId,
                  id: c.metaProp.id
                }
              });

              if (r.total && r.data.length) {
                const e = await client
                  .service("comments")
                  .remove(r.data[0]._id);
              }
            })
          );
        }

        if (tasks) {
          const dr = await Promise.all(
            tasks.map(async c => {
              const r = await client.service("tasks").find({
                query: {
                  annotationId,
                  id: c.metaProp.id
                }
              });

              if (r.total && r.data.length) {
                const e = await client.service("tasks").remove(r.data[0]._id);
              }
            })
          );
        }

        /* remove items from this canvas */
        canvasUtil.remove({ canvas });
        canvas.renderAll();

        /* update the annotation in the db */
        await handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.remove
        });

        /* let other connected clients know so they can sync up their canvas */
        sendStudioEvent(studioActionEvents.remove, nodes, {
          userId,
          annotationId,
          design
        });
      }

      if (v.name === studioActionEvents.pickColor) {
        return;
        const { setColorPicker, x, y, object } = v;
        const nodes = object.size ? object.getObjects() : [object];
        const isAnyNotMine = nodes.some(n => n.metaProp?.userId !== userId);
        if (isAnyNotMine) {
          console.log("at least one object is not yours to change color.");
          return;
        }
        setColorPicker(p => ({ ...p, isOpen: true, x, y, target: object }));
      }

      if (v.name === studioActionEvents.colorChanged) {
        return;
        const { color, object } = v;

        handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.pickColor
        }).then(r => {
          sendStudioEvent(
            studioActionEvents.colorChanged,
            [object.toObject(canvasUtil.extraPropsToSave)],
            { color, userId, annotationId, design }
          );
        });
      }

      if (v.name === studioActionEvents.taskEditEnd) {
        const { object } = v;
        track(studioActionEvents.taskEditEnd);
        handleUpdateModel({
          canvas,
          annotationId,
          event: studioActionEvents.taskEditEnd
        }).then(r => {
          sendStudioEvent(
            studioActionEvents.taskEditEnd,
            [object.toObject(canvasUtil.extraPropsToSave)],
            {
              userId,
              annotationId,
              design
            }
          );
        });
      }
    },
    [canvas]
  );

  /* setup actions listener */
  /* TODO may need a separate listener for handling mouse events */
  useEffect(() => {
    let listener;
    if (canvas) {
      listener = studioActionsRef.current.addListener(
        "studio_action",
        actionsHandler
      );
    }

    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, [canvas]);

  /* mouse move events handler for the collaborative pointer */
  useEffect(() => {
    let listener;

    if (canvas) {
      const mouseMoveAction = studioActionEvents.mouseMove;
      const mouseMoveHandler = event => {
        if (mouseMoveAction && event) {
          sendStudioEvent(mouseMoveAction, event.pointer, {
            userId,
            userColor: user.settings.avatar.bgColor,
            annotationId,
            design
          });
        }
      };

      const mouseHandlerTh = throttle(150, mouseMoveHandler);

      /* when there is a mouse move, send the mouse event */
      canvas.on("mouse:move", e => {
        emitMouseMove({
          name: "mouse_moving_now",
          event: e
        });
      });

      listener = mouseMoveEventRef.current.addListener(
        "studio_mouse_move",
        e => {
          if (isPointerEvents) {
            mouseHandlerTh(e.event);
          }
        }
      );
    }

    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, [isPointerEvents, canvas]);
};

export default useStudioActions;
