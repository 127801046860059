import client, { sendStudioEvent } from "common/client";
import { studioActionEvents } from "common/eve";
import { useState } from "react";
import parseTask from "common/task/parse-task-content";
import { handleUpdateModel } from "studio/update-model";
import * as canvasUtil from "studio/canvas-util";
import { popupwindow } from "common/iframe";

/* takes jira refresh token from the local storage and makes a call
 * to get a new one. You don't want to do this too frequently, because of that
 * there is another function refreshExistingToken that gets a new one only
 * if required. If we dont have a refresh token in local storage, return '';
 */
export const refreshJiraAccessToken = () => {
  const rToken = window.localStorage.getItem("jira_refresh_token");
  /* read refresh token, and get a new access token and set to local storage */
  if (rToken) {
    return client
      .service("jira")
      .create({
        refreshToken: rToken
      })
      .then(r => {
        if (r?.access_token) {
          window.localStorage.setItem("jira_token", r.access_token);
          // return Promise.resolve(r.access_token);
          return r.access_token;
        }
      });
  }

  return Promise.resolve("");
};

export const getCloudInfo = token => {
  if (!token) {
    return Promise.reject(new Error("Valid token was not provided."));
  }
  return client
    .service("jira")
    .create({
      requestCloudId: true,
      token
    })
    .then(r => {
      if (r != null) {
        return r[0];
      }
      return null;
    });
};

/*
 * try to get company info with existing token,
 * if expired, refresh token, save in local storage, and
 * return the new token. If any error happens or if we don't
 * have an existing token, return ''.
 */
export const refreshExistingToken = () => {
  const token = window.localStorage.getItem("jira_token");
  const cloudId = window.localStorage.getItem("jira_cloud_id");

  if (token && cloudId) {
    return getCloudInfo(token)
      .then(r => {
        if (r != null) {
          return token;
        } else {
          refreshJiraAccessToken()
            .then(newToken => newToken)
            .catch(err => Promise.reject(""));
        }
      })
      .catch(err => {
        refreshJiraAccessToken()
          .then(newToken => newToken)
          .catch(err => {
            // ellio.toaster.failure(
            //   "Could not refresh access token to Jira, please try again"
            // );
            console.log(err);
            return Promise.reject("");
          });
      });
  } else {
    return Promise.reject("");
  }
};

/*
 * one way sync, from ellio to Jira
 * for new tasks: create a ticket
 * for existing tasks/tickets: update the ticket
 * for deleted tasks: delete the ticket from Jira
 */

export const removeJiraKeysFromLS = () => {
  window.localStorage.removeItem("jira_token");
  window.localStorage.removeItem("jira_refresh_token");
  window.localStorage.removeItem("jira_cloud_id");
  window.localStorage.removeItem("jira_cloud_url");
};

export const syncWithJira = async ({
  projectKey,
  sTasks,
  selectedProject,
  designId,
  design,
  canvas,
  annotation,
  annotationId,
  userId,
  token,
  storyTitle,
  story
}) => {
  if (!projectKey) {
    throw new Error("Project key was missing. Please select a Jira project.");
  }

  const cloudId = window.localStorage.getItem("jira_cloud_id");
  const cloudUrl = window.localStorage.getItem("jira_cloud_url");
  const ellioUrl = `${ellio.webHost}/designs/${designId}`;

  const busyItems = {};
  sTasks.forEach(t => (busyItems[t.metaProp.id] = true));
  // setJiraBusy(busyItems);

  const toCreate = sTasks.filter(v => !v.metaProp.ticket);
  const toUpdate = sTasks.filter(v => v.metaProp.ticket);

  const ticketsToCreate = toCreate.map(v => {
    const { title, description } = parseTask(v.metaProp.content);

    const ticketDescription = description + `\n\n---\n\nEllio Url: ${ellioUrl}`;

    return {
      ticketTitle: title,
      ticketDescription,
      snapshotUrl: v.metaProp.snapshotUrl,
      taskId: v.metaProp.id,
      ticket: null,
      annotationId
    };
  });

  const ticketsToUpdate = toUpdate.map(v => {
    const { title, description } = parseTask(v.metaProp.content);
    return {
      ticketTitle: title,
      ticketDescription: description,
      snapshotUrl: v.metaProp.snapshotUrl,
      taskId: v.metaProp.id,
      ticket: v.metaProp.ticket,
      annotationId
    };
  });

  const sync = {
    ticketsToCreate,
    ticketsToUpdate,
    annotationId,
    cloudUrl,
    ellioUrl,
    storyTitle,
    story,
    design
  };

  return client.service("jira").create({
    sync,
    token,
    cloudId,
    projectKey
  });
};

export const connectJira = (opt = {}) => {
  return new Promise(async (resolve, reject) => {
    if (typeof opt.preventDefault === "function") {
      opt.preventDefault();
    }

    let ref;

    const { userId } = opt;

    if (!userId) {
      return Promise.reject(new Error("userId was not set."));
    }

    const localRefreshToken = window.localStorage.getItem("jira_refresh_token");

    /* if we have a refresh token, get a new one, dont bother to check for
     * another case, because Jira tokens expire too soon anyways.
     * even if you have a token, assume it's already expired.
     */
    if (localRefreshToken) {
      const token = await refreshJiraAccessToken();
      return resolve(token);
    } else {
      const url = ellio.jiraCallback + `&state=${window.encodeURI(userId)}`;
      ref = popupwindow(url, 800, 800);

      /* listen for message from popup */
      window.addEventListener(
        "message",
        event => {
          /* e is an event that is sent from the popup window back here, that has
           * access token, cloudId etc.
           */
          if (event.origin !== window.origin) {
            return;
          }

          window.localStorage.setItem("jira_token", event.data.token);
          window.localStorage.setItem(
            "jira_refresh_token",
            event.data.refreshToken
          );
          window.localStorage.setItem("jira_cloud_id", event.data.cloudId);
          window.localStorage.setItem(
            "jira_cloud_url",
            event.data.cloudInfo.url
          );

          return resolve(event.data.token);
        },
        false
      );
    }
  });
};
