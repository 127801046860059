import { useState, useEffect } from "react";
import klass from "common/klass";
import Tippy from "@tippyjs/react";
import typeCls from "common/type.module.css";
import cls from "./file-select.module.css";
import Dropzone from "react-dropzone";
import fileSelectSvg from "./file-select.svg";
import cloudSvg from "./cloud.svg";
import closeSvg from "./close.svg";
import crossSvg from "./cross.svg";
import { ReactSVG } from "react-svg";

const MB = 1e6;

const noop = v => v;

const DefaultDropArea = props => {
  const { isDragActive, onClose, isDropArea } = props;

  return (
    <div className={cls.indicator}>
      {!props.dropArea ? (
        <Tippy content="Close">
          <a
            href=""
            className={cls.close}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }}
          >
            <ReactSVG src={crossSvg} />
          </a>
        </Tippy>
      ) : null}
      {isDragActive ? (
        <img className={cls.uploadIndicator} src={cloudSvg} alt="" />
      ) : (
        <img src={fileSelectSvg} alt="" />
      )}
      {isDragActive ? (
        <p className={typeCls.ctrlDarkNavy}>Drop anywhere to upload</p>
      ) : (
        <p className={typeCls.ctrlDarkNavy}>
          Click anywhere to browse or drag files to the page to upload
        </p>
      )}
    </div>
  );
};

const FileSelect = props => {
  const {
    onDrop,
    style,
    className,
    onClose = noop,
    dropArea: DropArea = DefaultDropArea,
    noClick = false,
    maxSize = 15,
    onErrors = noop,
    isOpen = false,
    dropAreaClass = !props.dropArea ? cls.dropArea : props.dropAreaClass || "",
    accept = "image/jpeg, image/png"
  } = props;

  const [errors, setErrors] = useState();

  const onDropRejected = issues => {
    setErrors(issues);
  };

  const onDropAccepted = f => {
    // console.log('accepted', f)
    /* for those who are accepted, remove them from the errors list ? */
    // setErrors(null);
  };

  useEffect(() => {
    onErrors(errors);
  }, [errors]);

  return (
    <div
      className={klass([
        className,
        isOpen ? cls.showFileSelect : "",
        !props.dropArea ? cls.fileSelect : "",
        noClick && cls.fileSelectNoClick
      ])}
      style={style}
    >
      <Dropzone
        onDropRejected={onDropRejected}
        onDropAccepted={onDropAccepted}
        accept={accept}
        maxSize={maxSize * MB}
        noClick={noClick}
        onDrop={v => {
          setErrors(null);
          onDrop(v);
        }}
        // getFilesFromEvent={event => {
        //   console.log(event);
        //   const files = [];
        //   const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

        //   for (var i = 0; i < fileList.length; i++) {
        //     const file = fileList.item(i);

        //     Object.defineProperty(file, 'myProp', {
        //       value: true
        //     });

        //     files.push(file);
        //   }

        //   return files;
        // }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className={klass([
              dropAreaClass,
              noClick && cls.dropAreaNoClick,
              isDragActive ? (!props.dropArea ? cls.active : "active") : ""
            ])}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <DropArea
              isDragActive={isDragActive}
              onClose={onClose}
              isDropArea={props.dropArea}
            />
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default FileSelect;
