import client, { useServiceEvents } from "common/client";
import { useState, useEffect, useRef, useCallback } from "react";
import { paramsForServer as qq } from "feathers-hooks-common";

const useImages = (params = {}, deps = []) => {
  const imagesService = useRef(client.service("images"));
  const [images, setImages] = useState();
  const [imagesError, setImagesError] = useState();

  const refreshImages = (
    prms = {
      query: {
        isDeleted: false
      }
    }
  ) => {
    return imagesService.current
      .find(prms)
      .then(resp => {
        setImages(resp.data);
        return resp;
      })
      .catch(setImagesError);
  };

  useEffect(() => refreshImages(params), deps);

  // useServiceEvents(imagesService, refreshImages);
  // useServiceEvents(imagesService, (d, e) => console.log(d, e));

  const createImage = useCallback(
    (image, successMsg = "Design was created successfully.") => {
      return imagesService.current
        .create(image)
        .then(resp => {
          ellio.toaster.success(successMsg);
          return resp;
        })
        .catch(err => ellio.toaster.failure(err.message));
    },
    []
  );

  const deleteImage = useCallback(
    (image, successMsg = "Designs was removed successfully.") => {
      return (
        imagesService.current
          // .remove(image._id, qq({ images: [image] }))
          .patch(image._id, {
            isDeleted: true
          })
          .then(resp => {
            ellio.toaster.success(successMsg);
            return resp;
          })
          .catch(err => ellio.toaster.failure(err.message))
      );
    },
    []
  );

  const deleteImages = useCallback(
    (images, successMsg = "Designs were removed successfully.") => {
      return (
        imagesService.current
          .patch(
            null,
            {
              isDeleted: true
            },
            {
              query: {
                _id: {
                  $in: images.map(v => v._id)
                }
              }
            }
          )
          // return imagesService.current
          //   .remove(
          //     null,
          //     qq({
          //       query: {
          //         _id: {
          //           $in: images.map(v => v._id)
          //         }
          //       },
          //       images
          //     })
          //   )
          .then(resp => {
            ellio.toaster.success(successMsg);
            return resp;
          })
          .catch(err => ellio.toaster.failure(err.message))
      );
    },
    []
  );

  return {
    images,
    setImages,
    imagesError,
    imagesService,
    createImage,
    deleteImage,
    deleteImages,
    refreshImages
  };
};

export default useImages;
