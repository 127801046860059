import { useState, useEffect } from "react";
import klass from "common/klass";
import cls from "./studio.module.css";
import { Button, Menu, Icon } from "@blueprintjs/core";
import FileSelect from "common/file-select";
import { studioActionEvents, newEmitter } from "common/eve";
import keys from "common/keys";
import addSvg from "./assets/add.svg";

const SuperKey = () => {
  return <Icon icon="key-shift" />;
};

const Key = props => {
  const { action, modifier = "key-option" } = props;
  const icon = `key-${keys[action].modifiers[0]}`;
  return (
    <>
      <Icon icon={icon} />
      {keys[action].isShift ? <Icon icon="key-shift" /> : ""}{" "}
      <span>{keys[action].label}</span>{" "}
    </>
  );
};

const onFileSelectErrors = errors => {
  /* TOOD: add an option to see which file errored out */
  if (errors?.length) {
    const first = errors[0].errors[0];
    const code = first.code;
    if (code === "file-too-large") {
      ellio.toaster.warning(
        "At least one of the selected files is too large. Maximum file size allowed for each asset is 1MB"
      );
    } else if (code === "file-invalid-type") {
      ellio.toaster.warning(
        "At least one of the selected files was not an image."
      );
    }
  }
};

const ContextMenu = props => {
  const {
    x,
    y,
    target,
    canvas,
    setColorPicker,
    annotationId,
    emitAction,
    setOpenTask,
    onAssetsSelect
  } = props;

  const [isShowAddTask, setIsShowAddTask] = useState(true);

  useEffect(() => {
    if (canvas) {
      const o = canvas.getActiveObject();
      setIsShowAddTask(!Boolean(o));
    }
  }, []);

  return (
    <Menu
      style={{ left: x, top: y }}
      className={klass(["bp3-elevation-2", cls.contextMenu])}
    >
      <Menu.Item
        style={{ display: isShowAddTask ? "flex" : "none" }}
        icon="add-to-artifact"
        label={
          <span>
            <Key action={studioActionEvents.addTask} />
          </span>
        }
        onClick={() => {
          if (canvas.getActiveObject()) {
            return;
          }
          emitAction({
            name: studioActionEvents.addTask,
            x,
            y,
            canvas,
            annotationId,
            setOpenTask
          });
        }}
        text="Task"
      />

      <Menu.Item
        icon="square"
        label={
          <span>
            <Key action={studioActionEvents.addBox} />
          </span>
        }
        onClick={() => {
          emitAction({
            name: studioActionEvents.addBox,
            x,
            y,
            canvas,
            annotationId
          });
        }}
        text="Box"
      />

      <Menu.Item
        icon="comment"
        style={{ display: "none" }}
        label={
          <span>
            <Key action={studioActionEvents.addComment} />
          </span>
        }
        onClick={() => {
          emitAction({
            name: studioActionEvents.addComment,
            x,
            y,
            canvas,
            annotationId
          });
        }}
        text="Comment"
      />

      <Menu.Item
        icon="new-text-box"
        label={
          <span>
            <Key action={studioActionEvents.addText} />
          </span>
        }
        onClick={() => {
          emitAction({
            name: studioActionEvents.addText,
            x,
            y,
            canvas,
            annotationId
          });
        }}
        text="Text"
      />

      <Menu.Item
        className={cls.addAsset}
        labelElement={
          <div>
            <FileSelect
              onErrors={onFileSelectErrors}
              isOpen={true}
              onDrop={files => {
                onAssetsSelect(files, { x, y });
              }}
              maxSize={1}
              dropArea={() => (
                <div className={cls.addAssetInner}>
                  <div>
                    <span>
                      <img draggable={false} src={addSvg} />
                    </span>
                    <span>Add assets</span>
                  </div>
                  <div>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
            />
          </div>
        }
        onClick={() => {}}
      />

      <Menu.Divider />
      <Menu.Item
        style={{ display: "none" }}
        icon="group-objects"
        label={
          <span>
            <Key action={studioActionEvents.group} />
          </span>
        }
        onClick={() => {
          emitAction({
            name: studioActionEvents.group,
            canvas,
            annotationId,
            object: canvas.getActiveObject()
          });
        }}
        text="Group"
      />
      <Menu.Item
        style={{ display: "none" }}
        icon="ungroup-objects"
        label={
          <span>
            <Key action={studioActionEvents.ungroup} />
          </span>
        }
        onClick={() => {
          emitAction({
            name: studioActionEvents.ungroup,
            canvas,
            annotationId,
            object: canvas.getActiveObject()
          });
        }}
        text="Ungroup"
      />
      <Menu.Item
        style={{ display: "none" }}
        icon="tint"
        label={
          <span>
            <Key action={studioActionEvents.pickColor} />
          </span>
        }
        onClick={() => {
          emitAction({
            name: studioActionEvents.pickColor,
            x,
            y,
            object: target,
            canvas,
            setColorPicker,
            annotationId
          });
        }}
        text="Pick Color"
      />
      <Menu.Item
        icon="remove"
        label={
          <span>
            <Icon icon="key-backspace" />
          </span>
        }
        onClick={() => {
          emitAction({
            name: studioActionEvents.remove,
            canvas,
            annotationId
          });
        }}
        text="Remove"
      />
    </Menu>
  );
};

export default ContextMenu;
