import cls from "./all-plans.module.css";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import routes from "routes";
import BillingToggle from "./billing-toggle2";
import Tippy from "@tippyjs/react";
import { DateTime } from "luxon";
import { k } from "common/klass";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import typeCls from "common/type.module.css";
import client from "common/client";
import usePrices from "./use-prices";
import stripeSvg from "./payment/stripe.svg";
import infoSvg from "./info.svg";
import checkSvg from "./check.svg";
import crossSvg from "./cross.svg";
import NumberFormat from "react-number-format";
import appear from "common/animations/appear";
import WithAnimation from "common/animations/with-animation";
import SeatModal from "./seat-modal";
import SuccessModal from "./success-modal";
import rocketPng from "./rocket.png";
import teamsPng from "./team3.png";
import planetPng from "./planet.png";
import saturnPng from "./saturn.png";
import spacePng from "./space.png";
import spaceManPng from "./spaceman.png";

const getStatus = subscription => {
  const s = {
    active: "Active",
    canceled: "Canceled",
    trialing: "Trial"
  };
  if (!subscription) {
    return "Trial";
  }
  return s[subscription.status];
};

const getTotal = subscription => {
  const subTotal = (subscription?.plan?.amount * subscription?.quantity) / 100;
  const coupon = subscription?.discount?.coupon;
  const off = coupon?.percent_off / 100;
  return subTotal - subTotal * off;
};

const intervalToText = intervalObj => {
  const display = {
    month: "monthly",
    year: "yearly"
  };
  return display[intervalObj?.interval];
};

const planImages = {
  Teams: teamsPng,
  Pro: spaceManPng,
  Basic: rocketPng
};

/* the basic price is handled by the app, not stripe */
const basicPrice = {
  id: "price_772jsjdjfj82",
  unit_amount: 0,
  product: {
    name: "Basic",
    description: "Basic default plan, with limited functionality"
  }
};

const DefaultText = props => {
  const { children } = props;
  return (
    <p className={k(typeCls.p3, cls.defaultText)}>
      {children} After selecting a plan, you can set the number of seats that
      you'd like to include in your subscription. After setting the number of
      seats, you'll be redirected to Stripe. Payment processing and billing are
      all handled by Stripe and you can always manage your subscription through
      Stripe's Customer Portal.
    </p>
  );
};

const AvailablePlans = props => {
  const {
    user,
    prices,
    selectedPrice,
    setSelectedPrice,
    plansInfo,
    isYearly,
    setIsYearly
  } = props;

  const pricesCopy = prices
    .slice()
    .sort((a, b) => (a.product.name === "Pro" ? -1 : 1));
  const pricesWithBasic = [basicPrice, ...pricesCopy];

  if (!plansInfo) {
    return <div>Loading...</div>;
  }

  const basicPlan = plansInfo?.find(v => v.name === "Basic");
  const proPlan = plansInfo?.find(v => v.name === "Pro");
  const teamsPlan = plansInfo?.find(v => v.name === "Teams");

  return (
    <>
      <div>
        <BillingToggle
          user={user}
          isYearly={isYearly}
          setIsYearly={setIsYearly}
        />
      </div>
      <div className={cls.list}>
        {pricesWithBasic
          .filter(v => {
            const isBasic = v.product.name === "Basic";

            if (isYearly) {
              return v?.recurring?.interval === "year" || isBasic;
            } else {
              return v?.recurring?.interval === "month" || isBasic;
            }

            return isBasic;
          })
          .map((p, i) => (
            <div
              className={[
                cls.card,
                selectedPrice?.id === p.id ? cls.cardActive : ""
              ].join(" ")}
              key={p.id}
            >
              <p className={cls.name}>
                {p.product.name}
                {p.product.name === "Basic" && (
                  <img
                    style={{ marginLeft: "8px" }}
                    draggable={false}
                    src={checkSvg}
                  />
                )}
              </p>
              <div
                className={[
                  cls.image,
                  p.product.name.includes("Team") ? cls.imageTeam : ""
                ].join(" ")}
              >
                <img draggable={false} src={planImages[p.product.name]} />
              </div>
              <div className={cls.price}>
                <p className={cls.priceValue}>${p.unit_amount / 100}</p>
                <p className={cls.priceNote}>
                  <span
                    style={{
                      display:
                        p.product.name === "Pro" || p.product.name === "Basic"
                          ? "none"
                          : "block"
                    }}
                  >
                    per user
                  </span>

                  {!p?.recurring?.interval ? null : p.recurring.interval ===
                    "year" ? (
                    <span>per year</span>
                  ) : (
                    <span>per month</span>
                  )}

                  {
                    // p.product.name !== "Basic" && <span>per month</span>
                  }
                </p>
              </div>

              <p style={{ display: "none" }} className={cls.description}>
                {p.product.description}
              </p>

              {p.product.name !== "Basic" && (
                <p className={cls.description}>Includes a 7-day free trial</p>
              )}
              {p.product.name === "Basic" && (
                <p className={cls.description}>Basic functionality</p>
              )}

              {p.product.name === "Pro" && (
                <div className={cls.details}>
                  {proPlan?.details.map(p => (
                    <Detail key={p.id} plan={p} />
                  ))}
                </div>
              )}

              {p.product.name === "Teams" && (
                <div className={cls.details}>
                  {teamsPlan?.details.map(p => (
                    <Detail key={p.id} plan={p} />
                  ))}
                </div>
              )}

              {p.product.name === "Basic" && (
                <div className={cls.details}>
                  {basicPlan?.details.map(p => (
                    <Detail key={p.id} plan={p} />
                  ))}
                </div>
              )}

              {p.product.name !== "Basic" && (
                <div className={cls.button}>
                  <button
                    onClick={() => setSelectedPrice(p)}
                    className={buttonCls.primarySm}
                  >
                    {selectedPrice?.id === p.id ? "Selected" : "Select plan"}
                  </button>
                </div>
              )}

              {p.product.name === "Basic" && (
                <p
                  style={{ marginTop: "auto", padding: "7px 0" }}
                  className={typeCls.p3}
                >
                  Default plan
                </p>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

const BillingInfo = props => {
  const { user, coupon } = props;
  const currPeriodStart = DateTime.fromMillis(
    user?.subscription?.current_period_start * 1000
  );
  const currPeriodEnd = DateTime.fromMillis(
    user?.subscription?.current_period_end * 1000
  );

  const start = currPeriodStart.toLocaleString(DateTime.DATE_SHORT);
  const end = currPeriodEnd.toLocaleString(DateTime.DATE_SHORT);

  return (
    <div className={cls.nextBillInfo}>
      <p className={typeCls.ctrl2}>{end}</p>
      <p className={typeCls.ctrl2}>{user?.subscription?.quantity} seat(s)</p>
      <p className={typeCls.ctrl2}>
        {coupon ? "Subtotal" : "Total"}:&nbsp;
        <NumberFormat
          displayType="text"
          thousandsGroupStyle="thousand"
          value={
            (user?.subscription?.plan?.amount * user?.subscription?.quantity) /
            100
          }
          prefix="$"
          decimalSeparator="."
          type="text"
          thousandSeparator={true}
          allowNegative={false}
        />
      </p>
      {coupon ? (
        <>
          <p className={typeCls.ctrl2}>
            Coupon: {coupon.name} ({coupon.percent_off}% off)
          </p>
          <p className={typeCls.ctrl2}>
            Total:&nbsp;
            <NumberFormat
              displayType="text"
              thousandsGroupStyle="thousand"
              value={getTotal(user?.subscription)}
              prefix="$"
              decimalSeparator="."
              type="text"
              thousandSeparator={true}
              allowNegative={false}
            />
          </p>
        </>
      ) : null}
    </div>
  );
};

const Detail = props => {
  const { plan } = props;
  return (
    <p className={k(cls.planRow, plan.value === 0 ? cls.planRowMute : "")}>
      {plan.value === 0 ? (
        <img draggable={false} src={crossSvg} />
      ) : (
        <img draggable={false} src={checkSvg} />
      )}
      {plan.title}
    </p>
  );
};

export default function AllPlans(props) {
  const {
    setUser,
    user,
    manageBilling,
    plansInfo,
    isYearly,
    setIsYearly
  } = props;
  const history = useHistory();

  const [selectedPrice, setSelectedPrice] = useState();
  const [isCheckout, setIsCheckout] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [successOverlay, setSuccessOverlay] = useState(false);

  const refreshSubscriptionData = () => {
    // return Promise.resolve({});
    let theCustomer;
    let theSubscription;
    return Promise.all([
      client.service("users").get(user._id),
      client.service("stripe").find({
        query: {
          resource: "customer",
          email: user.email
        }
      })
    ])
      .then(([_user, _customers]) => {
        const c = _customers.data[0];
        theCustomer = c;

        return client.service("stripe").find({
          query: {
            resource: "subscription",
            customerId: c?.id
          }
        });
      })
      .then(_subscriptions => {
        const s = _subscriptions.data[0];
        theSubscription = s;

        return {
          stripeCustomerId: theCustomer?.id,
          subscription: theSubscription
        };
      })

      .then(o => {
        return client.service("users").patch(user._id, o);
      })
      .then(u => {
        return {
          user: u,
          customer: theCustomer,
          subscription: theSubscription
        };
      })
      .then(o => {
        const toNull = {};
        if (!o.customer) {
          toNull.stripeCustomerId = null;
        }
        if (!o.subscription) {
          toNull.subscription = null;
        }
        return client.service("users").patch(user._id, toNull);
      })
      .catch(err => {
        // ellio.toaster.failure(err.message + " Please try refreshing the page.");
      });
  };

  useEffect(refreshSubscriptionData, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccessOverlay(true);
      /* at this point we can assume that the customerId has been created
       * and also subscription has been associated with the user,
       * we can check if customerId and subscriptions are set,
       * if not, we will set it here.
       */

      refreshSubscriptionData()
        .then(d => {
          // console.log("sub DATA", d);
        })
        .catch(err => {
          ellio.toaster.failure(err.message);
        });
    }

    if (query.get("canceled")) {
    }
  }, []);

  const [prices, setPrices, pricesErr] = usePrices();

  const checkout = e => {
    setIsCheckout(true);
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const { quantity, name, productId } = e;

    if (quantity >= 99) {
      ellio.toaster.warning("Seat should be a number between 1 and 99", {
        title: "Oops..."
      });
      setIsCheckout(false);
      return;
    }

    client
      .service("stripe")
      .create({
        resource: "session",
        priceId: selectedPrice.id,
        quantity: name === "Pro" ? 1 : quantity,
        coupon: "",
        productId
      })
      .then(r => {
        window.location.href = r.url;
      })
      .catch(err => {
        console.log(err);
        setIsCheckout(false);
        ellio.toaster.failure(err.message);
      });
  };

  const activePrice = prices?.find(v => v.id === user?.subscription?.plan?.id);

  const currPeriodStart = DateTime.fromMillis(
    user?.subscription?.current_period_start * 1000
  );
  const currPeriodEnd = DateTime.fromMillis(
    user?.subscription?.current_period_end * 1000
  );
  const cancelAtDate = DateTime.fromMillis(
    user?.subscription?.cancel_at * 1000
  );

  const trialStart = DateTime.fromMillis(
    user?.subscription?.trial_start * 1000
  );

  const trialEnd = DateTime.fromMillis(user?.subscription?.trial_end * 1000);

  const start = currPeriodStart.toLocaleString(DateTime.DATE_SHORT);
  const end = currPeriodEnd.toLocaleString(DateTime.DATE_SHORT);
  const cancelDateStr = cancelAtDate.toLocaleString(DateTime.DATE_SHORT);

  const trialStartStr = trialStart.toLocaleString(DateTime.DATE_SHORT);
  const trialEndStr = trialEnd.toLocaleString(DateTime.DATE_SHORT);

  const coupon = user?.subscription?.discount?.coupon;

  const subStatus = getStatus(user?.subscription);

  if (user.orgNameId && !user.stripeCustomerId) {
    return <div>This page is managed by your organization's admin.</div>;
  }

  if (!prices || !plansInfo) {
    return <div>Loading ...</div>;
  }

  const basicPlan = plansInfo?.find(v => v.name === "Basic");
  const proPlan = plansInfo?.find(v => v.name === "Pro");
  const teamsPlan = plansInfo?.find(v => v.name === "Teams");

  return (
    <div className={cls.plans}>
      {user?.subscription?.plan?.id ? (
        <div className={cls.activePlan}>
          <div
            className={
              subStatus === "Canceled"
                ? cls.activePlanInnerCancel
                : cls.activePlanInner
            }
          >
            <section>
              <p
                className={[typeCls.p3, cls.activePlanTitle, "upper"].join(" ")}
              >
                Current plan{" "}
                <span
                  className={[
                    cls.planStatus,
                    subStatus === "Active"
                      ? cls.planStatusActive
                      : subStatus === "Canceled" || subStatus === "Trial"
                      ? cls.planStatusOrange
                      : ""
                  ].join(" ")}
                >
                  {subStatus}
                </span>
              </p>
              {user?.subscription?.cancel_at_period_end && (
                <p className={typeCls.p3}>
                  Subscription active until <strong>{cancelDateStr}</strong>
                </p>
              )}
              {subStatus !== "Canceled" && (
                <WithAnimation animation={appear}>
                  <div className={cls.activePlanCard}>
                    <h2 className={cls.activePlanCardH2}>
                      {activePrice?.product?.name}
                      <span className={cls.activePlanSeats}>
                        {` ${user?.subscription?.quantity} seat(s)`}
                      </span>
                    </h2>
                    <p className={cls.planInterval}>
                      Billed {intervalToText(activePrice?.recurring)}
                    </p>
                    <div className={cls.activePlanDetails}>
                      <div className={cls.activePlanFeatures}>
                        {activePrice?.product?.name === "Pro" ? (
                          <div className={cls.details}>
                            {proPlan?.summary.map(p => (
                              <Detail key={p.id} plan={p} />
                            ))}
                          </div>
                        ) : (
                          <div className={cls.details}>
                            {teamsPlan?.summary.map(p => (
                              <Detail key={p.id} plan={p} />
                            ))}
                          </div>
                        )}
                      </div>
                      <div
                        className={[
                          cls.activePlanImage,
                          activePrice?.product?.name === "Pro"
                            ? cls.activePlanImagePro
                            : ""
                        ].join(" ")}
                      >
                        <img
                          draggable={false}
                          src={planImages[activePrice?.product?.name]}
                        />
                      </div>
                    </div>

                    <div className={cls.activePlanMange}>
                      <button
                        disabled={
                          !user.stripeCustomerId || subStatus === "Canceled"
                        }
                        className={buttonCls.primarySm}
                        onClick={() => {
                          setIsRedirecting(true);
                          manageBilling();
                        }}
                      >
                        Manage{" "}
                        {isRedirecting ? (
                          <span className="el-spinner"></span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </WithAnimation>
              )}

              {subStatus === "Canceled" && (
                <div>
                  <DefaultText />
                  <div>
                    <WithAnimation animation={appear}>
                      <AvailablePlans
                        isYearly={isYearly}
                        setIsYearly={setIsYearly}
                        user={user}
                        prices={prices}
                        selectedPrice={selectedPrice}
                        setSelectedPrice={setSelectedPrice}
                        plansInfo={plansInfo}
                      />
                    </WithAnimation>
                  </div>
                </div>
              )}
            </section>
            <section>
              {subStatus !== "Canceled" && (
                <p
                  style={{
                    display: !user.stripeCustomerId ? "none" : "block"
                  }}
                  className={[typeCls.p3, cls.activePlanTitle].join(" ")}
                >
                  Next bill
                </p>
              )}

              <div
                style={{ display: !user.stripeCustomerId ? "none" : "block" }}
                className={cls.billingStatus}
              >
                {subStatus === "Active" &&
                  !user?.subscription?.cancel_at_period_end && (
                    <BillingInfo coupon={coupon} user={user} />
                  )}

                {(subStatus === "Active" || subStatus === "Trial") &&
                  user?.subscription?.cancel_at_period_end && (
                    <div className={cls.BillingInfoCancel}>
                      <p className={typeCls.p3}>
                        Cancellation will be effective at the end of your
                        current billing period on{" "}
                        <strong>{cancelDateStr}</strong>. You can renew your
                        subscription anytime before the end of the current
                        billing period by using the{" "}
                        <a href="" onClick={manageBilling}>
                          Customer Portal
                        </a>
                      </p>
                    </div>
                  )}

                {subStatus === "Trial" &&
                  !user?.subscription?.cancel_at_period_end && (
                    <div className={cls.trialInfo}>
                      <p className={typeCls.p3}>
                        Your free trial started on{" "}
                        <strong>{trialStartStr}</strong>. After your free trial
                        ends on <strong>{trialEndStr}</strong>, this plan will
                        continue automatically using the payment method on file.
                      </p>
                    </div>
                  )}
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div>
          <DefaultText />
          <WithAnimation animation={appear}>
            <AvailablePlans
              isYearly={isYearly}
              setIsYearly={setIsYearly}
              user={user}
              prices={prices}
              selectedPrice={selectedPrice}
              setSelectedPrice={setSelectedPrice}
              plansInfo={plansInfo}
            />
          </WithAnimation>
        </div>
      )}
      {selectedPrice && (
        <SeatModal
          isProcessing={isCheckout}
          onCheckout={checkout}
          manageBilling={manageBilling}
          onClose={() => setSelectedPrice(null)}
          selectedPrice={selectedPrice}
        />
      )}

      {successOverlay && (
        <SuccessModal
          onClose={() => {
            setSuccessOverlay(false);
            history.push(routes.settings.subRoutes.subscriptions.url);
          }}
        />
      )}
    </div>
  );
}
