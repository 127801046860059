import { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import * as canvasUtil from "./canvas-util";
import ColorPicker from "./color-picker";
import { studioActionEvents } from "common/eve";

/* TODO: make sure that only the owner can change the color */
const useColorPicker = opts => {
  const { canvas, emitAction } = opts;
  const colorPickerRef = useRef();
  const selectedColorRef = useRef();
  const [colorPicker, setColorPicker] = useState({
    x: null,
    y: null,
    isOpen: false,
    target: null
  });

  useEffect(() => {
    if (canvas) {
      if (colorPicker.isOpen) {
        const parent = canvas.node.parentElement;
        const { x, y, target } = colorPicker;
        const div = document.createElement("div");
        div.id = `canvas_colorpicker_${canvas.id}`;
        parent.appendChild(div);
        colorPickerRef.current = div;
        ReactDOM.render(
          <ColorPicker
            x={x}
            y={y}
            initialColor={canvasUtil.getTargetColor(target)}
            onClose={() => setColorPicker(p => ({ ...p, isOpen: false }))}
            onChange={color => {
              canvasUtil.changeColor({
                canvas,
                color,
                target: colorPicker.target
              });
              selectedColorRef.current = color;
            }}
          />,
          div
        );
      } else {
        const cId = `#canvas_colorpicker_${canvas.id}`;
        const wrapper = document.querySelector(cId);
        emitAction({
          name: studioActionEvents.colorChanged,
          color: selectedColorRef.current,
          object: colorPicker.target
        });
        if (wrapper) {
          wrapper.remove();
          colorPickerRef.current = null;
        }
      }
    }
  }, [canvas, colorPicker]);

  return {
    colorPicker,
    colorPickerRef,
    setColorPicker
  };
};

export default useColorPicker;
