import { useEffect } from "react";
import { fromBinder } from "baconjs";
import { serviceEvents } from "common/client";

export default function useStream(
  service,
  cb,
  { every = 1000, isCleanup = true, events = serviceEvents, deps = [] } = {}
) {
  if (!cb || !service) {
    throw new Error("Must pass a callback and a feathers service");
  }

  /*
  streams: {
    'eventName': {
      stream: Bacon Stream,
      cancel: Function to call to cancel the stream
    }
  }
  */
  const streams = {};

  useEffect(() => {
    events.forEach(event => {
      const s = fromBinder(sink => {
        service.on(event, d =>
          sink({
            data: d,
            event
          })
        );
      });
      streams[event] = {
        stream: s
      };
    });

    Object.keys(streams).forEach(s => {
      const cancel = streams[s].stream
        .throttle(every)
        /* this didnt work */
        // .mapError(err => {
        //   console.log(err);
        // })
        .onValue(cb);
      streams[s].cancel = cancel;
    });

    return () => {
      if (isCleanup) {
        serviceEvents.forEach(event => service.off(event));
      }

      Object.keys(streams).forEach(s => {
        streams[s].cancel();
      });
    };
  }, deps);
}
