import cls from "./hints.module.css";
import typeCls from "common/type.module.css";
import Video from "common/video";
import doubleClickSvg from "./assets/double-click.svg";
import rightClickSvg from "./assets/right-click.svg";
import dragMp4 from "./drag-files.mp4";
import dragWebm from "./drag-files.webm";

export default function DesignsHints() {
  return (
    <>
      <div className={cls.hint}>
        <div className={cls.hintInner}>
          <h2 className={typeCls.ctrl}>Drag files to upload</h2>
          <p className={typeCls.p3}>
            You can drag one or multiple images anywhere on the page to upload.
          </p>
          {/*<Video mp4={dragMp4} webm={dragWebm} />*/}
        </div>
      </div>
    </>
  );
}
