import { v4 as uuidv4 } from "uuid";

/* https://stackoverflow.com/questions/14672746/how-to-compress-an-image-via-javascript-in-the-browser */

// Take an image URL, downscale it to the given width, and return a new image URL.
function downscaleImage(dataUrl, opts = {}) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    const defaults = {
      newWidth: 384,
      imageType: "image/jpeg",
      imageArguments: 0.95
    };
    const options = { ...defaults, ...opts };
    const { newWidth, imageType, imageArguments } = options;

    image.onload = function () {
      const oldWidth = image.width;
      const oldHeight = image.height;
      const newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
      const newDataUrl = canvas.toDataURL(imageType, imageArguments);
      resolve(newDataUrl);
    };

    image.src = dataUrl;
  });
}

/* read each file and then call the callback with the array of image
 * objects.
 */
const readFiles = (files, downscaleOptions = {}, readAs = "dataUrl") => {
  const images = [];
  return new Promise((resolve, reject) => {
    files.forEach(file => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        const smallerDataUrl = await downscaleImage(
          reader.result,
          downscaleOptions
        );
        images.push({
          uuid: uuidv4(),
          name: file.name,
          size: file.size,
          dataUrl: smallerDataUrl,
          file
        });
        if (files.length === images.length) {
          resolve(images);
        }
      };
      if (readAs === "dataUrl") {
        reader.readAsDataURL(file);
      } else if (readAs === "binary") {
        reader.readAsArrayBuffer(file);
      } else {
        reject(new Error("cannot read as " + readAs));
      }
    });
  });
};

export default readFiles;

export { downscaleImage };
