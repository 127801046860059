const { NODE_ENV } = process.env;
import cls from "./account.module.css";
import React, { Fragment, useEffect } from "react";
import { cleanupLS } from "common/client";
import { useHistory } from "react-router-dom";
import logoSvg from "./assets/logo.svg";
import appear from "common/animations/appear";
import WithAnimation from "common/animations/with-animation";
import googleSvg from "./assets/google.svg";
import githubSvg from "./assets/github.svg";
import flagPng from "./assets/flag.png";
import planetPng from "./assets/planet.png";
import fuzzyPng from "./assets/fuzzy.png";
import circlesPng from "./assets/circles.png";
import spacePng from "./assets/space.png";

function Account(props) {
  const {
    isLoginForm,
    setLoginStatus,
    status,
    header,
    message,
    actionButton,
    children
  } = props;

  const history = useHistory();

  const onFailure = err => {
    console.log(err);
    setLoginStatus(status.NOT_LOGGED_IN);
  };

  const onSuccess = result => {
    setLoginStatus(status.LOGGED_IN);
  };

  return (
    <div className={cls.account}>
      <div className={cls.header}>
        <a href="" className={cls.logo}>
          <img src={logoSvg} alt="Logo" />
          <span className={cls.beta}>Beta</span>
        </a>
        <div className={cls.headerRight}>
          <p>{header.text}</p>
          <button
            onClick={() => {
              window.location.href = header.link;
            }}
            className={cls.headerButton}
          >
            {header.buttonText}
          </button>
        </div>
      </div>

      <div className={cls.message}>
        <WithAnimation animation={appear}>
          <h1>{message.title}</h1>
          <p>
            {message.text}{" "}
            {isLoginForm ? (
              <span>
                If you don't have an account, you can head over to the{" "}
                <a href="/signup">Sign up</a> page.
              </span>
            ) : null}
          </p>
        </WithAnimation>
      </div>

      <div className={cls.loginOptions}>
        <div className={cls.loginButtons}>
          <a
            href=""
            onClick={e => {
              e.preventDefault();
              cleanupLS();
              window.location.href = `${window.ellio.apiHost}/oauth/google`;
            }}
            className={cls.loginButton}
          >
            <img src={googleSvg} alt="Google" />
            <span>{actionButton} with Google</span>
          </a>
          <a
            style={{ display: "none" }}
            href={`${window.ellio.apiHost}/oauth/github`}
            className={cls.loginButton}
          >
            <img src={githubSvg} alt="Github" />
            <span>{actionButton} with Github</span>
          </a>
        </div>
      </div>

      <div className={cls.separator}>
        <div className={cls.sep}></div>
        <p>Or</p>
        <div className={cls.sep}></div>
      </div>

      <div className={cls.form} className="mt2">
        {children}
      </div>

      <div className={cls.footer}>
        <p>&copy; 2021 Enlini</p>
      </div>

      <div className={cls.planet}>
        <img src={planetPng} />
      </div>

      <div className={cls.flag}>
        <img src={flagPng} />
      </div>

      <div className={cls.fuzzy}>
        <img src={fuzzyPng} />
      </div>

      <div className={cls.circles}>
        <img src={circlesPng} />
      </div>
    </div>
  );
}

export default Account;
