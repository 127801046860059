import client from "common/client";
import axios from "axios";

const uploadsService = client.service("uploads");

const noop = v => v;

const s3Upload = async (fileWrapper, onProgress = noop) => {
  const { file, uuid } = fileWrapper;
  const resp = await uploadsService.create({
    key: uuid,
    contentType: file.type
  });

  const { signedUrl } = resp;

  const options = {
    headers: { "Content-Type": file.type /* "x-amz-acl": "public-read" */ },
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress({
        event: progressEvent,
        progress: percentCompleted,
        uuid
      });
    }
  };

  return axios.put(signedUrl, file, options).then(r => {
    const urlWithParams = r.config.url;
    const m = urlWithParams.match(/(http.*amazonaws.com)(.*)/);
    const base = m[1];
    const url = `${base}/${uuid}`;
    return url;
  });
};

export default s3Upload;
