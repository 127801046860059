import { useEffect } from "react";
import { drawLineOnDrag } from "studio/canvas-util";
import { studioActionEvents, newEmitter } from "common/eve";

const useDragToDrawLine = ({
  canvas,
  user,
  annotationId,
  canDrawLineRef,
  setIsDrawLineMode,
  emitAction
}) => {
  useEffect(() => {
    if (canvas) {
      drawLineOnDrag({
        canvas,
        user,
        annotationId,
        canDrawLineRef,
        setIsDrawLineMode,
        onDrawEnd(opts) {
          const { canvas, annotationId, line, userId } = opts;
          emitAction({
            name: studioActionEvents.drawLineEnd,
            canvas,
            annotationId,
            object: line
          });
        }
      });
    }
  }, [canvas]);
};

export default useDragToDrawLine;
