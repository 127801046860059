import { useState, useEffect, useRef } from "react";
import anime from "animejs/lib/anime.es.js";
import { v4 as uuidv4 } from "uuid";
import { fabric } from "fabric";
import { Spinner } from "@blueprintjs/core";
import Task from "./task";
import Tippy from "@tippyjs/react";
import cls from "./task-modal.module.css";
import overlayCls from "common/overlay.module.css";
import buttonCls from "common/button.module.css";
import { studioActionEvents } from "common/eve";
import closeSvg from "./close.svg";
import * as canvasUtil from "studio/canvas-util";
import Editor from "rich-markdown-editor";
import marked from "marked";
import { urlToFile } from "common/blob";
import s3Upload from "common/s3/s3-upload";
import client from "common/client";
import { handleUpdateModel } from "studio/update-model";

const getTarget = canvas => {
  const objects = canvas.getObjects();
  const last = objects[objects.length - 1];
  const active = canvas.getActiveObject();
  const target = active || last;
  if (!target.metaProp?.type.includes("ellio_task")) {
    return null;
  }
  return target;
};

const removeS3Url = async url => {
  const key = canvasUtil.getS3KeyFromUrl(url);
  return client.service("uploads").remove(key);
};

const TaskModal = props => {
  const {
    canvas,
    emitAction,
    annotation,
    annotationId,
    setOpenTask,
    openTask,
    userId,
    canvasTransform,
    setIsAllowDrag
  } = props;

  const [snapshot, setSnapshot] = useState("");
  const [copyCanvas, setCopyCanvas] = useState("");
  const [overlay, setOverlay] = useState();
  const [modal, setModal] = useState();
  const textEditCb = useRef();
  /* need these because outline editor pushes scroll up one by one as you type */
  const offsetTop = useRef(0);
  const mainRef = useRef();
  const [taskNumber, setTaskNumber] = useState("");
  const [editorRef, setEditorRef] = useState();

  const [progress, setProgress] = useState(0);
  const checkStatusRef = useRef();

  /* enable/disable drag over option */
  useEffect(() => {
    setIsAllowDrag(false);
    return () => setIsAllowDrag(true);
  }, []);

  /* make a copy of the canvas, remove all nodes except the bg image
  and the target node
  */
  useEffect(() => {
    if (openTask && canvas) {
      const canvasNode = document.createElement("canvas");
      const instance = new fabric.Canvas(canvasNode, {
        ...canvasUtil.defaultCanvasConfig
      });

      setTimeout(() => {
        const model = canvasUtil.toObject({ canvas });

        canvasUtil.loadModel({ canvas: instance, model }).then(r => {
          canvasUtil.setCanvasDimensions({
            canvas: instance,
            image: r.image,
            // canvasWidth: canvasUtil.getCanvasContainerWidth()
            canvasWidth: canvasUtil.defaultCanvasWidth
          });

          const bgImage = instance
            .getObjects()
            .find(v => v.metaProp?.type.includes("ellio_design"));

          const tr = canvasTransform.length
            ? canvasTransform
            : bgImage.metaProp?.transform
            ? bgImage.metaProp?.transform
            : [];

          if (tr.length) {
            instance.setViewportTransform(tr);

            // const containerWidth = canvasUtil.getCanvasContainerWidth();
            const containerWidth = canvasUtil.defaultCanvasWidth;

            if (bgImage.width > containerWidth) {
              bgImage.scaleToWidth(containerWidth);
            }

            instance.setViewportTransform(tr);
            instance.renderAll();
          }

          setCopyCanvas(instance);
        });
      }, 0);
    }
  }, [openTask, canvas]);

  /* when copyCanvas is available, generate snapshot url */
  useEffect(() => {
    if (copyCanvas) {
      const node = getTarget(canvas);
      if (!node) {
        return;
      }

      const nodesToRemove = copyCanvas
        .getObjects()
        .filter(
          v =>
            (!v.metaProp?.type.includes("ellio_design") &&
              v.metaProp?.id !== node.metaProp.id &&
              v.metaProp?.type.includes("ellio_task")) ||
            v.metaProp?.type.includes("ellio_comment")
        );
      copyCanvas.remove(...nodesToRemove);
      const canvsDataUrl = canvasUtil.getCanvasSnapshot({
        canvas: copyCanvas,
        node
      });

      urlToFile(canvsDataUrl)
        .then(r => {
          // console.log("made the file", r);
          return r;
        })
        .then(file => {
          /* to send to s3 */
          // const blobUri = window.URL.createObjectURL(file)
          // console.log(blobUri)
          return s3Upload({ uuid: uuidv4(), file }, p => {
            setProgress(p.progress);
          });
          /* to send with feathers:
            const fd = new FormData();
            fd.append("file", file);
            fd.append('fileId', '1255');
            const attSvc = restClient.service("attachments").create(fd);
          */
          /* to set as blob:url: setSnapshot(window.URL.createObjectURL(file)); */
        })
        .then(async imageUrl => {
          // setSnapshot(imageUrl)
          setSnapshot(canvsDataUrl);
          if (node.metaProp.snapshotUrl) {
            await removeS3Url(node.metaProp.snapshotUrl);
          }

          node.metaProp.snapshotUrl = imageUrl;
          /* may not be necessary */
          // handleUpdateModel({ canvas, annotationId }).then(r => {
          //   canvas.renderAll();
          // });
        })
        .catch(console.log);
    }
  }, [copyCanvas]);

  /* fade in overlay */
  useEffect(() => {
    let overlayAnim;
    let modalAnim;
    if (overlay && modal) {
      overlay.classList.add(overlayCls.fadeIn);
      const jsMain = document.querySelector("#js-main");
      /* need these because outline editor pushes scroll up one by one as you type */
      mainRef.current = jsMain;
      offsetTop.current = jsMain.scrollTop;
    }
  }, [overlay, modal]);

  /* when the task number is available, set is and move the cursor to the end */
  useEffect(() => {
    if (openTask.target.metaProp.taskNumber && editorRef) {
      setTaskNumber(openTask.target.metaProp.taskNumber);
      setTimeout(() => {
        editorRef.focusAtEnd();
      }, 0);
    }
  }, [
    openTask.target.metaProp.taskNumber,
    openTask.target.metaProp.content,
    editorRef
  ]);

  if (!canvas) {
    return null;
  }

  const editChange = v => {
    if (mainRef.current) {
      mainRef.current.scrollTop = offsetTop.current;
    }
    textEditCb.current = v;
  };

  const editSave = () => {
    if (!snapshot) {
      return;
    }

    const canvasObjects = canvas.getObjects();
    if (typeof textEditCb.current === "function") {
      openTask.target.metaProp.content = textEditCb.current();
    }
    emitAction({
      name: studioActionEvents.taskEditEnd,
      canvas,
      annotationId,
      object:
        canvas.getActiveObject() || canvasObjects[canvasObjects.length - 1]
    });
    setOpenTask(false);
  };

  const onClose = () => {
    setOpenTask(false);
  };

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div ref={setModal} className={cls.taskModal}>
        <div className={cls.taskModalInner}>
          <div className={cls.taskModalHeader}></div>
          <div className={cls.taskModalBody}>
            <div className={cls.taskWithImage}>
              <div className={cls.image}>
                {!snapshot ? (
                  <div>
                    <progress max="100" value={String(progress) || 0}>
                      {progress || 0}%
                    </progress>
                  </div>
                ) : (
                  <img draggable="false" src={snapshot} />
                )}
              </div>
              <div
                className={cls.task}
                style={{
                  display: !taskNumber ? "none" : "block"
                }}
              >
                <Editor
                  ref={setEditorRef}
                  placeholder="Type '/' for more options"
                  value={
                    openTask.target.metaProp.content || `## ${taskNumber}:`
                  }
                  onCancel={onClose}
                  autoFocus={true}
                  onChange={editChange}
                  onSave={editSave}
                  readOnly={false}
                  defaultValue={openTask.target.metaProp.content || ""}
                />
              </div>
              <div className={cls.footerButtons}>
                <button
                  onClick={onClose}
                  className={buttonCls.primaryTextSmall}
                >
                  Close
                </button>
                <Tippy content="⌘ + Enter">
                  <button
                    disabled={!snapshot}
                    className={buttonCls.primarySm}
                    onClick={editSave}
                  >
                    Save
                  </button>
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;
