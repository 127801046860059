export const urlToFile = (
  url,
  filename = "image.jpg",
  mimeType = "image/jpeg"
) => {
  if (!url) {
    throw new Error("must set url");
  }
  return fetch(url)
    .then(res => res.arrayBuffer())
    .then(buf => new File([buf], filename, { type: mimeType }));
};
