/*
this module handles syncing canvas events (and related events) with
the connected clients. It's never supposed to call the updateModel handler
to update the annotation in db. It's only job is to take events and update
the canvas for other clients.
*/
import { useRef } from "react";
import ReactDOM from "react-dom";
import id from "pkgs/random/id";
import client, { useServiceEvents } from "common/client";
import { studioActionEvents } from "common/eve";
import * as canvasUtil from "./canvas-util";
import { fabric } from "fabric";
import deepcopy from "deepcopy";

/*********************** REFACTOR SYNCING LOGICCCCCC *****************/

const syncActiveSelMod = ({ objects, canvas }) => {
  canvasUtil.revive(objects).then(elms => {
    canvas.getObjects().forEach(o => {
      elms.forEach(e => {
        if (e.type === "activeSelection") {
          const updatedNodes = e.getObjects();

          updatedNodes.forEach(un => {
            const dc = canvasUtil.getObjectTransformInGroup(un);
            const { scaleX, scaleY, left, top, angle } = dc;
            if (un.metaProp?.id === o.metaProp?.id) {
              o.set({
                scaleX,
                scaleY,
                left,
                top,
                angle
              });
              o.setCoords();
              canvas.renderAll();
            }
          });
        } else {
          if (e.metaProp?.id === o.metaProp?.id) {
            const { left, top, scaleX, scaleY, angle } = e;
            o.set({ left, top, scaleX, scaleY, angle });
            o.setCoords();
            canvas.renderAll();
          }
        }
      });
    });
  });
};

const useSyncModels = ({ canvas, annotationId, user, setIsSaving }) => {
  const userId = user._id;
  const studioEvents = useRef(client.service("studio-events"));
  const mouseRef = useRef();
  useServiceEvents(
    studioEvents,
    e => {
      if (canvas) {
        const { objects, name: eventName, meta } = e;

        /* notify clients save happened */
        if (
          eventName === studioActionEvents.save &&
          meta.annotationId === annotationId
        ) {
        }

        if (meta.annotationId === annotationId) {
          setTimeout(() => {
            setIsSaving(false);
          }, 500);
        }

        const thisTab = window.sessionStorage.getItem("tabId");

        /* dont sync if the event is coming from this tab */
        if (meta.tabId === thisTab && !meta.syncOwn) {
          return;
        }

        /* dont sync if the annotation is not correct */
        if (meta.annotationId !== annotationId) {
          return;
        }

        /* have to handle mouse move a little differently compared
        to the other events. have to capture it first for syncing.
        */
        if (eventName === studioActionEvents.mouseMove) {
          const { x, y } = objects;
          if (mouseRef.current) {
            mouseRef.current.set({ left: x, top: y, fill: meta.userColor });
            canvas.renderAll();
          } else {
            canvasUtil.newPointer().then(mouse => {
              mouse.metaProp.userId = meta.userId;
              mouse.set({ fill: meta.userColor });
              canvas.add(mouse).renderAll();
              mouseRef.current = mouse;
            });
          }
        } else {
          if (
            eventName.includes("add") ||
            eventName === studioActionEvents.drawLineEnd
          ) {
            canvasUtil.revive(objects).then(nodes => {
              canvas.add(...nodes).renderAll();
            });
          }

          if (eventName === studioActionEvents.jiraTicketCreated) {
            canvasUtil.revive(objects).then(nodes => {
              canvas.getObjects().forEach(co => {
                nodes.forEach(n => {
                  if (co.metaProp?.id === n.metaProp?.id) {
                    /* I tried to set co.metaProp = n.metaProp,
                     * it didn't work ..... */
                    co.metaProp.ticket = n.metaProp.ticket;
                    co.metaProp.content = n.metaProp.content;
                    co.metaProp.ticketUrl = n.metaProp.ticketUrl;
                    co.metaProp.jiraStory = n.metaProp.jiraStory;
                  }
                });
              });
            });
            canvas.renderAll();
          }

          if (eventName === studioActionEvents.group) {
            canvasUtil.revive(objects).then(elms => {
              const g = new fabric.Group(elms, {
                metaProp: {
                  id: `ellio_group_node_${elms[0].metaProp?.id}`
                }
              });

              objects.forEach(m => {
                canvas.getObjects().forEach(o => {
                  if (m.metaProp?.id === o.metaProp?.id) {
                    canvas.remove(o);
                  }
                });
              });

              g.set({ left: meta.active.left, top: meta.active.top });
              canvas.add(g);
              canvas.renderAll();
            });
          }

          if (eventName === studioActionEvents.ungroup) {
            fabric.util.enlivenObjects([meta.active], groups => {
              groups.forEach(group => {
                canvas.getObjects().forEach(obj => {
                  if (obj.metaProp?.id === group.metaProp?.id) {
                    const items = obj.getObjects();
                    obj.destroy();
                    canvas.add(...items);
                    canvas.remove(obj);
                    canvas.renderAll();
                  }
                });
              });
              canvas.renderAll();
            });
          }

          if (eventName === studioActionEvents.remove) {
            canvas.getObjects().forEach(eo => {
              objects.forEach(o => {
                if (eo.metaProp?.id === o.metaProp?.id) {
                  canvas.remove(eo).discardActiveObject().renderAll();
                }
              });
            });
          }

          if (eventName === studioActionEvents.setBackground) {
            fabric.util.enlivenObjects(objects, elms => {
              canvasUtil.clearAll({
                canvas,
                isDeleteBackground: true
              });

              canvasUtil.setCanvasDimensions({
                image: elms[0],
                canvas,
                // canvasWidth: canvasUtil.getCanvasContainerWidth()
                canvasWidth: canvasUtil.defaultCanvasWidth
              });
              canvas.add(...elms).renderAll();
            });
          }

          if (eventName === studioActionEvents.textEditingExited) {
            fabric.util.enlivenObjects(objects, elms => {
              canvas.getObjects().forEach(eo => {
                elms.forEach(e => {
                  if (eo.metaProp?.id === e.metaProp?.id) {
                    eo.set(e);
                    eo.setCoords();
                    canvas.renderAll();
                  }
                });
              });
            });
          }

          /* when objects are deselected, or task edit 
          is finished,  sync their transform props
          and also their meta props. for comments and tasks,
          metaProp.content will be synced too.
          */
          if (
            eventName === studioActionEvents.deselected ||
            eventName === studioActionEvents.cancel
          ) {
            canvas.getObjects().forEach(co => {
              objects.forEach(so => {
                if (co.metaProp?.id === so.metaProp?.id) {
                  co.set({
                    left: so.left,
                    top: so.top,
                    scaleX: so.scaleX,
                    scaleY: so.scaleY,
                    angle: so.angle,
                    fill: so.fill
                  });
                  co.metaProp = so.metaProp;
                  co.setCoords();
                  canvas.renderAll();
                }
              });
            });
          }

          /* had to do this sync differently, it wasn't updating
           * properly when another made a change ...
           */
          if (eventName === studioActionEvents.taskEditEnd) {
            const task = objects[0];
            const taskId = task.metaProp.id;
            client
              .service("annotations")
              .get(annotationId)
              .then(ann => {
                const latest = ann.model.objects.find(
                  v => v.metaProp.id === taskId
                );

                canvas.getObjects().forEach(co => {
                  if (co.metaProp?.id === taskId) {
                    co.metaProp = {
                      ...co.metaProp,
                      ...latest.metaProp
                    };
                    setTimeout(() => {
                      canvas.renderAll();
                    }, 200); /* maybe not needed ... */
                  }
                });
              });
          }

          if (eventName === studioActionEvents.colorChanged) {
            canvasUtil.revive(objects).then(elms => {
              elms.forEach(elm => {
                if (elm.type === "activeSelection") {
                  canvas.getObjects().forEach(co => {
                    elm.getObjects().forEach(un => {
                      if (co.metaProp?.id === un.metaProp?.id) {
                        const fill = un.fill;
                        co.set({ fill });
                        canvas.renderAll();
                      }
                    });
                  });
                } else if (elm.type === "group") {
                  canvas.getObjects().forEach(co => {
                    if (co.metaProp?.id === elm.metaProp?.id) {
                      const updatedNodes = elm.getObjects();
                      co.getObjects().forEach(cNode => {
                        cNode.set({ fill: updatedNodes[0].fill });
                        canvas.renderAll();
                      });
                    }
                  });
                } else {
                  canvas.getObjects().forEach(co => {
                    elms.forEach(elm => {
                      if (co.metaProp?.id === elm.metaProp?.id) {
                        co.set({ fill: elm.fill });
                        canvas.renderAll();
                      }
                    });
                  });
                }
              });
            });
          }

          if (
            eventName === studioActionEvents.commentMoveStart ||
            eventName === studioActionEvents.taskMoveStart
          ) {
            const nodeIds = objects.map(o => o.metaProp.id);
            nodeIds.forEach(ci => {
              const node = document.querySelector(`#${ci}`);
              if (node) {
                ReactDOM.unmountComponentAtNode(node);
                node.remove();
              }
            });
          }
        }

        /* sync any modification/transform/move, except add, mouse move
         color change, group, ungroup, deselected.
         */

        /* TODO: refactor this module, make it simpler, maybe make
         another studio for comments, and keep the task canvas simple?
         ************************ REFACTOR **************************
         */
        if (
          eventName !== studioActionEvents.mouseMove &&
          !eventName.includes("add") &&
          eventName !== studioActionEvents.colorChanged &&
          !eventName.includes("deselected") &&
          !eventName.includes("group") &&
          !eventName.includes("move_start") &&
          !eventName.includes("remove") &&
          !eventName.includes("task_edit_end") &&
          (studioActionEvents.moving ||
            studioActionEvents.scaling ||
            studioActionEvents.rotating ||
            studioActionEvents.modified)
        ) {
          syncActiveSelMod({ canvas, objects });
        }
      }
    },
    [canvas],
    ["created"]
  );
};

export default useSyncModels;
