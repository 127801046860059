const { REACT_APP_FIGMA_CALLBACK } = process.env;
import { useEffect } from "react";
import Url from "url-parse";
import client from "common/client";
import { DateTime } from "luxon";
import { figmaTokenInfo } from "common/figma-common";

export default function Figma(props) {
  const { user } = props;

  useEffect(() => {
    if (user?._id) {
      const u = new Url(window.location.href, true);

      if (u.query.state !== user._id) {
        console.log("state no match");
        ellio.toaster.failure("Couldn't connect to Figma, please try again.");
        return;
      }

      client
        .service("figma")
        .create(
          {
            code: u.query.code,
            callback: REACT_APP_FIGMA_CALLBACK
          },
          {
            query: {
              resource: "code"
            }
          }
        )
        .then(r => {
          const info = figmaTokenInfo(r);
          window.opener.postMessage(
            {
              ...info,
              name: "figma"
            },
            window.opener.origin
          );
          window.close();
        })
        .catch(err => {
          ellio.toaster.failure(err.message);
        });
    }
  }, [user]);

  if (!user._id) {
    return <div>Loading ...</div>;
  }

  return (
    <div style={{ padding: "12px" }}>
      <div>
        <div className="mb">Connecting to Figma ...</div>
        <div className="el-spinner"></div>
      </div>
    </div>
  );
}
