import { useEffect, useState } from "react";
import client from "common/client";

export default function usePrices() {
  const [prices, setPrices] = useState();
  const [err, setErr] = useState();
  useEffect(() => {
    async function getPricesAndProducts() {
      const pricesResp = await client.service("stripe").find({
        query: {
          resource: "price"
        }
      });

      if (pricesResp.total) {
        return pricesResp.data;
      }
    }

    getPricesAndProducts()
      .then(expandedPrices => {
        const available = expandedPrices.filter(
          v => !v.product.name.includes("archived")
        );
        setPrices(available);
      })
      .catch(err => {
        // ellio.toaster.failure(err.message);
        setErr(err);
      });
  }, []);

  return [prices, setPrices, err];
}
