const isHeading = str => {
  const trimmedStr = str.trim();
  const headings = {
    h1: "#",
    h2: "##",
    h3: "###",
    h4: "####"
  };
  return (
    trimmedStr.startsWith(headings.h1) ||
    trimmedStr.startsWith(headings.h2) ||
    trimmedStr.startsWith(headings.h3) ||
    trimmedStr.startsWith(headings.h4)
  );
};

const trim = heading => {
  return heading.replace(/#+/, "").trim();
};

/* take the md content, and parse title and description */
const parse = mdStr => {
  if (!mdStr) {
    return {
      title: "",
      description: ""
    };
  }
  const lines = mdStr.split("\n");
  // console.log(lines);
  const potentialTitle = lines[0];
  let title = isHeading(potentialTitle) ? trim(potentialTitle) : "";
  let description = "";
  if (!title) {
    description = mdStr.replace(/\\\n|\\/g, "");
    title = lines.filter(Boolean).join("").substring(0, 20) + "...";
  } else {
    description = lines
      .slice(1)
      .join("\n")
      .trim()
      .replace(/\\\n|\\/g, "");
  }

  // console.log('title', title)
  // console.log('description', description)

  return {
    title,
    description
  };
};

export default parse;
