const { REACT_APP_STRIPE_KEY, NODE_ENV } = process.env;
const isProd = NODE_ENV === "production";
import { v4 as uuidv4 } from "uuid";
import RSwitch from "react-input-switch";
import Tippy from "@tippyjs/react";
import ImageModal from "./image-modal";
import { k } from "common/klass";
import s3Upload from "common/s3/s3-upload";
import cls from "./settings.module.css";
import imageModalCls from "./image-modal.module.css";
import { useState, useEffect } from "react";
import routes, { pathTo } from "routes";
import client, { isValidSubscription } from "common/client";
import { DateTime } from "luxon";
import {
  Switch,
  Link,
  Route,
  useRouteMatch,
  Redirect,
  useLocation
} from "react-router-dom";
import Avatar from "common/avatar";
import pageCls from "common/page.module.css";
import inputCls from "common/input.module.css";
import buttonCls from "common/button.module.css";
import typeCls from "common/type.module.css";
import { ReactSVG } from "react-svg";
import personSvg from "./person.svg";
import userSvg from "./user2.svg";
import subsSvg from "./subs.svg";
import shieldSvg from "./shield.svg";
import layersSvg from "./layers.svg";
import teamSvg from "./team2.svg";
import buildingSvg from "./building2.svg";
import chevronRightSvg from "./chevron-right.svg";
import orgSvg from "./org.svg";
import shieldBgPng from "./shield-bg.png";
import spaceManPng from "./spaceman.png";
import picturePng from "./picture.png";
import AllPlans from "./all-plans";
import Team from "./team";
import useScrollToTop from "common/use-scroll-to-top";

const UsageRow = props => {
  const { resource, basicPlan, resourceNames } = props;
  return (
    <div className={cls.usageRow}>
      <p>
        {resourceNames[resource?.name]}: {resource.value}/
        {basicPlan?.limits[resource.name].value}
      </p>
      <progress
        className={cls.usageProgress}
        max={String(basicPlan?.limits[resource.name].value)}
        value={String(resource?.value) || 0}
      ></progress>
    </div>
  );
};

const Usage = props => {
  const { user, usage, plansInfo } = props;

  const resourceNames = {
    images: "Images",
    annotations: "Annotations",
    tasks: "Tasks",
    comments: "Comments",
    folders: "Folders",
    jiraSyncs: "Jira syncs",
    figmaImports: "Figma imports"
  };

  if (!usage || !plansInfo) {
    return <div>Loading...</div>;
  }

  const basicPlan = plansInfo.find(v => v.name === "Basic");

  return (
    <div className={cls.usage}>
      {Object.keys(usage)
        .sort()
        .map(uk => (
          <UsageRow
            key={uk}
            basicPlan={basicPlan}
            resource={{ name: uk, value: usage[uk] }}
            resourceNames={resourceNames}
          />
        ))}
    </div>
  );
};

const Profile = props => {
  useScrollToTop();
  const { user, setUser, usage, refreshUsage, plansInfo } = props;
  const [name, setName] = useState(user.name || "");
  const [isSavingImage, setIsSavingImage] = useState(false);
  const [isOpenImageModal, setIsOpenImageModal] = useState(false);
  const [theUser, setTheUser] = useState(user);
  const [isUpdating, setIsUpdating] = useState(false);

  const refreshUser = () => {
    client.service("users").get(user._id).then(setTheUser).catch(console.log);
  };

  useEffect(refreshUsage, []);

  useEffect(refreshUser, []);

  const update = () => {
    setIsUpdating(true);
    client
      .service("users")
      .patch(user._id, {
        name
      })
      .then(() => ellio.toaster.success("Updated profile successfully"))
      .catch(err => ellio.toaster.failure(err.message))
      .finally(() => setIsUpdating(false));
  };

  const changeProfileImage = () => {
    setIsOpenImageModal(true);
  };

  const onSaveImage = image => {
    setIsSavingImage(true);
    if (image) {
      return s3Upload({
        file: image,
        uuid: uuidv4()
      })
        .then(url => {
          setIsSavingImage(false);
          return client.service("users").patch(user._id, {
            settings: {
              ...user.settings,
              profileImageUrl: url
            }
          });
        })
        .then(u => {
          setIsOpenImageModal(false);
          ellio.toaster.success("Updated profile image successfully");
          setTheUser(u);
          return u;
        })
        .catch(err => {
          setIsSavingImage(false);
          ellio.toaster.failure(err.message);
        });
    }
  };

  return (
    <div className={cls.profileWrapper}>
      <div>
        <div className={cls.sectionHeader}>
          <h2 className={cls.sectionTitle}>Profile</h2>
        </div>
      </div>
      <div className={cls.profile}>
        <div className={cls.profileEdit}>
          <div className={cls.avatar}>
            {theUser?.settings?.profileImageUrl ? (
              <div
                className={imageModalCls.theImage}
                style={{
                  backgroundImage: `url(${theUser?.settings?.profileImageUrl})`
                }}
              ></div>
            ) : (
              <Avatar
                style={{ width: "120px", height: "120px" }}
                string={theUser.email}
                bgColor={theUser.settings.avatar.bgColor}
              />
            )}

            <div className={cls.picture}>
              <Tippy content="Change profile picture">
                <button
                  onClick={changeProfileImage}
                  className={buttonCls.button}
                >
                  <img draggable={false} src={picturePng} />
                </button>
              </Tippy>
            </div>
          </div>
          <div className={cls.profileInputs}>
            <input
              className={inputCls.textInput}
              placeholder="Name"
              value={name}
              onChange={e => setName(e.target.value)}
              type="text"
            />
            <input
              className={inputCls.textInput}
              type="text"
              value={user.email}
              readOnly
            />
            <button
              disabled={isUpdating}
              className={cls.updateProfileButton}
              onClick={update}
            >
              Update
            </button>
          </div>
        </div>
        {!isValidSubscription(user?.subscription) && (
          <div className={cls.profileUsage}>
            <h2 className={cls.sectionTitle}>Usage</h2>
            <Usage usage={usage} user={user} plansInfo={plansInfo} />
          </div>
        )}
      </div>
      {isOpenImageModal && (
        <ImageModal
          user={user}
          onClose={() => setIsOpenImageModal(false)}
          isProcessing={isSavingImage}
          onSaveImage={onSaveImage}
        />
      )}
    </div>
  );
};

const Plans = props => {
  const { user, setUser, manageBilling, plansInfo } = props;
  useScrollToTop();
  const [isYearly, setIsYearly] = useState(false);
  return (
    <div className={cls.plans}>
      <div className={cls.plansHeader}>
        <div>
          <h2 className={cls.sectionTitle}>Plans & subscription</h2>
        </div>
      </div>

      <div>
        <AllPlans
          manageBilling={manageBilling}
          user={user}
          setUser={setUser}
          plansInfo={plansInfo}
          isYearly={isYearly}
          setIsYearly={setIsYearly}
        />
      </div>
    </div>
  );
};

export default function Settings(props) {
  const {
    user,
    setUser,
    usage,
    refreshUsage,
    plansInfo,
    setIsAllowDrag
  } = props;

  const match = useRouteMatch();
  const location = useLocation();
  const subRoutes = routes.settings.subRoutes;
  const [isRedirecting, setIsRedirecting] = useState(false);

  const isOrgRoute =
    location.pathname === routes.settings.subRoutes.organization.url;

  useEffect(() => {
    setIsAllowDrag(false);
    return () => setIsAllowDrag(true);
  }, []);

  let active;

  Object.keys(subRoutes).forEach(k => {
    if (subRoutes[k].url === location.pathname) {
      active = {
        [k]: subRoutes[k]
      };
    }
  });

  const profile = pathTo(match, subRoutes.profile.url);
  const subscriptions = pathTo(match, subRoutes.subscriptions.url);
  const organization = pathTo(match, subRoutes.organization.url);

  const manageBilling = e => {
    setIsRedirecting(true);
    if (e?.preventDefault) {
      e.preventDefault();
    }
    client
      .service("stripe")
      .create({
        resource: "portal"
      })
      .then(r => {
        window.location.href = r.url;
      })
      .catch(console.log);
  };

  return (
    <div className={cls.settings}>
      <div className={pageCls.header}>
        <div className={pageCls.info}>
          <h1 className={pageCls.title}>Settings</h1>
        </div>
      </div>
      <div className={cls.inner}>
        <div className={cls.nav}>
          <ul className={cls.navList}>
            <li className={active?.profile ? cls.navActive : ""}>
              <ReactSVG
                className={[cls.icon, cls.invIcon].join(" ")}
                src={userSvg}
              />
              <Link className={cls.link} to={profile}>
                {subRoutes.profile.display}
              </Link>
              {active?.profile && (
                <img
                  className={cls.chevron}
                  draggable={false}
                  src={chevronRightSvg}
                />
              )}
            </li>

            <li className={active?.subscriptions ? cls.navActive : ""}>
              <ReactSVG className={cls.icon} src={subsSvg} />

              <Link className={cls.link} to={subscriptions}>
                {subRoutes.subscriptions.display}
              </Link>
              {active?.subscriptions && (
                <img
                  className={cls.chevron}
                  draggable={false}
                  src={chevronRightSvg}
                />
              )}
            </li>

            <li className={active?.organization ? cls.navActive : ""}>
              <ReactSVG className={cls.icon} src={orgSvg} />
              <Link
                className={cls.link}
                to={l => {
                  return organization;
                }}
              >
                {subRoutes.organization.display}
              </Link>
              {active?.organization && (
                <img
                  className={cls.chevron}
                  draggable={false}
                  src={chevronRightSvg}
                />
              )}
            </li>
          </ul>
        </div>
        <div className={k(cls.section, isOrgRoute ? cls.sectionNoBgImage : "")}>
          <Switch>
            <Route path={profile}>
              <Profile
                user={user}
                setUser={setUser}
                usage={usage}
                refreshUsage={refreshUsage}
                plansInfo={plansInfo}
              />
            </Route>

            <Route path={subscriptions}>
              <Plans
                manageBilling={manageBilling}
                user={user}
                setUser={setUser}
                plansInfo={plansInfo}
              />
            </Route>

            <Route path={organization}>
              <Team
                user={user}
                setUser={setUser}
                manageBilling={manageBilling}
              />
            </Route>

            <Redirect from={match.path} to={profile} />
          </Switch>
        </div>
      </div>
      <div className={cls.shieldBg}>
        {/*<img draggable={false} src={shieldBgPng} />*/}
      </div>
    </div>
  );
}
