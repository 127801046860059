import cls from "./studio.module.css";
import buttonCls from "common/button.module.css";
import { track, trackActions } from "ui-track";
import typeCls from "common/type.module.css";
import { EventManager } from "@axtk/event-manager";
import { urlToFile } from "common/blob";
import id from "pkgs/random/id";
import Select from "react-select";
import { useHotkeys } from "react-hotkeys-hook";
import ReactDOM from "react-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import Tippy from "@tippyjs/react";
import debounceFn from "debounce-fn";
import { ReactSVG } from "react-svg";
import { useState, useRef, useEffect, useCallback, useContext } from "react";
import { Button, Spinner } from "@blueprintjs/core";
import * as canvasUtil from "./canvas-util";
import FileSelect from "common/file-select";
import klass from "common/klass";
import fileSelectCls from "common/file-select/file-select.module.css";
import client from "common/client";
import UserCtx from "common/user.context";
import Fabric from "./fabric";
import readFiles from "common/read-files";
import useResizeCanvas from "./use-resize-canvas";
import useContextMenu from "./use-context-menu";
import useZoomAndPan from "./use-zoom-and-pan";
import useComment from "./use-comment";
import useDragToDrawLine from "./use-drag-to-draw-line";
import useTask from "./use-task";
import Task from "./task";
import useTaskDbclick from "./use-task-dbclick";
import useTaskPermission from "./use-task-permission";
import TaskModal from "./task-modal";
import useColorPicker from "./use-color-picker";
import Comment from "./comment";
import s3Upload from "common/s3/s3-upload";
import { v4 as uuidv4 } from "uuid";
import useAddAssetsToCanvas from "./use-add-assets-to-canvas";
import useTrackMouse from "./use-track-mouse";
import useSetBackground from "./use-set-background";
import useAnnotations from "./use-annotations";
import {
  useEve,
  newEve,
  studioAction,
  studioActionEvents,
  sendStudioAction,
  newEmitter
} from "common/eve";
import InviteUsers from "./invite-users";
import useSyncModels from "./use-sync-models2";
import updateModel, { handleUpdateModel } from "./update-model";
import useStudioActions from "./use-studio-actions2";
import useDragOver from "common/use-drag-over";
import useKeys from "./use-keys";
import personAddSvg from "./person-add.svg";
import addImageSvg from "./add-image.svg";
import crossSvg from "./cross.svg";
import arrowSvg from "./assets/arrow.svg";
import lineSvg from "./assets/line.svg";
import boxSvg from "./assets/box.svg";
import layersSvg from "./assets/layers.svg";
import downArrowSvg from "./assets/download.svg";
import downangleSvg from "./assets/downangle.svg";
import addCommentSvg from "./assets/comment.svg";
import addTaskSvg from "./assets/task.svg";
import addSvg from "./assets/add.svg";
import pointerSvg from "./assets/pointer.svg";
import assetsSvg from "./assets/assets.svg";
import textSvg from "./assets/text.svg";
import colorSvg from "./assets/color.svg";
import imageSvg from "./assets/image.svg";
import removeSvg from "./assets/remove.svg";
import groupSvg from "./assets/group.svg";
import ungroupSvg from "./assets/ungroup.svg";
import zoomSvg from "./assets/zoom.svg";
import pushToBottomSvg from "./assets/push-to-bottom.svg";
import bringToTopSvg from "./assets/bring-to-top.svg";
import jiraSvg from "./assets/jira.svg";
import shortcutSvg from "./assets/shortcut.svg";
import infoSvg from "./assets/info.svg";
import plugSvg from "./assets/plug.svg";
import closeSvg from "./close.svg";
import moreSvg from "./assets/more-vertical.svg";
import chevronRightSvg from "./assets/chevron-right.svg";
import chevronLeftSvg from "./assets/chevron-left.svg";
import Drawer from "./drawer";
import { popupwindow } from "common/iframe";
import {
  refreshExistingToken,
  getCloudInfo,
  syncWithJira
} from "common/jira-common";
import Avatar from "common/avatar";
import Hints, { useHints } from "./hints";
import StudioHints from "./studio-hints";
import Dropdown from "common/dropdown";
import JiraSyncModal from "./jira-sync-modal";
import ShortcutSyncModal from "./shortcut-sync-modal";

const Studio = props => {
  const {
    design,
    setDesign,
    refreshDesign,
    activeUsers,
    refreshActiveUsers,
    onOpenFileSelector,
    setIsAllowDrag,
    activeFolder,
    activeFolderImages,
    images,
    plansInfo,
    setUser
  } = props;

  const history = useHistory();
  const match = useRouteMatch();
  const user = useContext(UserCtx);
  const userId = user?._id;
  const [imagesList, setImagesList] = useState();
  const [isPrevDesign, setIsPrevDesign] = useState(false);
  const [isNextDesign, setIsNextDesign] = useState(false);

  useEffect(() => {
    if (activeFolder) {
      setImagesList(activeFolderImages);
    } else {
      setImagesList(images?.filter(v => !v.parent));
    }
  }, [activeFolder]);

  // useEffect(refreshDesign, [])

  // useEffect(() => {
  //   if (design) {
  //     refreshActiveUsers(design)
  //   }
  // }, [design])

  useEffect(() => {
    setTimeout(() => {
      if (imagesList?.length) {
        const currDesignIdx = imagesList?.findIndex(v => v._id === design._id);
        setIsPrevDesign(currDesignIdx - 1 >= 0);
        setIsNextDesign(currDesignIdx + 1 <= imagesList?.length - 1);
      }
    }, 0);
  }, [imagesList]);

  const annotation = design.annotation.model;
  const annotationId = String(design.annotation._id);
  const [canvas, setCanvas] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  const [assets, setAssets] = useState({ imgs: null, x: null, y: null });
  const mousePositionRef = useTrackMouse({ canvas });
  const annotationsSvc = useRef(client.service("annotations"));
  const { emit: emitAction, ref: studioActionsRef } = newEve("studio_action");
  const { emit: emitMouseMove, ref: mouseMoveEventRef } = newEve(
    "studio_mouse_move"
  );

  const [isBgChanging, setIsBgChanging] = useState(false);
  const [isPointerEvents, setIsPointerEvents] = useState(false);
  /* when anything is active on canvas, update this */
  const [activeObjects, setActiveObjects] = useState([]);
  /* side drawer for tasks/comments */
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [designAnnotation, setDesignAnnotation] = useState();
  const [isJiraConnected, setIsJiraConnected] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [openTask, setOpenTask] = useState(false);
  const [toolbarInner, setToolbarInner] = useState("");

  const [isInvitingUsers, setIsInvitingUsers] = useState(false);

  const [selectedProject, setSelectedProject] = useState();
  const [tasksToSyncWithJira, setTasksToSyncWithJira] = useState();
  const [isJiraSyncInProgress, setIsJiraSyncInProgress] = useState(false);
  const [isJiraSyncing, setIsJiraSyncing] = useState(false);

  /* shortcut sync */
  const [isShowSyncWithShortcut, setIsShowSyncWithShortcut] = useState(false);

  /* the state variable is to update the active state for the draw tool,
   * the ref is passed to fabric
   * this is not ideal ...
   */
  const [isDrawLineMode, setIsDrawLineMode] = useState(false);
  const canDrawLineRef = useRef(false);

  const [isHintsDismissed, setIshintDismissed] = useHints(user, "studio");

  // const [isHintsDismissed, setIshintDismissed] = useState(
  //   user.settings.isHintsDismissed == null
  //     ? false
  //     : user.settings.dismissedHints.studio
  // );

  useHotkeys(
    "escape",
    e => {
      if (tasksToSyncWithJira && isJiraSyncInProgress) {
        setTasksToSyncWithJira(null);
        setIsJiraSyncInProgress(false);
      }
      if (isInvitingUsers) {
        setIsInvitingUsers(false);
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"]
    },
    [tasksToSyncWithJira, isJiraSyncInProgress, isInvitingUsers]
  );

  useEffect(() => {
    setDesignAnnotation(design.annotation);
    client.service("annotations").on("patched", d => {
      if (d._id === annotationId) {
        setDesignAnnotation(d);
      }
    });

    return () => client.service("annotations").off("patched");
  }, []);

  /* refresh existing token */
  useEffect(() => {
    refreshExistingToken()
      .then(setIsJiraConnected)
      .catch(err => setIsJiraConnected(false));
  }, []);

  /* if annotation is set, it will initialize canvas with it */
  const bgImage = useAnnotations({ annotation, canvas, design, annotationId });
  // const [canvasTransform] = useResizeCanvas({ canvas, annotationId, bgImage });
  let canvasTransform = [];

  /* when images are selected, they are added to the canvas */
  useAddAssetsToCanvas({ canvas, assets, annotationId, emitAction, userId });

  /* if background is set, it will be set as canvas primary bg */
  useSetBackground({
    canvas,
    backgroundImage,
    emitAction
  });

  useZoomAndPan({
    canvas
  });

  /* hook up color picker */
  const { colorPicker, setColorPicker, colorPickerRef } = useColorPicker({
    studioActionsRef,
    emitAction,
    canvas
  });

  /* handler for adding images/assets to an existing canvas */
  const onAssetsSelect = (files, { x, y }) => {
    readFiles(files).then(imgs =>
      setAssets({
        imgs,
        x,
        y
      })
    );
  };

  /* hook up context menu */
  const { contextMenu, setContextMenu } = useContextMenu({
    canvas,
    setColorPicker,
    annotationId,
    studioActionsRef,
    emitAction,
    setOpenTask,
    onAssetsSelect
  });

  /* hook up the comments handler */
  useComment({
    canvas,
    annotationId,
    emitAction,
    userId
  });

  /* hook up task handler */
  // useTask({
  //   canvas,
  //   annotationId,
  //   emitAction,
  //   userId
  // });

  useTaskDbclick({
    canvas,
    openTask,
    setOpenTask,
    emitAction,
    annotationId,
    image: design,
    user
  });

  // useTaskPermission({
  //   canvas,
  //   userId,
  //   annotationId
  // });

  /* keyboard handlers */
  useKeys({
    canvas,
    mousePositionRef,
    studioActionsRef,
    emitAction,
    annotationId,
    setColorPicker,
    colorPicker,
    setContextMenu,
    contextMenu,
    setOpenTask,
    openTask
  });

  /* listen for updates to model to sync clients */
  useSyncModels({ canvas, annotationId, user, setIsSaving });

  /* handle studio actions */
  useStudioActions({
    design,
    plansInfo,
    canvas,
    annotationId,
    studioActionsRef,
    user,
    isPointerEvents,
    mouseMoveEventRef,
    emitMouseMove,
    setActiveObjects,
    emitAction,
    setIsSaving
  });

  /* when drag, draw a line */
  useDragToDrawLine({
    canvas,
    canDrawLineRef,
    setIsDrawLineMode,
    user,
    annotationId,
    emitAction
  });

  /* when is dragging over */
  // const [isDragOver] = useDragOver();

  /* handler for selecting the background for canvas */
  const onBackgroundSelect = files => {
    readFiles(files)
      .then(async imgs => {
        const img1 = imgs[0];
        setIsBgChanging(true);
        const url = await s3Upload(img1);
        setBackgroundImage({ ...img1, url, _id: design._id });
      })
      .then(() => {})
      .catch(console.log)
      .then(() => setIsBgChanging(false));
  };

  /* ui handlers */
  const addBox = () => {
    emitAction({
      name: studioActionEvents.addBox,
      x: mousePositionRef.current.x,
      y: mousePositionRef.current.y,
      canvas,
      annotationId
    });
  };

  const addText = () => {
    emitAction({
      name: studioActionEvents.addText,
      x: mousePositionRef.current.x,
      y: mousePositionRef.current.y,
      canvas,
      annotationId
    });
  };

  const addLine = () => {
    emitAction({
      name: studioActionEvents.addLine,
      x: mousePositionRef.current.x,
      y: mousePositionRef.current.y,
      canvas,
      annotationId
    });
  };

  const remove = () => {
    if (!canvas.getActiveObject()) {
      return;
    }
    emitAction({
      name: studioActionEvents.remove,
      canvas,
      annotationId
    });
  };

  const clear = () =>
    canvasUtil.clearAll({ canvas, isDeleteBackground: false });

  const group = () => {
    emitAction({
      name: studioActionEvents.group,
      canvas,
      annotationId,
      object: canvas.getActiveObject()
    });
  };

  const ungroup = () => {
    emitAction({
      name: studioActionEvents.ungroup,
      canvas,
      annotationId,
      object: canvas.getActiveObject()
    });
  };

  const addComment = () => {
    emitAction({
      name: studioActionEvents.addComment,
      x: mousePositionRef.current.x,
      y: mousePositionRef.current.y + 50,
      canvas,
      annotationId
    });
  };

  const addTask = () => {
    emitAction({
      name: studioActionEvents.addTask,
      x: mousePositionRef.current.x,
      y: mousePositionRef.current.y + 100,
      canvas,
      annotationId
    });
  };

  // const pickColor = () =>
  //   setColorPicker({
  //     isOpen: true,
  //     x: 0,
  //     y: 0,
  //     target: canvasUtil.lastObject({ canvas })
  //   });

  const pickColor = () => {
    if (!canvas.getActiveObject()) {
      return;
    }
    emitAction({
      name: studioActionEvents.pickColor,
      x: mousePositionRef.current.x,
      y: mousePositionRef.current.y + 50,
      object: canvas.getActiveObject(),
      canvas,
      setColorPicker,
      annotationId
    });
  };

  const extendCanvas = () => {
    const c = canvas;
    const w = c.get("width");
    const h = c.get("height");
    console.log({ w, h });
    const extendBy = 100;
    c.setDimensions({ width: w + extendBy, height: h + extendBy });
    // const bg = c.getObjects().find(v => v.metaProp?.type.includes('ellio_design'))
    c.getObjects().forEach(v => {
      // v.centerH();
      v.set({
        left: v.left + extendBy / 2,
        top: v.top + extendBy / 2
      });
      v.setCoords();
    });
    // bg.centerH();
    // bg.setCoords();
    c.renderAll();
  };

  const zoomFit = () => {
    canvas.setZoom(1);
    canvas.renderAll();
  };

  const downloadCanvas = () =>
    canvasUtil.downloadCanvas({ canvas, filename: "annotation.jpg" });

  const cropPortion = () => canvasUtil.cropPortion({ canvas });
  const logModel = () => {
    const model = canvasUtil.toObject({ canvas });
    console.log(model.objects.map(v => v.metaProp));

    // getCloudInfo(window.localStorage.getItem("jira_token")).then(console.log);
  };

  const connectJira = e => {
    return new Promise((resolve, reject) => {
      e?.preventDefault();
      let ref;

      const url = ellio.jiraCallback + `&state=${window.encodeURI(userId)}`;

      ref = popupwindow(url, 800, 800);

      /* listen for message from popup */
      window.addEventListener(
        "message",
        event => {
          /* e is an event that is sent from the popup window back here, that has
           * access token, cloudId etc.
           */
          if (event.origin !== window.origin) {
            return;
          }

          if (event.data.name !== "jira") {
            return;
          }

          window.localStorage.setItem("jira_token", event.data.token);
          window.localStorage.setItem(
            "jira_refresh_token",
            event.data.refreshToken
          );
          window.localStorage.setItem("jira_cloud_id", event.data.cloudId);
          window.localStorage.setItem(
            "jira_cloud_url",
            event.data.cloudInfo.url
          );
          setIsJiraConnected(true);

          ellio.toaster.success("Connected to Jira successfully!", {
            dismiss: 2000
          });

          resolve(window.localStorage.getItem("jira_token"));
        },
        false
      );
    });
  };

  const handleSyncToJira = async () => {
    track(trackActions.studio.startJira);
    setIsJiraSyncInProgress(true);
    const tasks = designAnnotation?.model?.objects.filter(v =>
      v.metaProp?.type.includes("task")
    );

    let jiraToken;

    try {
      jiraToken = await refreshExistingToken();
    } catch (err) {
      try {
        jiraToken = await connectJira();
      } catch (cError) {
        console.log(cError);
        setIsJiraSyncInProgress(false);
        return ellio.toaster.failure(
          "Couldn't connect to Jira, please refresh the page and try one more time."
        );
      }
    }

    const jiraProjects = await client.service("jira").find({
      query: {
        token: jiraToken,
        cloudId: window.localStorage.getItem("jira_cloud_id")
      }
    });

    const jiraProjectsOptions = jiraProjects.map(v => ({
      value: v.key,
      label: v.name
    }));

    const fields = {
      projectKey: selectedProject?.value,
      jiraProjects,
      jiraProjectsOptions,
      sTasks: tasks,
      selectedProject,
      designId: design._id,
      canvas,
      annotation,
      annotationId,
      userId,
      token: jiraToken
    };

    setTasksToSyncWithJira(fields);
  };

  const finishSyncToJira = async v => {
    const { isStory, storyTitle } = v;
    // setIsJiraSyncing(true);
    try {
      const canvasObjects = canvas.getObjects();

      const objectWithStory = canvasObjects.find(
        v => typeof v.metaProp?.jiraStory === "object"
      );

      await client.service("images").patch(design._id, {
        jiraProject: selectedProject
      });

      await refreshDesign().then(r => {
        if (r.jiraProject) {
          setSelectedProject(r.jiraProject);
        }
      });

      const r = await syncWithJira({
        ...tasksToSyncWithJira,
        projectKey: selectedProject?.value,
        story: objectWithStory ? objectWithStory.metaProp.jiraStory : null,
        storyTitle,
        isStory,
        design
      });

      return r;
      // setTasksToSyncWithJira(null);
    } catch (err) {
      console.log(err);
      ellio.toaster.failure(err.message);
      return Promise.reject(err);
    } finally {
      // setIsJiraSyncInProgress(false);
      // setIsJiraSyncing(false);
    }
  };

  const cancelSyncToJira = () => {
    setTasksToSyncWithJira(null);
    setIsJiraSyncInProgress(false);
  };

  const showHints = () => {
    setIshintDismissed(false);
  };

  const prevDesign = () => {
    const currIdx = imagesList?.findIndex(v => v._id === design._id);
    if (currIdx - 1 >= 0) {
      const prevDesignId = imagesList[currIdx - 1]._id;

      // client.service('images').get(prevDesignId).then(d => {
      //   setDesign(d);
      //   setDesignAnnotation(d.annotation)
      // }).then(() => {
      //   history.push(`/designs/${prevDesignId}`)
      // })
      location.href = `/designs/${prevDesignId}`;
    }
  };
  const nextDesign = () => {
    const currIdx = imagesList?.findIndex(v => v._id === design._id);
    if (currIdx + 1 <= imagesList?.length - 1) {
      const nextDesignId = imagesList[currIdx + 1]._id;

      // client.service('images').get(nextDesignId).then(d => {
      //   setDesign(d);
      //   setDesignAnnotation(d.annotation)
      // }).then(() => {
      //   history.push(`/designs/${nextDesignId}`)
      // })
      location.href = `/designs/${nextDesignId}`;
    }
  };

  return (
    <div className={cls.studio}>
      <Drawer
        design={design}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        annotation={designAnnotation}
        canvas={canvas}
        updateModel={updateModel}
        handleUpdateModel={handleUpdateModel}
        isJiraConnected={isJiraConnected}
        setIsJiraConnected={setIsJiraConnected}
        userId={userId}
        connectJira={connectJira}
        onProjectSelect={v => setSelectedProject(v)}
      />
      <div className={cls.canvasWrapper}>
        <div className={cls.toolbar}>
          <div className={cls.toolbarInner} ref={setToolbarInner}>
            <div className={cls.toolbarLeft}>
              <p className={[typeCls.p2, "abs"].join(" ")}>
                {isSaving ? "Saving ..." : "Saved"}
              </p>
            </div>
            <div className={cls.toolbarMid}>
              <Tippy content="Log Model">
                <div
                  style={{
                    display: "none"
                  }}
                  onClick={logModel}
                  className={cls.tool}
                >
                  <ReactSVG src={zoomSvg} />
                </div>
              </Tippy>

              <Tippy content="Show tips">
                <div onClick={showHints} className={cls.tool}>
                  <ReactSVG src={infoSvg} />
                </div>
              </Tippy>

              <Tippy content="Draw line">
                <div
                  onClick={() => {
                    track(trackActions.studio.drawLine);
                    setIsDrawLineMode(p => {
                      canDrawLineRef.current = !p;
                      return !p;
                    });
                  }}
                  className={klass([
                    cls.tool,
                    isDrawLineMode ? cls.activeTool : ""
                  ])}
                >
                  <ReactSVG src={lineSvg} />
                </div>
              </Tippy>

              <Tippy content="Tasks and comments">
                <div
                  style={{ display: "none" }}
                  onClick={() => setIsDrawerOpen(p => !p)}
                  className={klass([
                    cls.tool,
                    isDrawerOpen ? cls.activeTool : ""
                  ])}
                >
                  <ReactSVG src={layersSvg} />
                </div>
              </Tippy>

              {isJiraSyncInProgress ? (
                <div className={klass([cls.tool, "el-spinner"])}></div>
              ) : (
                <Tippy content="Sync with Jira">
                  <div className={klass([cls.tool, cls.destination])}>
                    <button
                      disabled={
                        !["Teams", "Pro"].includes(user?.subscription?.name)
                      }
                      onClick={handleSyncToJira}
                    >
                      <img draggable={false} src={jiraSvg} alt="Jira" />
                    </button>
                  </div>
                </Tippy>
              )}

              <Tippy content="Sync with Shortcut">
                <div className={klass([cls.tool, cls.destination])}>
                  <button
                    disabled={
                      !["Teams", "Pro"].includes(user?.subscription?.name)
                    }
                    onClick={() => {
                      // () => makeShortcutStory(canvas)
                      setIsShowSyncWithShortcut(true);
                    }}
                  >
                    <img draggable={false} src={shortcutSvg} alt="Shortcut" />
                  </button>
                </div>
              </Tippy>

              <Dropdown
                className="studio-extra-options"
                trigger={
                  <Tippy content="More options">
                    <button className={buttonCls.button}>
                      <img draggable={false} src={moreSvg} />
                    </button>
                  </Tippy>
                }
              >
                <div className="my-menu">
                  {/*                  <div className="menu-row">
                    <div className="menu-row-text">
                      <button
                        onClick={showHints}
                        className={buttonCls.buttonSm}
                      >
                        <ReactSVG className="menu-row-icon" src={infoSvg} />
                        Show tips
                      </button>
                    </div>
                  </div>*/}

                  <div className="menu-row">
                    <div className="menu-row-text">
                      <button
                        onClick={onOpenFileSelector}
                        className={buttonCls.buttonSm}
                      >
                        <ReactSVG className="menu-row-icon" src={addImageSvg} />
                        Upload designs
                      </button>
                    </div>
                  </div>

                  <div className="menu-row">
                    <div className="menu-row-text">
                      <button
                        onClick={() => {
                          if (["Teams"].includes(user?.subscription?.name)) {
                            setIsInvitingUsers(true);
                          } else {
                            ellio.toaster.warning(
                              "Sorry, the sharing feature is not included in your current plan, please upgrade to a different plan",
                              {
                                title: "Oops..."
                              }
                            );
                          }
                        }}
                        className={buttonCls.buttonSm}
                      >
                        <ReactSVG
                          className="menu-row-icon"
                          src={personAddSvg}
                        />
                        Collaborators
                      </button>
                    </div>
                  </div>

                  <div style={{ display: "none" }} className="menu-row">
                    <div className="menu-row-text">
                      <button
                        onClick={cropPortion}
                        className={buttonCls.buttonSm}
                      >
                        <ReactSVG
                          className="menu-row-icon"
                          src={downangleSvg}
                        />
                        Crop boxes
                      </button>
                    </div>
                  </div>

                  <div className="menu-row">
                    <div className="menu-row-text">
                      <button
                        onClick={downloadCanvas}
                        className={buttonCls.buttonSm}
                      >
                        <ReactSVG
                          className="menu-row-icon"
                          src={downArrowSvg}
                        />
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </Dropdown>

              <Tippy content="Connect to Jira">
                <div
                  className={klass([cls.tool, cls.jira])}
                  style={{
                    display: "none"
                  }}
                >
                  <button onClick={connectJira}>
                    <img src={plugSvg} alt="Connect" />
                  </button>
                </div>
              </Tippy>

              <Tippy
                content={
                  isPointerEvents ? "Disable pointer" : "Collaborative pointer"
                }
              >
                <div
                  style={{ display: "none" }}
                  className={klass([
                    cls.tool,
                    isPointerEvents ? cls.activeTool : ""
                  ])}
                  onClick={() => setIsPointerEvents(p => !p)}
                >
                  <ReactSVG src={pointerSvg} />
                </div>
              </Tippy>

              <Tippy
                onShow={() => activeObjects?.length}
                content="Bring to top"
              >
                <div
                  onClick={() => {
                    canvas.bringToFront(canvas.getActiveObject()).renderAll();
                  }}
                  className={klass([
                    cls.tool,
                    activeObjects?.length === 0 ? cls.hide : ""
                  ])}
                >
                  <ReactSVG src={bringToTopSvg} />
                </div>
              </Tippy>

              <Tippy
                onShow={() => activeObjects?.length}
                content="Push to bottom"
              >
                <div
                  style={{ display: "none" }}
                  onClick={() => {
                    canvas.sendBackwards(canvas.getActiveObject());
                  }}
                  className={klass([
                    cls.tool,
                    activeObjects?.length === 0 ? cls.hide : ""
                  ])}
                >
                  <ReactSVG src={pushToBottomSvg} />
                </div>
              </Tippy>

              <Tippy content="Add box">
                <div
                  onClick={addBox}
                  className={cls.tool}
                  style={{ display: "none" }}
                >
                  <ReactSVG src={boxSvg} />
                </div>
              </Tippy>

              <Tippy content="Add comment">
                <div
                  onClick={addComment}
                  className={cls.tool}
                  style={{ display: "none" }}
                >
                  <ReactSVG src={addCommentSvg} />
                </div>
              </Tippy>

              <Tippy content="Extend canvas">
                <div
                  style={{ display: "none" }}
                  onClick={extendCanvas}
                  className={cls.tool}
                >
                  <ReactSVG src={addCommentSvg} />
                </div>
              </Tippy>

              <Tippy content="Zoom fit">
                <div
                  style={{ display: "none" }}
                  onClick={zoomFit}
                  className={cls.tool}
                >
                  <ReactSVG src={addTaskSvg} />
                </div>
              </Tippy>

              <Tippy content="Add task">
                <div
                  onClick={addTask}
                  className={cls.tool}
                  style={{ display: "none" }}
                >
                  <ReactSVG src={addTaskSvg} />
                </div>
              </Tippy>

              <Tippy content="Add text">
                <div
                  style={{ display: "none" }}
                  onClick={addText}
                  className={cls.tool}
                >
                  <ReactSVG src={textSvg} />
                </div>
              </Tippy>

              <Tippy content="Change color">
                <div
                  onClick={pickColor}
                  style={{ display: "none" }}
                  className={cls.tool}
                >
                  <ReactSVG src={colorSvg} />
                </div>
              </Tippy>

              <Tippy content="Group">
                <div
                  onClick={group}
                  style={{ display: "none" }}
                  className={cls.tool}
                >
                  <ReactSVG src={groupSvg} />
                </div>
              </Tippy>

              <Tippy content="Ungroup">
                <div
                  onClick={ungroup}
                  style={{ display: "none" }}
                  className={cls.tool}
                >
                  <ReactSVG src={ungroupSvg} />
                </div>
              </Tippy>

              <Tippy content="Download boxes">
                <div
                  style={{ display: "none" }}
                  onClick={cropPortion}
                  className={cls.tool}
                >
                  <ReactSVG src={downangleSvg} />
                </div>
              </Tippy>

              <Tippy content="Export to file">
                <div
                  style={{ display: "none" }}
                  onClick={downloadCanvas}
                  className={cls.tool}
                >
                  <ReactSVG src={downArrowSvg} />
                </div>
              </Tippy>

              <Tippy content="Change Image">
                <div style={{ display: "none" }} className={cls.tool}>
                  <FileSelect
                    isOpen={true}
                    onDrop={onBackgroundSelect}
                    maxSize={10}
                    dropArea={() => (
                      <div>
                        <ReactSVG src={imageSvg} />
                      </div>
                    )}
                  />
                </div>
              </Tippy>

              <Tippy content="Add images">
                <div className={cls.tool} style={{ display: "none" }}>
                  <FileSelect
                    isOpen={true}
                    onDrop={onAssetsSelect}
                    maxSize={1}
                    dropArea={() => (
                      <div>
                        <ReactSVG src={assetsSvg} />
                      </div>
                    )}
                  />
                </div>
              </Tippy>

              <Tippy
                onShow={() => activeObjects?.length}
                content="Remove selected items"
              >
                <div
                  onClick={remove}
                  className={klass([
                    cls.tool,
                    activeObjects?.length === 0 ? cls.hide : ""
                  ])}
                >
                  <ReactSVG src={removeSvg} />
                </div>
              </Tippy>
            </div>
            <div className={cls.toolbarRight}>
              <div className={cls.activeUsers}>
                {activeUsers?.map((active, i) => (
                  <div key={`profile_${active.userId}`}>
                    {active.profileImageUrl ? (
                      <div
                        className={cls.activeProfileImage}
                        style={{
                          left: `${i * 15 * -1}px`,
                          backgroundImage: `url(${active.profileImageUrl})`
                        }}
                      ></div>
                    ) : (
                      <>
                        <div
                          key={`avatar_${active.userId}`}
                          className={cls.activeAvatar}
                          style={{ left: `${i * 15 * -1}px` }}
                        >
                          <Avatar
                            style={{ width: "40px", height: "40px" }}
                            string={active.userEmail}
                            bgColor={active?.avatar?.bgColor}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <Fabric hidden={true} onInit={setCanvas} />

        <div
          className={cls.chevron}
          style={{
            display: openTask || !isNextDesign ? "none" : "block"
          }}
        >
          <Tippy content="Next design">
            <button onClick={nextDesign} className={cls.button}>
              <img draggable={false} src={chevronRightSvg} />
            </button>
          </Tippy>
        </div>

        <div
          className={cls.chevronLeft}
          style={{
            display: openTask || !isPrevDesign ? "none" : "block"
          }}
        >
          <Tippy content="Previous design">
            <button onClick={prevDesign} className={cls.button}>
              <img draggable={false} src={chevronLeftSvg} />
            </button>
          </Tippy>
        </div>

        <div
          className={klass([
            cls.imageSpinner,
            !isBgChanging ? cls.hide : cls.show,
            bgImage ? cls.hide : cls.show
          ])}
        >
          <Spinner intent="primary" />
        </div>

        {isInvitingUsers ? (
          <InviteUsers
            user={user}
            image={design}
            onClose={() => {
              setIsInvitingUsers(false);
            }}
          />
        ) : null}

        {openTask ? (
          <TaskModal
            setIsAllowDrag={setIsAllowDrag}
            canvasTransform={canvasTransform}
            annotationId={annotationId}
            canvas={canvas}
            emitAction={emitAction}
            setOpenTask={setOpenTask}
            openTask={openTask}
            userId={userId}
            annotation={annotation}
          />
        ) : null}

        {tasksToSyncWithJira ? (
          <JiraSyncModal
            setSelectedProject={setSelectedProject}
            selectedProject={selectedProject}
            options={tasksToSyncWithJira.jiraProjectsOptions}
            onClose={cancelSyncToJira}
            onSync={v => {
              return finishSyncToJira(v);
            }}
            isProcessing={isJiraSyncing}
            setIsProcessing={setIsJiraSyncing}
            canvasObjects={canvas.getObjects()}
            design={design}
          />
        ) : null}

        {isShowSyncWithShortcut && (
          <ShortcutSyncModal
            user={user}
            setUser={setUser}
            designAnnotation={designAnnotation}
            canvas={canvas}
            design={design}
            onClose={() => setIsShowSyncWithShortcut(false)}
          />
        )}

        {!isHintsDismissed ? (
          <Hints
            section="studio"
            isHintsDismissed={isHintsDismissed}
            setIshintDismissed={setIshintDismissed}
            user={user}
          >
            <StudioHints />
          </Hints>
        ) : null}
      </div>
    </div>
  );
};

export default Studio;
