import { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import cls from "./error.module.css";
import buttonCls from "common/button.module.css";

let errorCache = {};

export function useException({ userId }) {
  const [exception, setException] = useState();
  useEffect(() => {
    window.onerror = (message, source, lineno, colno, error) => {
      if (Object.keys(errorCache).length > 100) {
        errorCache = {};
      }
      const key = `${lineno}_${colno}:${message}`;
      const alreadyLogged = errorCache[key] === true;
      if (!alreadyLogged) {
        errorCache[key] = true;
        const page = window.currentActivePath || window.location.pathname;
        const newError = {
          timestamp: new Date().toISOString(),
          level: "error",
          message: `${message}:\n${error?.stack}`,
          meta: { label: "ui", page: page },
          userId
        };
        setException(newError);
      }
    };
  }, []);
  return [exception, setException];
}

export default function Boundary(props) {
  const { children, exception } = props;

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role="alert" className={cls.error}>
          <h2>Oops ...</h2>
          <p>
            It looks like you have found a bug on the front-end. Don’t worry,
            you can click on the button below to restore the app. We are
            notified of the issue and we will be looking into it.
          </p>

          {/*
            <pre>{error.message}</pre>
            <pre>{exception?.message}</pre>
            */}
          <button
            className={buttonCls.primary}
            onClick={() => {
              // this next line is why the fallbackRender is useful
              // resetComponentState();
              // though you could accomplish this with a combination
              // of the FallbackCallback and onReset props as well.
              resetErrorBoundary();
            }}
          >
            Restore
          </button>
        </div>
      )}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

// export { ErrorBoundary } from "react-error-boundary";
