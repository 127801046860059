import cls from "./hints.module.css";
import typeCls from "common/type.module.css";
import Video from "common/video";
import doubleClickSvg from "./assets/double-click.svg";
import rightClickSvg from "./assets/right-click.svg";
import multiSelectWebm from "./multi-select.webm";
import multiSelectMp4 from "./multi-select.mp4";

export default function DesignsHints() {
  return (
    <>
      <div className={cls.hint}>
        <div className={cls.hintInner}>
          <h2 className={typeCls.ctrl}>Shift + click</h2>
          <p className={typeCls.p3}>
            After an item is selected, you can shift click to multi select
            images or folders
          </p>
          <Video mp4={multiSelectMp4} webm={multiSelectWebm} />
        </div>
      </div>
    </>
  );
}

/*
<video loop={true} autoPlay={true} width="100%">
  <source src={multiSelectWebm} type="video/webm" />

  <source src={multiSelectMp4} type="video/mp4" />
</video>
*/
