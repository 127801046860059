import "./dropdown.css";
import Tippy from "@tippyjs/react";
import klass from "common/klass";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import buttonCls from "common/button.module.css";

export default function Dropdown(props) {
  const {
    className,
    style = {},
    children,
    trigger,
    placement = "bottom-end",
    animation = false,
    arrow = false,
    autoClose = true
  } = props;

  const [instance, setInstance] = useState();

  const close = e => {
    instance.hide();
  };

  return (
    <div style={style} className={klass([className])}>
      <Tippy
        arrow={arrow}
        interactive={true}
        theme="ellio"
        animation={animation}
        placement={placement}
        trigger="click"
        onCreate={setInstance}
        content={
          <div
            onClick={e => {
              if (autoClose) {
                close();
              }
            }}
          >
            {children}
          </div>
        }
      >
        {trigger}
      </Tippy>
    </div>
  );
}
