/**
 * Usage: wrap `<WithAnimation>` around an element you want to
 * animate, and pass it an anime animation object.
 * ## Example
 *
 *  ```js
 *  <WithAnimation animation={appear}>
 *    <div />
 *  </WithAnimation
 * ```
 * you can also pass it an `animationOptions` prop to have more control over
 * the animation.
 */
import React, { useRef } from "react";
import useAnimeOnMount from "./use-anime-mount";

function WithAnimation(props) {
  const nodeRef = useRef(null);
  const {
    animation,
    animationOptions,
    watchList,
    style,
    initialStyles,
    className,
    children
  } = props;

  useAnimeOnMount({
    ref: nodeRef,
    def: animation,
    animationOptions: animationOptions,
    watchList: watchList
  });

  const styles = style || {};
  const baseStyles = initialStyles || {};

  return (
    <div
      className={className ? className : ""}
      ref={nodeRef}
      style={{ ...baseStyles, ...styles }}
    >
      {children}
    </div>
  );
}

export default WithAnimation;
