const { NODE_ENV } = process.env;
const isProd = NODE_ENV === "production";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export default function init() {
  Sentry.init({
    dsn:
      "https://2068eb1c8ae941d09fbf4ce4d2ace8ad@o1023453.ingest.sentry.io/5989762",
    integrations: [new Integrations.BrowserTracing()],
    environment: isProd ? "production" : "local"

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    // tracesSampleRate: 1.0
  });
}
