import { useEffect, useState } from "react";
import * as canvasUtil from "./canvas-util";
import updateModel from "studio/update-model";

const useAnnotations = opts => {
  const {
    canvas,
    annotation,
    canvasWidth = canvasUtil.defaultCanvasWidth,
    design,
    annotationId
  } = opts;

  const [bgImage, setBgImage] = useState();

  useEffect(() => {
    if (canvas && annotation && design) {
      canvasUtil
        .loadModel({ model: annotation, canvas, design })
        .then(({ image }) => {
          setBgImage(image);

          // const containerWidth = canvasUtil.getCanvasContainerWidth();
          const containerWidth = canvasUtil.defaultCanvasWidth;

          const { width, height } = canvasUtil.setCanvasDimensions({
            canvas,
            image,
            canvasWidth: containerWidth
          });

          if (image.metaProp?.transform) {
            canvas.setViewportTransform(image.metaProp.transform);
          }

          if (image.width > containerWidth) {
            image.scaleToWidth(containerWidth);
          }

          canvas.renderAll();

          /* call this mostly for the case when the ellio_design node is
           * generated asynchronously with a queue and when the annotation model
           * was not set at the time of creation (eg images from figma frames)
           */
          updateModel({ canvas, annotationId });
        });
    }
  }, [canvas, annotation, design]);

  return bgImage;
};

export default useAnnotations;
