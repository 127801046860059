import { studioActionEvents } from "common/eve";

const keys = {
  [studioActionEvents.addBox]: {
    key: "b",
    label: "B",
    modifiers: ["option"]
  },
  [studioActionEvents.addComment]: {
    key: "c",
    label: "C",
    modifiers: ["option"]
  },
  [studioActionEvents.addTask]: {
    key: "t",
    label: "T",
    modifiers: ["option"]
  },
  [studioActionEvents.addText]: {
    key: "e",
    label: "E",
    modifiers: ["option"]
  },
  [studioActionEvents.addLine]: {
    key: "l",
    label: "L",
    modifiers: ["option"]
  },
  [studioActionEvents.pickColor]: {
    key: "k",
    label: "K",
    modifiers: ["option"]
  },
  [studioActionEvents.group]: {
    key: "g",
    label: "G",
    modifiers: ["option"]
  },
  [studioActionEvents.ungroup]: {
    key: "g",
    label: "G",
    modifiers: ["option"],
    isShift: true
  }
};

const combination = action => {
  return keys[action].modifiers
    .map(m => `${m}+${keys[action].isShift ? "shift+" : ""}${keys[action].key}`)
    .join(",");
};

export default keys;
export { combination };
