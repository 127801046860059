import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import * as canvasUtil from "./canvas-util";
import ContextMenu from "./context-menu";

const useContextMenu = opts => {
  const {
    canvas,
    setColorPicker,
    annotationId,
    emitAction,
    setOpenTask,
    onAssetsSelect
  } = opts;
  const contextMenuRef = useRef();
  const [contextMenu, setContextMenu] = useState({
    isOpen: false,
    x: null,
    y: null,
    target: null,
    event: null
  });

  useEffect(() => {
    if (canvas) {
      canvasUtil.addContextMenuHandler({
        canvas,
        setContextMenu
      });
    }
  }, [canvas]);

  useEffect(() => {
    if (canvas) {
      if (contextMenu.isOpen) {
        const parent = canvas.node.parentElement;
        const { x, y, target } = contextMenu;
        const div = document.createElement("div");
        div.id = `canvas_menu_${canvas.id}`;
        div.addEventListener(
          "contextmenu",
          function (e) {
            e.preventDefault();
          },
          false
        );

        /* close context menu if any click inside the context */
        div.addEventListener("click", e => {
          setContextMenu(p => ({ ...p, isOpen: false }));
        });

        if (!contextMenuRef.current) {
          parent.appendChild(div);
          ReactDOM.render(
            <ContextMenu
              x={x}
              y={y}
              target={target}
              canvas={canvas}
              remove={canvasUtil.remove}
              setColorPicker={setColorPicker}
              annotationId={annotationId}
              emitAction={emitAction}
              setOpenTask={setOpenTask}
              onAssetsSelect={onAssetsSelect}
            />,
            div
          );
        }
        contextMenuRef.current = div;
      } else {
        const cId = `#canvas_menu_${canvas.id}`;
        const contextMenuWrapper = document.querySelector(cId);
        if (contextMenuWrapper) {
          contextMenuWrapper.remove();
          contextMenuRef.current = null;
        }
      }
    }
  }, [contextMenu]);

  return {
    contextMenu,
    setContextMenu
  };
};

export default useContextMenu;
