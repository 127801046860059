import { useState, useEffect, useRef, useContext, useCallback } from "react";
import id from "pkgs/random/id";
import { trackActions, track } from "ui-track";
import Boundary, { useException } from "common/error";
import { ReactSVG } from "react-svg";
import { globalEvent, useListener } from "common/eve";
import Tippy from "@tippyjs/react";
import { useHotkeys } from "react-hotkeys-hook";
import moreSvg from "./more-vertical.svg";
import buttonCls from "common/button.module.css";
import typeCls from "common/type.module.css";
import fileSelectCls from "common/file-select/file-select.module.css";
import pageCls from "common/page.module.css";
import galleryCls from "./gallery.module.css";
import designsCls from "./designs.module.css";
import klass from "common/klass";
import { useHistory } from "react-router-dom";
import { Button, Spinner } from "@blueprintjs/core";
import deepcopy from "deepcopy";
import UserCtx from "common/user.context";
import client from "common/client";
import readFiles from "common/read-files";
import FileSelect from "common/file-select";
import Editable from "common/editable";
import * as canvasUtil from "studio/canvas-util";
import Fabric from "studio/fabric";
import s3Upload from "common/s3/s3-upload";
import useDragOver from "common/use-drag-over";
import DesignPreview from "./design-preview";
import cloudPng from "./cloud.png";
import appear from "common/animations/appear";
import WithAnimation from "common/animations/with-animation";
import personAddSvg from "./person-add.svg";
import bigPlusSvg from "./big-plus.svg";
import addImageSvg from "./add-image.svg";
import folderAddSvg from "./folder-add.svg";
import jiraSvg from "./jira.svg";
import NewFolderModal from "./new-folder-modal";
import DeleteFolderModal from "./delete-folder-modal";
import Folder from "./folder";
import trashSvg from "./trash3.svg";
import DeleteDesignsModal from "./delete-designs-modal";
import DeleteFoldersModal from "./delete-folders-modal";
import folderSvg from "./folder.svg";
import deselectSvg from "./deselect.svg";
import MoveToFolderModal from "./move-to-folder-modal";
import ImportFromFigmaModal from "./import-from-figma-modal";
import { connectFigma, fileIdFromUrl } from "common/figma-common";
import arrowLeftSvg from "./arrowleft.svg";
import noTasksPng from "./no-tasks.png";
import multiSelect from "common/multi-select";
import Hints, { useHints } from "studio/hints";
import DesignsHints from "studio/designs-hints";
import DesignsDragHints from "studio/designs-drag-hints";
import infoSvg from "./info.svg";
import figmaSvg from "./figma.svg";
import InviteToFolder from "./invite-to-folder";
import { connectJira } from "common/jira-common";
import JiraTicketsFromFolderModal from "./jira-tickets-from-folder-modal";
import Dropdown from "common/dropdown";

function Designs(props) {
  const {
    globalEveRef,
    onOpenFileSelector,
    imagesUploading,
    images,
    setImages,
    imagesError,
    deleteImage,
    deleteImages,
    refreshImages,
    folders,
    createFolder,
    refreshFolders,
    deleteFolder,
    deleteFolders,
    foldersError,
    activeFolder,
    setActiveFolder,
    activeFolderImages,
    activeImagesLoading,
    isUploadInProgress,
    setUser
  } = props;

  const [isStartNewFolder, setIsStartNewFolder] = useState(false);
  const [isNewFolderProgress, setIsNewFolderProgress] = useState(false);

  const imagesUploadingCount = Object.keys(imagesUploading).length;

  const history = useHistory();
  const user = useContext(UserCtx);
  const userId = user._id;

  const [selectedDesigns, setSelectedDesigns] = useState([]);

  const [isMultiDelete, setIsMultiDelete] = useState(false);
  const [isStartMultiDelete, setIsStartMultiDelete] = useState(false);

  const deleteSelectedImages = () => {
    return deleteImages(selectedDesigns).then(r => {
      refreshImages();
    });
  };

  useEffect(refreshImages, []);
  useEffect(refreshFolders, []);

  const [isStartMove, setIsStartMove] = useState(false);
  const [isMoveProcess, setIsMoveProcess] = useState(false);

  const moveDesignsToFolder = async folder => {
    let folderId;
    if (typeof folder === "object") {
      folderId = folder.value;
    } else {
      const folderResp = await client.service("folders").create({
        name: folder
      });
      folderId = folderResp._id;
    }

    const folderInDb = await client.service("folders").get(folderId);

    return client
      .service("images")
      .patch(
        null,
        {
          parent: folderId,
          collaborators: folderInDb.collaborators
        },
        {
          query: {
            _id: {
              $in: selectedDesigns.map(v => v._id)
            }
          }
        }
      )
      .then(r => {
        setSelectedDesigns([]);
        setActiveFolder(null);
        ellio.toaster.success("Move images to folder successfully!", {
          dismiss: 2500
        });
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
      })
      .then(refreshImages);
  };

  const [isStartDeleteFolder, setIsStartDeleteFolder] = useState(false);
  const [isDeletingFolder, setIsDeletingFolder] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState();

  const [isMultiDeleteFolders, setIsMultiDeleteFolders] = useState(false);
  const [isStartMultiDeleteFolders, setIsStartMultiDeleteFolders] = useState(
    false
  );

  const deleteSelectedFolders = () => {
    return deleteFolders(selectedFolders);
  };

  const imagesService = useRef(client.service("images"));

  useListener(globalEveRef, "global", v => {
    console.log("global event", v);
  });

  const userFolders = folders;

  const imagesNoParent = images?.filter(img => !img.parent);

  const imagesList = activeFolder ? activeFolderImages : imagesNoParent;

  const selectDesign = (image, isShiftKey) => {
    if (isShiftKey) {
      const toSelect = multiSelect(imagesList, image, selectedDesigns);
      setSelectedDesigns(toSelect);
    } else {
      if (selectedDesigns.find(im => im._id === image._id)) {
        setSelectedDesigns(p => p.filter(im => im._id !== image._id));
      } else {
        setSelectedDesigns(p => [...p, image]);
      }
    }
  };

  const [selectedFolders, setSelectedFolders] = useState([]);

  const updateSelectedFolders = (folder, isAdd, isShiftKey) => {
    if (isAdd) {
      if (isShiftKey) {
        const revList = userFolders;
        const toSelect = multiSelect(revList, folder, selectedFolders);
        setSelectedFolders(toSelect);
      } else {
        setSelectedFolders(p => [...p, folder]);
      }
    } else {
      setSelectedFolders(p => p.filter(f => f._id !== folder._id));
    }
  };

  const canSelectFolder = Boolean(selectedDesigns?.length === 0);
  const canSelectDesign = Boolean(selectedFolders?.length === 0);

  const [isHintsDismissed, setIshintDismissed] = useHints(user, "designs");
  const [isDragHintsDismissed, setIsDragHintsDismissed] = useHints(
    user,
    "designs_drag"
  );

  const [isInvitingUsers, setIsInvitingUsers] = useState(false);
  const [folderToInvite, setFolderToInvite] = useState();

  /* figma import modal */
  const [isStartFigmaImport, setIsStartFigmaImport] = useState(false);
  const [isFigmaImportProcessing, setIsFigmaImportProcessing] = useState(false);

  const onFigmaImport = useCallback(async v => {
    const { fileId, nodesIds } = v;

    return connectFigma({ userId })
      .then(token => {
        if (token) {
          return client
            .service("figma")
            .create(
              {
                fileId,
                nodesIds,
                activeFolder
              },
              {
                query: {
                  resource: "images-from-frame",
                  token
                }
              }
            )
            .then(v => v)
            .catch(e => Promise.resolve(""));
        }

        return Promise.resolve("");
      })
      .catch(err => {
        console.log(err);
        return Promise.resolve("");
      });
  }, []);

  const [jiraTicketsFromFolder, setJiraTicketsFromFolder] = useState({
    isStart: false,
    isProcessing: false
  });

  const [selectedJiraFolder, setSelectedJiraFolder] = useState();

  const onCreateJiraTicketsFromFolder = async v => {
    const { folder, jiraProject, ellioUrlPrefix, isStory, isSubTasks } = v;
    const token = window.localStorage.getItem("jira_token");
    const cloudId = window.localStorage.getItem("jira_cloud_id");
    const cloudUrl = window.localStorage.getItem("jira_cloud_url");

    return client.service("jira").create({
      token,
      cloudId,
      folderJob: {
        folder,
        projectKey: jiraProject.value,
        jiraProject,
        ellioUrlPrefix,
        cloudUrl,
        isStory,
        isSubTasks
      }
    });
  };

  useHotkeys(
    "escape",
    e => {
      if (isStartDeleteFolder) {
        setIsStartDeleteFolder(false);
      }

      if (isStartMultiDelete) {
        setIsStartMultiDelete(false);
      }

      if (isStartMultiDeleteFolders) {
        setIsStartMultiDeleteFolders(false);
      }

      if (isInvitingUsers) {
        setIsInvitingUsers(false);
      }

      if (isStartFigmaImport) {
        setIsStartFigmaImport(false);
      }

      if (selectedJiraFolder) {
        setSelectedJiraFolder(false);
      }

      if (isStartMove) {
        setIsStartMove(false);
      }

      if (isStartNewFolder) {
        setIsStartNewFolder(false);
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"]
    },
    [
      isStartDeleteFolder,
      isStartMultiDelete,
      isStartMultiDeleteFolders,
      isInvitingUsers,
      isStartFigmaImport,
      selectedJiraFolder,
      isStartMove,
      isStartNewFolder
    ]
  );

  if (imagesError) {
    return <div>{imagesError.message}</div>;
  }

  if (foldersError) {
    return <div>{foldersError.message}</div>;
  }

  if (!images || !folders) {
    return <div>Loading ...</div>;
  }

  return (
    <div>
      <div className={pageCls.header}>
        <div className={pageCls.info}>
          <h1 className={pageCls.title}>Designs</h1>
        </div>

        <section>
          <div
            style={{
              display: selectedFolders?.length > 0 ? "none" : "flex"
            }}
            className={klass([
              designsCls.multiSelectOptions,
              selectedDesigns.length ? designsCls.multiSelectOptionsVisible : ""
            ])}
          >
            <Tippy
              onShow={() => selectedDesigns?.length > 0}
              content="Show tips"
            >
              <div>
                <button
                  className={klass([buttonCls.button, designsCls.showTips])}
                  onClick={() => {
                    setIshintDismissed(false);
                  }}
                >
                  <ReactSVG src={infoSvg} />
                </button>
              </div>
            </Tippy>
            <Tippy
              onShow={() => selectedDesigns?.length > 0}
              content="Delete images"
            >
              <div>
                <button
                  className={klass([
                    buttonCls.button,
                    designsCls.deleteSelected
                  ])}
                  onClick={() => {
                    setIsStartMultiDelete(true);
                  }}
                >
                  <ReactSVG src={trashSvg} />
                </button>
              </div>
            </Tippy>

            <Tippy
              onShow={() => selectedDesigns?.length > 0}
              content="Move to folder"
            >
              <button
                className={klass([buttonCls.button, designsCls.addToFolder])}
                onClick={() => {
                  setIsStartMove(true);
                }}
              >
                <ReactSVG src={folderSvg} />
              </button>
            </Tippy>

            <Tippy
              onShow={() => selectedDesigns?.length > 0}
              content="Add collaborators"
            >
              <div style={{ display: "none" }}>
                <button
                  className={klass([
                    buttonCls.button,
                    designsCls.addCollaborators
                  ])}
                  onClick={() => {
                    console.log("kjjkj");
                  }}
                >
                  <ReactSVG src={personAddSvg} />
                </button>
              </div>
            </Tippy>

            <Tippy
              onShow={() => selectedDesigns?.length > 0}
              content="Deselect all"
            >
              <button
                className={klass([buttonCls.button, designsCls.deselect])}
                onClick={() => {
                  setSelectedDesigns([]);
                }}
              >
                <ReactSVG src={deselectSvg} />
              </button>
            </Tippy>
          </div>

          <div
            style={{
              display: selectedDesigns?.length > 0 ? "none" : "flex"
            }}
            className={klass([
              designsCls.multiSelectOptions,
              selectedFolders?.length
                ? designsCls.multiSelectOptionsVisible
                : ""
            ])}
          >
            <Tippy
              onShow={() => selectedFolders?.length > 0}
              content="Show tips"
            >
              <div>
                <button
                  className={klass([buttonCls.button, designsCls.showTips])}
                  onClick={() => {
                    setIshintDismissed(false);
                  }}
                >
                  <ReactSVG src={infoSvg} />
                </button>
              </div>
            </Tippy>
            <Tippy
              onShow={() => selectedFolders?.length > 0}
              content="Delete folders"
            >
              <div>
                <button
                  className={klass([
                    buttonCls.button,
                    designsCls.deleteSelected
                  ])}
                  onClick={() => {
                    setIsStartMultiDeleteFolders(true);
                  }}
                >
                  <ReactSVG src={trashSvg} />
                </button>
              </div>
            </Tippy>

            <Tippy
              onShow={() => selectedFolders?.length > 0}
              content="Add collaborators"
            >
              <div style={{ display: "none" }}>
                <button
                  className={klass([
                    buttonCls.button,
                    designsCls.addCollaborators
                  ])}
                  onClick={() => {
                    console.log("kjjkj");
                  }}
                >
                  <ReactSVG src={personAddSvg} />
                </button>
              </div>
            </Tippy>

            <Tippy
              onShow={() => selectedFolders?.length > 0}
              content="Deselect all"
            >
              <button
                className={klass([buttonCls.button, designsCls.deselect])}
                onClick={() => {
                  setSelectedFolders([]);
                }}
              >
                <ReactSVG src={deselectSvg} />
              </button>
            </Tippy>
          </div>
        </section>

        <div className="el-flex">
          {!selectedDesigns.length && !selectedFolders.length ? (
            <div className={designsCls.extraOptions}>
              <Tippy content="Import from Figma">
                <button
                  disabled={
                    !["Pro", "Teams"].includes(user?.subscription?.name)
                  }
                  onClick={() => {
                    track(trackActions.figma.startImport);
                    setIsStartFigmaImport(true);
                  }}
                  className={buttonCls.button}
                >
                  <ReactSVG className={designsCls.figmaIcon} src={figmaSvg} />
                </button>
              </Tippy>
              <Dropdown
                trigger={
                  <Tippy content="More options">
                    <button className={buttonCls.button}>
                      <img draggable={false} src={moreSvg} />
                    </button>
                  </Tippy>
                }
              >
                <div className="my-menu">
                  <div className="menu-row">
                    <div className="menu-row-text">
                      <button
                        onClick={() => setIsDragHintsDismissed(false)}
                        className={buttonCls.buttonSm}
                      >
                        <ReactSVG className="menu-row-icon" src={infoSvg} />
                        Show tips
                      </button>
                    </div>
                  </div>

                  <div className="menu-row">
                    <div className="menu-row-text">
                      <button
                        onClick={() => {
                          onOpenFileSelector();
                        }}
                        className={buttonCls.buttonSm}
                      >
                        <ReactSVG className="menu-row-icon" src={addImageSvg} />
                        Add designs
                      </button>
                    </div>
                  </div>

                  {!activeFolder ? (
                    <div className="menu-row">
                      <div className="menu-row-text">
                        <button
                          onClick={() => {
                            setIsStartNewFolder(true);
                          }}
                          className={buttonCls.buttonSm}
                        >
                          <ReactSVG
                            className="menu-row-icon"
                            src={bigPlusSvg}
                          />
                          New folder
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="menu-row">
                        <div className="menu-row-text">
                          <button
                            onClick={async () => {
                              const token = await connectJira({ userId });
                              setSelectedJiraFolder(activeFolder);
                            }}
                            className={buttonCls.buttonSm}
                          >
                            <ReactSVG className="menu-row-icon" src={jiraSvg} />
                            Jira tickets
                          </button>
                        </div>
                      </div>
                      <div className="menu-row">
                        <div className="menu-row-text">
                          <button
                            onClick={() => {
                              if (
                                ["Teams"].includes(user?.subscription?.name)
                              ) {
                                setIsInvitingUsers(true);
                                setFolderToInvite(activeFolder);
                              } else {
                                ellio.toaster.warning(
                                  "Sorry, the sharing feature is not included in your current plan, please upgrade to a different plan",
                                  {
                                    title: "Oops..."
                                  }
                                );
                              }
                            }}
                            className={buttonCls.buttonSm}
                          >
                            <ReactSVG
                              className="menu-row-icon"
                              src={personAddSvg}
                            />
                            Collaborators
                          </button>
                        </div>
                      </div>

                      <div className="menu-row">
                        <div className="menu-row-text">
                          <button
                            onClick={() => {
                              setIsStartDeleteFolder(true);
                              setFolderToDelete(activeFolder);
                            }}
                            className={buttonCls.buttonSm}
                          >
                            <ReactSVG
                              className="menu-row-icon"
                              src={trashSvg}
                            />
                            Delete folder
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Dropdown>
            </div>
          ) : null}
        </div>
      </div>

      <WithAnimation animation={appear}>
        <div
          className={klass([
            designsCls.noContent,
            images.length || userFolders.length || imagesUploadingCount > 0
              ? "hide"
              : ""
          ])}
        >
          <h1 className={designsCls.title}>
            {imagesUploadingCount > 0 ? (
              <span>
                Uploading{" "}
                <span
                  style={{ marginLeft: "20px", top: "5px" }}
                  className="el-spinner"
                ></span>
              </span>
            ) : (
              "No designs uploaded"
            )}
          </h1>

          <p className={designsCls.text}>
            {imagesUploadingCount > 0
              ? "Your images are being uploaded in the background, they will appear on the page once the upload is finished"
              : `You haven't uploaded any designs. Get started by clicking on "Add
            designs"`}
          </p>

          {imagesUploadingCount > 0 ? null : (
            <button
              onClick={() => {
                track(trackActions.designs.addButton);
                onOpenFileSelector();
              }}
              className={designsCls.button}
            >
              Add designs<span>&nbsp;&nbsp;+</span>
            </button>
          )}
          <div className={designsCls.clouds}>
            <img className={designsCls.cloudLeft} src={cloudPng} />
            <img className={designsCls.cloudRight} src={cloudPng} />
          </div>
        </div>
      </WithAnimation>

      <div className={designsCls.inner}>
        {activeFolder ? (
          <div className="el-flex el-vc border-bottom">
            <Tippy content="Go back">
              <button
                style={{ marginRight: "10px" }}
                className={buttonCls.button}
                onClick={() => {
                  setActiveFolder(null);
                  setSelectedDesigns([]);
                  refreshImages({
                    query: {
                      isDeleted: false
                    }
                  });
                }}
              >
                <img draggable={false} src={arrowLeftSvg} />
              </button>
            </Tippy>

            <Editable
              style={{ textAlign: "left" }}
              value={activeFolder.name}
              onConfirm={newName => {
                client
                  .service("folders")
                  .patch(activeFolder._id, {
                    name: newName
                  })
                  .then(() =>
                    ellio.toaster.success("Updated folder name successfully")
                  )
                  .catch(err => ellio.toaster.failure(err.message));
              }}
            />
          </div>
        ) : null}

        <h2
          style={{
            minHeight: "22px",
            display:
              (activeFolder && activeFolderImages?.length === 0) ||
              (activeFolder && activeImagesLoading) ||
              (imagesList?.length === 0 &&
                !activeFolder &&
                folders?.length === 0)
                ? "none"
                : "flex"
          }}
          className={klass([typeCls.h2, "el-flex", "el-vc"])}
        >
          Images ({imagesList?.length})
          {imagesUploadingCount > 0 ? (
            <Tippy content={`Uploading ${imagesUploadingCount} image(s)`}>
              <div style={{ marginLeft: "10px" }} className="el-spinner"></div>
            </Tippy>
          ) : null}
          {selectedDesigns?.length ? (
            <button
              style={{ marginLeft: "5px" }}
              className={buttonCls.link}
              onClick={() => setSelectedDesigns([])}
            >
              Deselect ({selectedDesigns.length})
            </button>
          ) : null}
        </h2>

        <div className={galleryCls.gallery}>
          {imagesList?.map(img => (
            <DesignPreview
              user={user}
              canSelectDesign={canSelectDesign}
              onDelete={v => {
                return deleteImage(v).then(refreshImages);
              }}
              key={img._id}
              image={img}
              selectDesign={selectDesign}
              selectedDesigns={selectedDesigns}
              resetSelect={() => setSelectedDesigns([])}
              uploadStatus={imagesUploading[img.uuid]}
            />
          ))}
          <div
            style={{
              display:
                (imagesList?.length === 0 && folders?.length === 0) ||
                (activeFolder && activeImagesLoading) ||
                (activeFolder && activeFolderImages?.length === 0)
                  ? "none"
                  : "flex",
              paddingLeft: imagesList?.length === 0 ? 0 : "75px"
            }}
            className={designsCls.bigAdd}
          >
            <Tippy content="Add design">
              <button
                style={{ height: "100px" }}
                onClick={onOpenFileSelector}
                className={buttonCls.button}
              >
                <ReactSVG src={bigPlusSvg} />
              </button>
            </Tippy>
          </div>
        </div>

        {!activeFolder ? (
          <div>
            <div
              style={{
                display: images.length || userFolders.length ? "block" : "none"
              }}
              className={designsCls.folderOptions}
            >
              <div
                style={{
                  display: "none"
                }}
                className={designsCls.folderOptionsInner}
              >
                <div
                  className={designsCls.addCollaborators}
                  style={{ display: "none" }}
                >
                  <Tippy content="Add collaborators">
                    <button className={buttonCls.button}>
                      <ReactSVG src={personAddSvg} />
                    </button>
                  </Tippy>
                </div>
              </div>

              <h2 className={typeCls.h2}>Folders</h2>
            </div>

            <div className={galleryCls.folders}>
              {userFolders.map(folder => (
                <Folder
                  userId={userId}
                  selectedFolders={selectedFolders}
                  canSelectFolder={canSelectFolder}
                  key={folder._id}
                  folder={folder}
                  onDelete={f => {
                    setFolderToDelete(f);
                    setIsStartDeleteFolder(true);
                  }}
                  onAddCollaborator={f => {
                    if (["Teams"].includes(user?.subscription?.name)) {
                      setIsInvitingUsers(true);
                      setFolderToInvite(f);
                    } else {
                      ellio.toaster.warning(
                        "Sorry, the sharing feature is not included in your current plan, please upgrade to a different plan",
                        {
                          title: "Oops..."
                        }
                      );
                    }
                  }}
                  onSelectJiraFolder={(f, t) => {
                    /* get all the images in the folder, for each
                     * image make a ticket
                     * first make a story for the folder, using folder newName
                     * then for each image make a ticket using the image name
                     * add the tickets as sub tasks of the story.
                     */
                    setSelectedJiraFolder(f);
                    // console.log("create jira tickets");
                    // const token = await connectJira({ userId });
                    // console.log(f);
                    // console.log(token);
                  }}
                  onOpen={setActiveFolder}
                  addFolder={(f, isShiftKey) =>
                    updateSelectedFolders(f, true, isShiftKey)
                  }
                  removeFolder={f => updateSelectedFolders(f)}
                />
              ))}

              <Folder
                userId={userId}
                style={{
                  display:
                    images?.length === 0 && userFolders?.length === 0
                      ? "none"
                      : "flex"
                }}
                isNewFolder
                onNewFolder={() => {
                  setIsStartNewFolder(true);
                }}
              />
            </div>
          </div>
        ) : null}

        {activeImagesLoading && activeFolder && (
          <div className="el-center mt2">
            <div className="el-spinner"></div>
          </div>
        )}

        {!imagesUploadingCount &&
          activeFolder &&
          activeFolderImages?.length === 0 &&
          !activeImagesLoading && (
            <div className={designsCls.noImages}>
              <img src={noTasksPng} />
              <p className={typeCls.p}>
                No images are added to this folder. You can either drag images
                to the page or simply click on the "Add designs" button to
                select images.
              </p>
              <button
                onClick={onOpenFileSelector}
                className={buttonCls.primarySm}
              >
                Add designs&nbsp;&nbsp;+
              </button>
            </div>
          )}
      </div>

      {isStartNewFolder ? (
        <NewFolderModal
          onClose={() => setIsStartNewFolder(false)}
          onNewFolder={f =>
            createFolder(f).then(r => {
              setActiveFolder(r);
            })
          }
          isProcessing={isNewFolderProgress}
          setIsProcessing={setIsNewFolderProgress}
        />
      ) : null}

      {isStartMove ? (
        <MoveToFolderModal
          folders={folders}
          images={selectedDesigns}
          onClose={() => setIsStartMove(false)}
          onMove={moveDesignsToFolder}
          isProcessing={isMoveProcess}
          setIsProcessing={setIsMoveProcess}
        />
      ) : null}

      {isStartFigmaImport ? (
        <ImportFromFigmaModal
          user={user}
          onClose={() => setIsStartFigmaImport(false)}
          onImport={onFigmaImport}
          isProcessing={isFigmaImportProcessing}
          setIsProcessing={setIsFigmaImportProcessing}
        />
      ) : null}

      {selectedJiraFolder ? (
        <JiraTicketsFromFolderModal
          folder={selectedJiraFolder}
          onClose={() => setSelectedJiraFolder(null)}
          onCreate={onCreateJiraTicketsFromFolder}
          isProcessing={jiraTicketsFromFolder.isProcessing}
          setIsProcessing={v => {
            setJiraTicketsFromFolder(p => ({
              ...p,
              isProcessing: v
            }));
          }}
        />
      ) : null}

      {isStartDeleteFolder ? (
        <DeleteFolderModal
          onClose={() => setIsStartDeleteFolder(false)}
          onDelete={f => {
            setIsDeletingFolder(true);
            return deleteFolder(f)
              .then(r => {
                setIsStartDeleteFolder(false);
                setActiveFolder(null);
                refreshFolders();
                refreshImages();
              })
              .catch(err => {
                setIsDeletingFolder(false);
              })
              .then(() => setIsDeletingFolder(false));
          }}
          isProcessing={isDeletingFolder}
          folder={folderToDelete}
        />
      ) : null}

      {isStartMultiDelete ? (
        <DeleteDesignsModal
          images={selectedDesigns}
          isProcessing={isMultiDelete}
          onClose={() => setIsStartMultiDelete(false)}
          onDelete={() => {
            setIsMultiDelete(true);
            deleteSelectedImages()
              .then(r => {
                setSelectedDesigns([]);
                setIsStartMultiDelete(false);
              })
              .catch(err => {
                ellio.toaster.failure(err.message);
              })
              .then(() => {
                setIsMultiDelete(false);
              });
          }}
        />
      ) : null}

      {isStartMultiDeleteFolders ? (
        <DeleteFoldersModal
          folders={selectedFolders}
          isProcessing={isMultiDeleteFolders}
          onClose={() => setIsStartMultiDeleteFolders(false)}
          onDelete={() => {
            setIsMultiDeleteFolders(true);
            return deleteSelectedFolders()
              .then(r => {
                setSelectedFolders([]);
                setIsStartMultiDeleteFolders(false);
                refreshFolders();
                refreshImages();
              })
              .catch(err => {
                ellio.toaster.failure(err.message);
              })
              .then(() => {
                setIsMultiDeleteFolders(false);
              });
          }}
        />
      ) : null}

      {(selectedDesigns.length > 0 || selectedFolders.length > 0) &&
      !isHintsDismissed ? (
        <Hints
          section="designs"
          isHintsDismissed={isHintsDismissed}
          setIshintDismissed={setIshintDismissed}
          user={user}
        >
          <DesignsHints />
        </Hints>
      ) : null}

      {!isDragHintsDismissed &&
      !(selectedDesigns.length > 0 || selectedFolders.length > 0) ? (
        <Hints
          section="designs_drag"
          setIshintDismissed={setIsDragHintsDismissed}
          user={user}
        >
          <DesignsDragHints />
        </Hints>
      ) : null}

      {isInvitingUsers ? (
        <InviteToFolder
          user={user}
          folder={folderToInvite}
          onClose={() => {
            setIsInvitingUsers(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default Designs;
