import { useEffect, useState } from "react";

const isDefined = v => v != null;

function useAnimeOnMount({ ref, def, watchList = [], animationOptions = {} }) {
  /* going to use this later to run animation only once on mount */
  const [isOnMount, setOnMount] = useState(false);

  /* set variable to call the animation only once */
  useEffect(() => {
    if (watchList.every(isDefined)) {
      setOnMount(true);
    }
  }, [watchList]);

  /* when mounted, animate, once */
  useEffect(() => {
    if (ref.current && isOnMount) {
      def(ref.current, animationOptions).play();
    }
  }, [ref, def, isOnMount]);

  return def;
}

export default useAnimeOnMount;
