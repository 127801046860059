import nanoid from "pkgs/random/id";

const pathTo = (match, subRoute) => {
  const ending = subRoute.split("/").slice(-1)[0];
  return `${match.url}/${ending}`;
};

const routes = {
  // planning: {
  //   id: nanoid(),
  //   url: "/planning",
  //   display: "Planning"
  // },
  // projects: {
  //   id: nanoid(),
  //   url: "/projects",
  //   display: "Projects"
  // },
  // reports: {
  //   id: nanoid(),
  //   url: "/reports",
  //   display: "Reports"
  // },
  designs: {
    id: nanoid(),
    url: "/designs",
    display: "Designs",
    subRoutes: {
      details: {
        id: nanoid(),
        url: "/designs/:id",
        display: "Design"
      }
    }
  },
  settings: {
    id: nanoid(),
    url: "/settings",
    display: "Settings",
    subRoutes: {
      profile: {
        id: nanoid(),
        url: "/settings/profile",
        display: "Profile"
      },
      subscriptions: {
        id: nanoid(),
        url: "/settings/subscriptions",
        display: "Subscriptions"
      },
      organization: {
        id: nanoid(),
        url: "/settings/organization",
        display: "Organization"
      }
      // organization: {
      //   id: nanoid(),
      //   url: "/settings/organization",
      //   display: "Organization"
      // }
    }
  }
  // retro: {
  //   id: nanoid(),
  //   url: "/retro",
  //   display: "Retro"
  // },
};

/* If route does not match, redirect to default route */
const defaultRoute = routes.designs;
const routesList = [routes.designs, routes.settings];

export default routes;

export { defaultRoute, routesList, pathTo };
