import client, { useServiceEvents, serviceEvents } from "common/client";
import { useState, useEffect, useRef, useCallback } from "react";
const isFunction = v => typeof v === "function";

const useNotifications = (
  params = {},
  deps = [],
  fns = [],
  events = serviceEvents
) => {
  const notificationsService = useRef(client.service("notifications"));
  const [notifications, setNotifications] = useState();
  const [notificationsErr, setNotificationsErr] = useState();

  const refreshNotifications = (
    prms = {
      query: {
        isDismissed: false,
        ...params.query
      }
    }
  ) => {
    return notificationsService.current
      .find(prms)
      .then(resp => setNotifications(resp.data))
      .catch(setNotificationsErr);
  };

  useEffect(refreshNotifications, []);

  useServiceEvents(
    notificationsService,
    (d, e) => {
      refreshNotifications();
      if (fns.length && fns.every(v => isFunction(v.fn))) {
        fns.forEach(v => {
          if (!v.event) {
            v.fn(d, e);
          } else if (v.event === e) {
            v.fn(d, e);
          }
        });
      }
    },
    [],
    events
  );

  const createNotification = useCallback(
    (folder, successMsg = "Folder was created successfully.") => {
      return notificationsService.current
        .create(folder)
        .then(resp => {
          ellio.toaster.success(successMsg, { dismiss: 3500 });
        })
        .catch(err => {
          ellio.toaster.failure(err.message);
          return Promise.reject(err);
        });
    },
    []
  );

  const deleteNotification = useCallback(
    (folder, successMsg = "Folder was removed successfully.") => {
      return notificationsService.current
        .remove(folder._id)
        .then(resp => ellio.toaster.success(successMsg, { dismiss: 3500 }))
        .catch(err => {
          ellio.toaster.failure(err.message);
          return Promise.reject(err);
        });
    },
    []
  );

  const deleteNotifications = useCallback(
    (
      notifications,
      successMsg = "Notifications were removed successfully."
    ) => {
      return notificationsService.current
        .remove(null, {
          query: {
            _id: {
              $in: notifications.map(v => v._id)
            }
          }
        })
        .then(resp => ellio.toaster.success(successMsg))
        .catch(err => ellio.toaster.failure(err.message));
    },
    []
  );

  return {
    notifications,
    setNotifications,
    notificationsErr,
    notificationsService,
    createNotification,
    deleteNotification,
    deleteNotifications
  };
};

export default useNotifications;
