import cls from "./form.module.css";
import React, { useState, useRef } from "react";
import accountCls from "./account.module.css";
import appear from "common/animations/appear";
import WithAnimation from "common/animations/with-animation";
import klass from "common/klass";
import client, { cleanupLS } from "common/client";
import personSvg from "./assets/person.svg";

function LoginForm(props) {
  const [creds, setCreds] = useState({ email: "" });
  const [errMsg, setErrMsg] = useState("");
  const formRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  const update = (f, val) => {
    setCreds(p => ({
      ...p,
      [f]: val
    }));
  };

  const friendlyErrorMsg = msg => {
    if (msg.toLowerCase().includes("invalid login")) {
      return "Couldn't send a login link, please try again.";
    }
    return msg;
  };

  return (
    <form
      className={klass([cls.form, errMsg ? cls.danger : ""])}
      onSubmit={e => {
        e.preventDefault();
        // login();
      }}
    >
      {errMsg ? (
        <WithAnimation animation={appear}>
          <p className={cls.error}>{friendlyErrorMsg(errMsg)}</p>
        </WithAnimation>
      ) : null}
      <div className={cls.inputs}>
        <div className={cls.inputWrapper}>
          <div className={cls.unameLogo}>
            <img src={personSvg} />
          </div>
          <input
            className={klass([cls.input, cls.unameInput])}
            value={creds.email}
            autoComplete="username"
            placeholder="Email"
            onChange={e => update("email", e.target.value)}
            type="text"
          />
        </div>
        <div className={cls.inputWrapper}></div>
      </div>
      <div className={accountCls.terms}>
        <p>
          By clicking below, you agree to our{" "}
          <a target="_blank" href="https://ellio.app/terms.html">
            Terms of Service
          </a>{" "}
          and{" "}
          <a target="_blank" href="https://ellio.app/privacy.html">
            Privacy Policy
          </a>{" "}
        </p>
      </div>
      <div className={cls.buttonWrapper}>
        <button
          disabled={isSending}
          onClick={() => {
            if (isSending) {
              return;
            }
            setIsSending(true);
            return props
              .passwordLessAuth(creds.email)
              .then(() => {})
              .catch(err => {
                ellio.toaster.failure(err.message);
                setErrMsg(err.message);
              })
              .finally(() => {
                setIsSending(false);
              });
          }}
          type="submit"
          className={cls.button}
        >
          Login
        </button>
      </div>
    </form>
  );
}

export default LoginForm;
