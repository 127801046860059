import { useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory, Link } from "react-router-dom";
import { newEve, studioActionEvents } from "common/eve";
import cls from "./block-user.module.css";
import buttonCls from "common/button.module.css";
import typeCls from "common/type.module.css";
import rocketPng from "./rocket.png";
import confirmPng from "./confirm.png";

export default function BlockUser(props) {
  const { logoutAndGoToEllio, user } = props;
  const [isSending, setIsSending] = useState(false);

  const verifyEmailMsg = `We have sent you a verification email and it should arrive shortly. Please check your inbox and verify your email adress. If you can’t find it, check your Spam folder or click the link below to resend the email.`;

  const betaUserMsg = `We couldn't find your email in the beta list. Please go to https://ellio.app to ask for an invite.`;

  const reqInviteBlock = () => {
    return (
      <p className={typeCls.p1}>
        Please go to{" "}
        <a onClick={logoutAndGoToEllio} href="">
          Ellio.app
        </a>{" "}
        and request an invite.
      </p>
    );
  };

  const resendToken = () => {
    if (!user.isBeta) {
      return;
    }
    setIsSending(true);
    client
      .service("email-confirm")
      .create({
        email: user.email
      })
      .then(r => {
        ellio.toaster.success(`Verification email was sent to ${user.email}`, {
          dismiss: false
        });
      })
      .catch(err => {
        ellio.toaster.failure(err.message);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  if (!user.isBeta) {
    return (
      <div className={cls.notAllowed}>
        <img draggable={false} className={cls.confirmImage} src={confirmPng} />
        <div className={cls.body}>
          <h1>Beta access</h1>
          <p className={typeCls.p2}>{betaUserMsg}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={cls.notAllowed}>
      <img draggable={false} className={cls.confirmImage} src={confirmPng} />
      <div className={cls.body}>
        <h1>Verify email address</h1>
        <p className={typeCls.p2}>{verifyEmailMsg}</p>
        <button
          disabled={isSending}
          onClick={resendToken}
          className={buttonCls.primarySm}
        >
          Resend email
        </button>
      </div>
    </div>
  );
}
