import cls from "./collaborators.module.css";
import Avatar from "common/avatar";
import klass from "common/klass";

export default function Collaborators(props) {
  const { collaborators, style = {}, className } = props;

  if (!collaborators) {
    return null;
  }

  return (
    <div style={style} className={klass([cls.collaborators, className])}>
      {collaborators.map((c, i) => (
        <div className="rel" key={c.email}>
          {i > 3 ? null : i > 2 ? (
            <span
              style={{ left: `${i * 15 * -1}px` }}
              className={cls.moreCollabs}
            >
              +{collaborators.length - 3}
            </span>
          ) : (
            <>
              {c.profileImageUrl ? (
                <div
                  className={cls.profileImage}
                  style={{
                    left: `${i * 15 * -1}px`,
                    backgroundImage: `url(${c.profileImageUrl})`
                  }}
                ></div>
              ) : (
                <Avatar
                  string={c.email}
                  bgColor={"#D5E3F0"}
                  style={{
                    width: "30px",
                    height: "30px",
                    left: `${i * 15 * -1}px`,
                    position: "relative"
                  }}
                />
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
}
