import { useState, useEffect, useRef } from "react";
import cls from "./team-modal.module.css";
import imagePickCls from "./image-modal.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import overlayCls from "common/overlay.module.css";
import client from "common/client";
import FileSelect from "common/file-select";
import { k } from "common/klass";
import orgSvg from "./org.svg";
import { ReactSVG } from "react-svg";

export default function TeamModal(props) {
  const { onClose, onCreate, isProcessing } = props;
  const [overlay, setOverlay] = useState();
  const [orgName, setOrgName] = useState("");
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [input, setInput] = useState();

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  useEffect(() => {
    if (input) {
      input.focus();
    }
  }, [input]);

  const onFileSelectErrors = errors => {
    /* TOOD: add an option to see which file errored out */
    if (errors?.length) {
      const first = errors[0].errors[0];
      const code = first.code;
      if (code === "file-too-large") {
        setIsInvalid(true);
        ellio.toaster.warning("File size limit is 4MB", {
          title: "File too large"
        });
      } else if (code === "file-invalid-type") {
        ellio.toaster.warning(
          "At least one of the selected files was not an image."
        );
      }
    }
  };

  const onFileSelect = files => {
    const f = files[0];
    if (f) {
      setImage(window.URL.createObjectURL(f));
      setFile(f);
    }
  };

  const onConfirm = () => {
    return onCreate({
      name: orgName,
      file,
      image
    });
  };

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={cls.teamModal}>
        <h1 className={typeCls.ctrl5}>Create organization</h1>
        <p className={typeCls.p}>
          Use the form below to create your organization. You can optionally
          upload a logo as well. Once you finish creating your organization, you
          can starting inviting team members.
        </p>
        <div className={cls.orgDetails}>
          <FileSelect
            isOpen={true}
            onDrop={onFileSelect}
            onErrors={onFileSelectErrors}
            maxSize={4}
            accept="image/*"
            dropArea={() => (
              <div className={imagePickCls.avatarWrapper}>
                {image ? (
                  <div>
                    <div
                      className={cls.theImage}
                      style={{
                        backgroundImage: `url(${image})`
                      }}
                    ></div>
                  </div>
                ) : (
                  <div className={cls.logoPlaceholder}>
                    <ReactSVG src={orgSvg} />
                  </div>
                )}
                <button className={k(buttonCls.button, cls.selectImage)}>
                  Select logo
                </button>
              </div>
            )}
          />

          <input
            ref={setInput}
            placeholder="Organization name"
            value={orgName}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onConfirm();
              }
            }}
            onChange={e => setOrgName(e.target.value)}
            type="text"
            className={k(inputCls.textInput, cls.orgName)}
          />
        </div>
        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          <button
            disabled={orgName.length < 2}
            className={buttonCls.primarySm}
            onClick={onConfirm}
          >
            Create {isProcessing ? <div className="el-spinner"></div> : null}
          </button>
        </div>
      </div>
    </div>
  );
}
