import { useHotkeys } from "react-hotkeys-hook";
import { studioActionEvents } from "common/eve";
import { isBgImage } from "./canvas-util";
import keys, { combination } from "common/keys";

const useKeys = ({
  canvas,
  mousePositionRef,
  annotationId,
  contextMenu,
  setContextMenu,
  colorPicker,
  setColorPicker,
  studioActionsRef,
  emitAction,
  setOpenTask,
  openTask
}) => {
  /* delete selected object when backspace */
  useHotkeys(
    "backspace",
    () => {
      emitAction({
        name: studioActionEvents.remove,
        canvas,
        annotationId
      });
    },
    {},
    [canvas]
  );

  /* close color picker or context menu */
  useHotkeys(
    "escape",
    e => {
      e.preventDefault();

      if (colorPicker.isOpen) {
        setColorPicker(p => ({ ...p, isOpen: false }));
      }

      if (contextMenu.isOpen) {
        setContextMenu(p => ({ ...p, isOpen: false }));
      }

      if (openTask) {
        setOpenTask(false);
      }

      const canvasObjects = canvas.getObjects();

      emitAction({
        name: studioActionEvents.cancel,
        canvas,
        annotationId,
        object:
          canvas.getActiveObject() || canvasObjects[canvasObjects.length - 1]
      });
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"]
    },
    [canvas, contextMenu, colorPicker, openTask]
  );

  /* save */
  useHotkeys(
    "ctrl+s, command+s",
    e => {
      e.preventDefault();
      emitAction({
        name: studioActionEvents.save,
        canvas,
        annotationId
      });
    },
    {},
    [canvas]
  );

  useHotkeys(
    combination(studioActionEvents.group),
    e => {
      e.preventDefault();
      return;
      emitAction({
        name: studioActionEvents.group,
        canvas,
        annotationId,
        object: canvas.getActiveObject()
      });
    },
    {},
    [canvas]
  );

  useHotkeys(
    combination(studioActionEvents.ungroup),
    e => {
      return;
      e.preventDefault();
      emitAction({
        name: studioActionEvents.ungroup,
        canvas,
        annotationId,
        object: canvas.getActiveObject()
      });
    },
    {},
    [canvas]
  );

  useHotkeys(
    combination(studioActionEvents.addBox),
    e => {
      e.preventDefault();
      const { x, y } = mousePositionRef.current;
      emitAction({
        name: studioActionEvents.addBox,
        x,
        y,
        canvas,
        annotationId
      });
    },
    {},
    [canvas]
  );

  useHotkeys(
    combination(studioActionEvents.addComment),
    e => {
      e.preventDefault();
      const { x, y } = mousePositionRef.current;
      emitAction({
        name: studioActionEvents.addComment,
        x,
        y,
        canvas,
        annotationId
      });
    },
    {},
    [canvas]
  );

  useHotkeys(
    combination(studioActionEvents.addTask),
    e => {
      e.preventDefault();
      const { x, y } = mousePositionRef.current;
      emitAction({
        name: studioActionEvents.addTask,
        x,
        y,
        canvas,
        annotationId
      });
    },
    {},
    [canvas]
  );

  useHotkeys(
    combination(studioActionEvents.addText),
    e => {
      e.preventDefault();
      const { x, y } = mousePositionRef.current;
      emitAction({
        name: studioActionEvents.addText,
        x,
        y,
        canvas,
        annotationId
      });
    },
    {},
    [canvas]
  );

  useHotkeys(
    combination(studioActionEvents.addLine),
    e => {
      e.preventDefault();
      const { x, y } = mousePositionRef.current;
      emitAction({
        name: studioActionEvents.addLine,
        x,
        y,
        canvas,
        annotationId
      });
    },
    {},
    [canvas]
  );

  useHotkeys(
    combination(studioActionEvents.pickColor),
    e => {
      e.preventDefault();
      return;
      const active = canvas.getActiveObject();
      const objects = canvas.getObjects();
      const last = objects[objects.length - 1];
      const currMouseTarget = mousePositionRef.current.target;
      const mouseTarget = isBgImage(currMouseTarget) ? null : currMouseTarget;

      const target = mouseTarget || active || last;
      const { x, y } = mousePositionRef.current;

      emitAction({
        name: studioActionEvents.pickColor,
        x,
        y,
        object: target,
        canvas,
        annotationId,
        setColorPicker
      });
    },
    {},
    [canvas, setColorPicker]
  );
};

export default useKeys;
