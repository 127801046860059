import { useState, useEffect } from "react";
import Tippy from "@tippyjs/react";
import client from "common/client";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import klass from "common/klass";
import cls from "./jira-tickets-from-folder-modal.module.css";
import typeCls from "common/type.module.css";
import buttonCls from "common/button.module.css";
import inputCls from "common/input.module.css";
import overlayCls from "common/overlay.module.css";
import checkSvg from "./check.svg";
import crossSvg from "./cross.svg";
import validator from "validator";

export default function JiraTicketsFromFolderModal(props) {
  const {
    onClose,
    onCreate,
    isProcessing,
    setIsProcessing,
    folder: _folder,
    designId
  } = props;
  const [folder, setFolder] = useState();
  const [overlay, setOverlay] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [project, setProject] = useState(null);
  const [jiraProjectsOptions, setJiraProjectsOptions] = useState();
  const [isStory, setIsStory] = useState(true);
  const [isSubTasks, setIsSubTasks] = useState(true);

  useEffect(() => {
    if (overlay) {
      overlay.classList.add(overlayCls.fadeIn);
    }
  }, [overlay]);

  useEffect(() => {
    client
      .service("folders")
      .get(_folder._id)
      .then(d => setFolder(d));
  }, []);

  useEffect(() => {
    async function getProjects() {
      const token = window.localStorage.getItem("jira_token");
      const cloudId = window.localStorage.getItem("jira_cloud_id");
      const jiraProjects = await client.service("jira").find({
        query: {
          token,
          cloudId
        }
      });

      const options = jiraProjects.map(v => ({
        value: v.key,
        label: v.name
      }));

      return options;
    }

    if (folder) {
      getProjects().then(r => {
        if (folder.jiraProject) {
          setProject(folder.jiraProject);
          setJiraProjectsOptions([folder.jiraProject]);
        } else {
          setJiraProjectsOptions(r);
        }
      });
    }
  }, [folder]);

  const handleCreate = jiraProject => {
    const ellioUrlPrefix = `${ellio.webHost}/designs`;
    setIsProcessing(true);
    onCreate({
      folder,
      ellioUrlPrefix,
      jiraProject,
      isStory,
      isSubTasks
    })
      .then(r => {
        setTimeout(() => {
          setIsProcessing(false);
          setIsConfirmed(true);
        }, 250);
      })
      .catch(err => {
        console.log("err");
        console.log(err);
        setIsProcessing(false);
      });
  };

  if (!folder) {
    return <div>Loading ...</div>;
  }

  return (
    <div ref={setOverlay} className={overlayCls.blurOverlay}>
      <div className={klass([cls.ticketsFromFolderModal])}>
        {isConfirmed ? null : (
          <h1 className={typeCls.ctrl5}>Create Jira tickets from folder</h1>
        )}

        {isConfirmed ? (
          <div className={cls.confirm}>
            <ReactSVG className={cls.check} src={checkSvg} />
            <p className={typeCls.p}>
              We have started creating your Jira tickets, you will be notified
              when it's finished on the top right hand corner of the page.
            </p>
          </div>
        ) : (
          <div>
            <p className={typeCls.pMb2}>
              {isStory
                ? "For each image in the folder a Jira Story will be created under the selected project. And for each task in an image, a sub-task will be created."
                : "One Jira story will be created under the selected project, and for each image a sub-task will be added to the Story."}
            </p>

            <Select
              isDisabled={folder.jiraProject}
              options={jiraProjectsOptions || []}
              value={project || null}
              placeholder="Select project from Jira"
              onChange={setProject}
            />

            <div>
              <label className={cls.label}>
                <input
                  type="checkbox"
                  checked={isStory}
                  onChange={e => {
                    setIsStory(e.target.checked);
                  }}
                />
                Story for each image
              </label>
            </div>

            <div style={{ display: "none" }}>
              <label className={cls.label}>
                <input
                  type="checkbox"
                  checked={isSubTasks}
                  onChange={e => {
                    setIsSubTasks(e.target.checked);
                  }}
                />
                Create sub-tasks
              </label>
            </div>
          </div>
        )}

        <div className={cls.footerButtons}>
          <button onClick={onClose} className={buttonCls.primaryTextSmall}>
            Close
          </button>
          {isConfirmed ? null : (
            <>
              <button
                disabled={!project}
                className={buttonCls.primarySm}
                onClick={() => handleCreate(project)}
              >
                {folder.jiraProject ? "Update" : "Create"}
                {isProcessing ? <div className="el-spinner"></div> : null}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
