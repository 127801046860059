import cls from "./studio.module.css";
import client, { sendStudioEvent } from "common/client";
import id from "pkgs/random/id";
import { studioActionEvents } from "common/eve";
import debounceFn from "debounce-fn";
import ReactDOM from "react-dom";
import { fabric } from "fabric";
import { Button } from "@blueprintjs/core";
import Color from "color";
import colorParse from "color-parse";
import commentSvg from "./comment.svg";
import comment2Svg from "./comment2.svg";
import comment3Svg from "./comment3.svg";
import taskSvg from "./task.svg";
import task2Svg from "./task2.svg";
import task3Svg from "./task3.svg";
import pointerSvg from "./pointer.svg";
import { handleUpdateModel } from "studio/update-model";

export const getS3KeyFromUrl = url => {
  const m = url.match(/http.*s3.*amazonaws.com\/(.*)/);
  if (m) {
    return m[1].split("?")[0];
  }
  return "";
};

/* https://stackoverflow.com/questions/170624/javascript-image-resize */
/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
export function getScaledDim(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

export const defaultCanvasConfig = {
  selection: true,
  uniformScaling: false,
  preserveObjectStacking: true,
  containerClass: "el-studio__canvas",
  hoverCursor: "crosshair",
  stateful: true,
  /* for some reason, I couldnt verify thse two actually work for sure */
  fireRightClick: true,
  stopContextMenu: true
};

export const defaultCanvasWidth = 1500;

export const defaultColor = { r: 255, g: 0, b: 0, a: 0.04 };

export const extraPropsToSave = [
  "selectable",
  "hoverCursor",
  "lockRotation",
  "strokeUniform",
  "objectCaching",
  "_controlsVisibility",
  "metaProp",
  "hasControls",
  "hasBorders",
  "lockMovementX",
  "lockMovementY",
  "crossOrigin"
];

/* define get abs position */
fabric.Canvas.prototype.getAbsoluteCoords = function (object) {
  return {
    x: object.left,
    offsetLeft: this._offset.left,
    y: object.top,
    offsetTop: this._offset.top,
    aCoords: object.aCoords
  };
};

export const addMetaProp = ({ metaProp, object }) => {
  object.set("metaProp", metaProp);

  /* add metaProp in the result of toObject call */
  object.toObject = (function (toObject) {
    return function (propertiesToInclude) {
      return fabric.util.object.extend(
        toObject.call(this, propertiesToInclude),
        {
          metaProp
        }
      );
    };
  })(object.toObject);
};

export const ungroup = ({ canvas }) => {
  if (!canvas.getActiveObject()) {
    return;
  }
  if (canvas.getActiveObject().type !== "group") {
    return;
  }
  canvas.getActiveObject().toActiveSelection();
  canvas.requestRenderAll();
};

export const group = ({ canvas }) => {
  if (!canvas.getActiveObject()) {
    return;
  }
  if (canvas.getActiveObject().type !== "activeSelection") {
    return;
  }

  const first = canvas.getActiveObject().getObjects()[0];
  const firstId = first.metaProp?.id;

  canvas.getActiveObject().toGroup();

  canvas.getActiveObject().set({
    hoverCursor: "move",
    metaProp: { id: `ellio_group_node_${firstId}` }
  });
  canvas.requestRenderAll();
};

export const RECT_PROPS = {
  fill: "rgba(255,255,255,.04)",
  strokeDashArray: [6, 6],
  stroke: "black",
  strokeUniform: true,
  objectCaching: false,
  hoverCursor: "move",
  lockRotation: true
};

export const newBox = (opts = {}) => {
  const defaults = {
    left: 10,
    top: 10,
    width: 100,
    height: 100,
    ...RECT_PROPS
  };
  const rectOpts = { ...defaults, ...opts };
  const rect = new fabric.Rect(rectOpts);

  rect.setControlsVisibility({ mtr: false });

  const metaProp = {
    type: "ellio_region_node",
    id: `region_${id()}`
  };

  addMetaProp({ metaProp, object: rect });

  return rect;
};

export const COMMENT_PROPS = {
  objectCaching: false,
  hoverCursor: "pointer",
  lockRotation: true,
  hasControls: false
  // lockMovementX: true,
  // lockMovementY: true
};

export const newComment = (opts = {}, userColor) => {
  const defaults = {
    left: 10,
    top: 10,
    ...COMMENT_PROPS
    // hasBorders: false
  };

  const commentOpts = { ...defaults, ...opts };

  const metaProp = {
    type: "ellio_comment_node",
    id: `comment_${id()}`
  };

  return new Promise((resolve, reject) => {
    fabric.loadSVGFromURL(comment3Svg, commentObject => {
      const shape = fabric.util.groupSVGElements(commentObject, {
        ...commentOpts,
        originX: "center",
        originY: "center"
      });

      const count = newSimpleText({
        originX: "center",
        originY: "center",
        left: 5,
        top: 2
      });

      // shape.setControlsVisibility({ mtr: false });
      // shape.set(commentOpts);

      if (userColor) {
        if (shape.getObjects) {
          shape.getObjects()[0].set({ fill: userColor });
        } else {
          /* feather icon */
          // shape.set({ stroke: userColor, strokeWidth: '2  ', fill: '#fff' });
          // shape.set({ fill: userColor, stroke: "#fff", strokeWidth: 2 });

          shape.set({
            fill: userColor,
            stroke: "#fff",
            strokeWidth: 2,
            originX: "center",
            originY: "center"
          });
        }
      }
      const gr = new fabric.Group([shape, count], commentOpts);
      addMetaProp({ metaProp, object: gr });
      resolve(gr);
    });
  });
};

export const TASK_PROPS = {
  objectCaching: false,
  hoverCursor: "pointer",
  lockRotation: true,
  hasControls: false
  // lockMovementX: true,
  // lockMovementY: true
};

export const newTask = (opts = {}, userColor) => {
  const defaults = {
    left: 10,
    top: 10,
    ...TASK_PROPS
    // hasBorders: false
  };

  const taskOpts = { ...defaults, ...opts };

  const metaProp = {
    type: "ellio_task_node",
    id: `task_${id()}`
  };

  return new Promise((resolve, reject) => {
    fabric.loadSVGFromURL(task3Svg, taskObj => {
      const shape = fabric.util.groupSVGElements(taskObj, {
        ...taskOpts,
        originX: "center",
        originY: "center"
      });

      const count = newSimpleText({
        originX: "center",
        originY: "center",
        left: 2,
        top: 2
      });

      // shape.setControlsVisibility({ mtr: false });
      // gr.setControlsVisibility({ mtr: false })
      // gr.set(taskOpts);
      // shape.set(taskOpts);

      if (userColor) {
        if (shape.getObjects) {
          /* this is not valid anymore, the svg that i'm using is simpler */
          shape.getObjects()[0].set({ fill: userColor });
        } else {
          shape.set({
            fill: userColor,
            stroke: "#fff",
            originX: "center",
            originY: "center"
          });
          // shape.getObjects()[0].set({ fill: userColor });
        }
      }
      const gr = new fabric.Group([shape, count], taskOpts);
      addMetaProp({ metaProp, object: gr });
      resolve(gr);
    });
  });
};

export const newLine = (opts = {}) => {
  const defaultOpts = {
    left: 10,
    top: 10,
    stroke: "red",
    hoverCursor: "move"
  };
  const config = { ...defaultOpts, ...opts };
  const line = new fabric.Line([50, 100, 200, 200], config);
  const metaProp = {
    type: "ellio_line_node",
    id: `line_${id()}`
  };
  addMetaProp({ metaProp, object: line });
  return line;
};

export const newText = (opts = {}) => {
  const type = opts.type || "ellio_text_node";

  /* removing so not to mess with fabric type */
  delete opts.type;

  const defaults = {
    fontFamily: "arial",
    fontSize: 20,
    cornerStyle: "circle",
    backgroundColor: "rgba(255,255,255,1)",
    hoverCursor: "move"
    // hasControls: false
  };

  const config = { ...defaults, ...opts };

  const text = new fabric.IText("Double click to edit", config);

  if (type === "ellio_text_node") {
    text.fontSize = 16;
  }

  const metaProp = {
    type,
    id: `text_${id()}`
  };

  addMetaProp({ metaProp, object: text });
  return text;
};

export const newSimpleText = (opts = {}, txt = "") => {
  const type = opts.type || "ellio_simple_text_node";

  /* removing so not to mess with fabric type */
  delete opts.type;

  const defaults = {
    fontFamily: "arial",
    fill: "#fff",
    fontSize: 16,
    cornerStyle: "circle",
    backgroundColor: "transparent",
    hoverCursor: "move",
    hasControls: false
  };

  const config = { ...defaults, ...opts };

  const text = new fabric.Text(txt, config);

  const metaProp = {
    type,
    id: `simple_text_${id()}`
  };

  addMetaProp({ metaProp, object: text });
  return text;
};

export const newPointer = (opts = {}) => {
  const defaults = {
    left: 10,
    top: 10,
    width: 20,
    height: 20,
    selectable: false,
    crossOrigin: "anonymous"
  };

  const pointerOpts = { ...defaults, ...opts };

  const metaProp = {
    type: `ellio_mouse_${id()}`,
    type: `ellio_mouse_${id()}`
  };

  return new Promise((resolve, reject) => {
    fabric.loadSVGFromURL(pointerSvg, pointerObj => {
      var shape = fabric.util.groupSVGElements(pointerObj, pointerOpts);

      // shape.set({fill: 'red'})
      // console.log('shape', shape);
      // pointerObj.setControlsVisibility({ mtr: false });
      // pointerObj.set({
      //   top: pointerObj.top - pointerObj.height,
      //   left: pointerObj.left - 8
      // });
      // console.log(shape, shape.paths)
      addMetaProp({ metaProp, object: shape });
      resolve(shape);
    });
  });
};

export const setCanvasDimensions = ({
  image,
  canvas,
  canvasWidth = defaultCanvasWidth
}) => {
  const imgWidth = image.get("width");
  const imgHeight = image.get("height");
  const maxHeight = imgHeight;

  const canvasDim = getScaledDim(imgWidth, imgHeight, canvasWidth, maxHeight);

  canvas.setWidth(canvasDim.width);
  canvas.setHeight(canvasDim.height);

  if (imgWidth > canvasWidth) {
    image.scaleToWidth(canvasWidth);
  }

  return canvasDim;
};

export const remove = ({ canvas }) => {
  canvas.getActiveObjects().forEach(o => canvas.remove(o));
  canvas.discardActiveObject();
};

export const add = (object, canvas) => {
  canvas.add(object);
  return { object, canvas };
};

export const addContextMenuHandler = ({ canvas, setContextMenu }) => {
  canvas.on("mouse:down", e => {
    if (e.button === 3) {
      const target = e.target;
      const { x, y } = e.pointer;
      /* select the target underneath the context menu, except groups or
       * the background image.
       */
      if (
        !target?._objects &&
        !target.metaProp?.type.includes("ellio_design")
      ) {
        canvas.setActiveObject(target).renderAll();
      }
      /* set state variable */
      setContextMenu({
        isOpen: true,
        x,
        y,
        target,
        event: e
      });
    } else {
      const { x, y } = e.pointer;
      setContextMenu({
        isOpen: false,
        x,
        y,
        target: e.target,
        event: e
      });
    }
  });
};

export const downloadDataUrl = (uri, name) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const toImageUri = ({ canvas, options = {} }) => {
  return canvas.toDataURL({ format: "png" });
};

export const downloadCanvas = ({ canvas, filename }) => {
  const data = canvas.toDataURL({
    format: "jpeg",
    quality: 1,
    enableRetinaScaling: true
  });
  downloadDataUrl(data, filename);
};

export const clearAll = opts => {
  const { canvas, isDeleteBackground = false } = opts;
  if (!canvas) {
    throw new Error("must provide a fabric canvas");
  }
  const allObjects = canvas.getObjects();
  if (isDeleteBackground) {
    canvas.remove(...allObjects);
  } else {
    const toRemove = allObjects.filter(
      v => !v.metaProp?.type.includes("ellio_design")
    );
    canvas.remove(...toRemove);
  }
  return canvas;
};

/* crop portion of the canvas given a task and the rect associated */
export const cropPortion = ({ canvas, filename = "portion" } = {}) => {
  /* for each rect, get the aCoords.tl.x|y, width and height */
  const rects = canvas
    .getObjects()
    .filter(v => v.metaProp?.type === "ellio_region_node");
  rects.forEach(rect => {
    const padding = 10;
    const left = rect.aCoords.tl.x - padding;
    const top = rect.aCoords.tl.y - padding;
    const width = rect.getScaledWidth() + padding * 2;
    const height = rect.getScaledHeight() + padding * 2;
    const d = canvas.toDataURL({
      format: "jpeg",
      quality: 1,
      left,
      top,
      width,
      height,
      enableRetinaScaling: true
    });
    downloadDataUrl(d, filename);
  });
};

export const toColorString = color => {
  const { r, g, b, a } = color;
  const rgbStr = `rgba(${r}, ${g}, ${b}, ${a})`;
  return rgbStr;
};

export const changeColor = ({ canvas, color, target, prop = "fill" }) => {
  if (!target) {
    return;
  }
  const { r, g, b, a } = color;
  const rgbStr = `rgba(${r}, ${g}, ${b}, ${a})`;
  const isGroup = target.size ? !!target.size() : false;
  if (isGroup) {
    target.getObjects().forEach(object => object.set(prop, rgbStr));
  } else {
    target.set(prop, rgbStr);
  }

  canvas.renderAll();
};

export const trackMousePosition = ({ canvas, cb = () => {} }) => {
  const callPos = e => {
    cb({ x: e.pointer.x, y: e.pointer.y, target: e.target, event: e });
  };

  const debCallPos = debounceFn(callPos, { wait: 150 });
  canvas.on("mouse:move", debCallPos);
};

export const isBgImage = object => {
  return object.metaProp?.type.includes("ellio_design");
};

export const getTargetColor = target => {
  if (!target) {
    return defaultColor;
  }
  const pc = colorParse(target.fill);
  const result = Color.rgb(pc.values).alpha(pc.alpha).object();
  return {
    r: result.r,
    g: result.g,
    b: result.b,
    a: result.alpha || 1
  };
};

/* if model is empty or src is figma, we can infer that we have to generate
 * the ellio_design node for the background.
 */
export const loadModel = async ({ canvas, model, design }) => {
  const backgroundImage = model.objects.find(o =>
    o.metaProp?.type.includes("ellio_design")
  );

  if (backgroundImage) {
    const key = getS3KeyFromUrl(backgroundImage.src);
    const isFigma = backgroundImage.src.includes("figma-alpha-api/img");
    const signResp = isFigma
      ? { signedUrl: backgroundImage.src }
      : await client.service("uploads").get(key);

    backgroundImage.src = signResp.signedUrl;
    backgroundImage.metaProp.src = signResp.signedUrl;
    if (backgroundImage.metaProp.snapshotUrl) {
      const snapSignResp = await client
        .service("uploads")
        .get(getS3KeyFromUrl(backgroundImage.metaProp.snapshotUrl));
      backgroundImage.metaProp.snapshotUrl = snapSignResp.signedUrl;
    }
  } else if (design.url.includes("figma-alpha-api/img")) {
    /* need to create canvas image  */
    if (model.objects.length === 0) {
      const canvasImageResult = await newCanvasImage({
        imageSource: design.url,
        canvas,
        backgroundImage: {
          uuid: design.uuid
        }
      });

      // console.log('canvasImageResult', canvasImageResult)
      // console.log('to object', toObject(canvasImageResult.oImg))

      // model.objects.push(canvasImageResult.oImg);
      model.objects.push(canvasImageResult.oImg.toObject(extraPropsToSave));
    }
  }

  canvas.loadFromJSON(model, canvas.renderAll.bind(canvas));
  const objectsCount = model.objects.length;
  let objectsAdded = 0;
  /* after fabric is done adding all the items, set the canvas dimension
   * based on the bg image.
   */
  return new Promise((resolve, reject) => {
    canvas.on("object:added", function (object) {
      objectsAdded += 1;

      if (objectsAdded === objectsCount) {
        const objects = canvas.getObjects();
        const bgImage = objects.find(o =>
          o.metaProp?.type.includes("ellio_design")
        );

        const tempImage = new Image();
        tempImage.src = bgImage.src;

        tempImage.onload = function () {
          resolve({ image: bgImage, canvas });
        };

        resolve({ image: bgImage, canvas });
      }
    });
  });
};

export const defaultEventsToWatch = [
  // "object:added",
  "object:removed",
  "object:modified",
  "object:scaled",
  "object:skewed",
  "selection:updated",
  "selection:created",
  "selection:cleared"
];

export const onModelChange = (
  { canvas, ignore = [], events = defaultEventsToWatch },
  cb
) => {
  events
    .filter(v => !ignore.includes(v))
    .forEach(event => {
      canvas.on(event, e => {
        cb(e, event);
      });
    });
};

export const newCanvasImage = opts => {
  const {
    backgroundImage,
    canvasWidth = defaultCanvasWidth,
    canvas,
    imageSource
  } = opts;

  return new Promise(async resolve => {
    let source;
    if (imageSource) {
      source = imageSource;
    } else {
      if (backgroundImage.url) {
        const key = getS3KeyFromUrl(backgroundImage.url);
        const signResp = await client.service("uploads").get(key);
        source = signResp.signedUrl;
      } else if (backgroundImage.dataUrl) {
        srouce = backgroundImage.dataUrl;
      }
    }

    fabric.Image.fromURL(
      // backgroundImage.url || backgroundImage.dataUrl,
      source,
      function (img) {
        const oImg = img
          .set({ left: 0, top: 0, angle: 0, objectCaching: false })
          .scale(0.9);

        setCanvasDimensions({
          image: oImg,
          canvas,
          canvasWidth
        });

        oImg.set("selectable", false);
        const metaProp = {
          type: `ellio_design_${backgroundImage.uuid}`,
          id: backgroundImage.uuid
        };
        addMetaProp({ metaProp, object: oImg });
        resolve({ oImg, canvas });
      },
      {
        crossOrigin: "anonymous"
      }
    );
  });
};

export const lastObject = ({ canvas }) => {
  const objects = canvas.getObjects();
  return objects[objects.length - 1];
};

export const toObject = ({ canvas }) => {
  return canvas.toObject(extraPropsToSave);
};

export const revive = (objects, namespace, reviver) => {
  return new Promise((resolve, reject) => {
    fabric.util.enlivenObjects(
      objects,
      elms => resolve(elms),
      namespace,
      reviver
    );
  });
};

export const getObjectTransformInGroup = object => {
  const matrix = object.calcTransformMatrix();
  const dc = fabric.util.qrDecompose(matrix);
  /* TODO: left and right are not quite right when the object is resized */
  const left = object.left + object.group.left + object.group.width / 2;
  const top = object.top + object.group.top + object.group.height / 2;
  return { left, top, ...dc };
};

/* useful for getting custom nodes like tasks and comments */
export const getNodesByType = (target, type = "comment") => {
  if (target.size && target.metaProp?.type?.includes(type)) {
    return [target];
  }

  if (target.size) {
    return target.getObjects().filter(o => o.metaProp?.type?.includes(type));
  }

  if (target.metaProp?.type?.includes(type)) {
    return [target];
  }

  return null;
};

export const isTask = node => {
  return node?.metaProp?.type === "ellio_task_node";
};

export const isComment = node => {
  return node?.metaProp?.type === "ellio_comment_node";
};

export const getCanvasSnapshot = ({ canvas, node }) => {
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  const { x, y } = node.aCoords.tl;

  const width = canvasWidth > 1000 ? 1000 : canvasWidth;
  const height = canvasHeight > 500 ? 500 : canvasHeight;

  const left =
    x <= width / 2
      ? x - x
      : x + width / 2 >= canvasWidth
      ? x - Math.abs(canvasWidth - (x + width))
      : x - width / 2;

  const top =
    y - height / 2 <= 0
      ? y - y
      : y + height >= canvasHeight
      ? y - (height - (canvasHeight - y))
      : y - height / 2;

  const d = canvas.toDataURL({
    format: "jpeg",
    quality: 1,
    left,
    top,
    width,
    height,
    enableRetinaScaling: true
  });
  return d;
};

/*
https://stackoverflow.com/questions/21931271/how-to-enable-responsive-design-for-fabric-js
*/

export function rescaleCanvas({ canvas, containerWidth, annotationId }) {
  if (!containerWidth) {
    console.log("cannot rescale, must set containerWidth");
    return {
      zoom: 1,
      transform: []
    };
  }

  const bgImage = canvas
    .getObjects()
    .find(v => v.metaProp?.type.includes("ellio_design"));

  /* this works after the width of the canvas is set based on the image */
  const ratio = canvas.getWidth() / canvas.getHeight();
  const scaleToWidth =
    bgImage.width > containerWidth ? containerWidth : bgImage.width;

  const scale = scaleToWidth / canvas.getWidth();
  const zoom = canvas.getZoom() * scale;

  canvas.setDimensions({
    width: scaleToWidth,
    height: scaleToWidth / ratio
  });

  const transform = [zoom, 0, 0, zoom, 0, 0];

  canvas.setViewportTransform(transform);
  bgImage.scaleToWidth(scaleToWidth);

  bgImage.metaProp.transform = transform;

  canvas.setViewportTransform(transform);

  canvas.renderAll();

  handleUpdateModel({ canvas, annotationId });

  return {
    transform: [zoom, 0, 0, zoom, 0, 0],
    zoom
  };
}

export const getCanvasContainerWidth = wrapperNode => {
  if (wrapperNode) {
    return wrapperNode.clientWidth;
  }

  const nav = document.querySelector("#js-nav");
  return window.innerWidth - nav.clientWidth - 17;
};

export const drawLineOnDrag = ({
  canvas,
  user,
  annotationId,
  canDrawLineRef,
  setIsDrawLineMode,
  onDrawEnd = () => {}
}) => {
  if (!canvas) {
    throw new Error("must set canvas");
  }

  var line;
  var isDrawing;
  const userId = user._id;
  let active;

  canvas.on("mouse:down", function (o) {
    if (canDrawLineRef.current) {
      active = canvas.getActiveObject();
      if (active) {
        active.lockMovementX = true;
        active.lockMovementY = true;
      }
      canvas.discardActiveObject();
      canvas.renderAll();

      canvas.selection = false;
      isDrawing = true;
      var pointer = canvas.getPointer(o.e);
      var points = [pointer.x, pointer.y, pointer.x, pointer.y];

      line = new fabric.Line(points, {
        strokeWidth: 3,
        stroke: user.settings.avatar.bgColor,
        hoverCursor: "move",
        lockRotation: true
      });

      line.setControlsVisibility({ mtr: false });

      const metaProp = {
        type: "ellio_line_node",
        id: `line_${id()}`,
        userId
      };

      addMetaProp({ metaProp, object: line });
      canvas.add(line);
    }
  });

  canvas.on("mouse:move", function (o) {
    if (isDrawing && canDrawLineRef.current) {
      var pointer = canvas.getPointer(o.e);
      line.set({ x2: pointer.x, y2: pointer.y });
      canvas.renderAll();
    }
  });

  canvas.on("mouse:up", function (o) {
    if (canDrawLineRef.current) {
      if (active) {
        active.lockMovementX = false;
        active.lockMovementY = false;
      }
      isDrawing = false;
      canvas.selection = true;
      line.setCoords();
      canvas.renderAll();
      setIsDrawLineMode(false);
      canDrawLineRef.current = false;
      onDrawEnd({
        canvas,
        annotationId,
        events: "",
        line,
        userId
      });
    }
  });
};
